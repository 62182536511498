<template>
    <div class="table-single-image">
        <img :src="scope.row[code_data.key]" max-width="150px" :width="code_data.image_width"
            :height="code_data.image_height" @click="showBig(scope.row[code_data.key])" :onerror="errorImg">

        <el-dialog :title="code_data.label" :visible.sync="dialogShow"
            :width="code_data?.dialog_width ? code_data?.dialog_width - 0 + 80 + 'px' : '380px'" :show-close="false">
            <div class="dialog-box">
                <img :src="scope.row[code_data.key]"
                    :width="code_data?.dialog_width ? code_data?.dialog_width + 'px' : '300px'"
                    :height="code_data?.dialog_height ? code_data?.dialog_height + 'px' : '300px'">
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    name: "component.table.table_single_image",
    props: {
        code_data: {
            type: Object,
            required: true
        },
        scope: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dialogImage: '',
            dialogShow: false,
            errorImg: 'this.src="' + require("@/assets/images/lodingsb.svg") + '"'
        }
    },
    methods: {
        showBig(item) {
            if (!this.code_data.is_dialog) return false
            this.dialogImage = item;
            this.dialogShow = true;
        }
    }
}
</script>

<style scoped lang="scss">
.table-single-image {
    cursor: pointer;
    img{
        max-width:none ;
    }

    .dialog-box {
        width: 100%;
        padding: 40px;
    }
}
</style>