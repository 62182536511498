<template>
    <div :class="{ 'input-number-append-box': code_data.parameters?.append }">
        <el-input-number :precision="code_data.parameters?.precision || 0" :step="code_data.parameters?.step || 1"
            :min="code_data.parameters?.min || 0" :placeholder="code_data.parameters?.placeholder"
            :max="code_data.parameters?.max || 99999" v-model="$formData[code_data.key]" :controls="false">
        </el-input-number>
        <span class="input-group__append" v-if="code_data.parameters.append">{{ code_data.parameters.append }}</span>
    </div>
</template>

<script>
export default {
    name: 'component.form.inputnumber_input',
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep.el-input-number .el-input__inner {
    text-align: left;
}

.input-number-append-box {
    width: 275px;
    display: flex;
    align-items: center;

    /deep/.el-input-number {
        flex: 1;

        .el-input {
            width: 100%;
        }

        .el-input__inner {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .input-group__append {
        height: 40px;
        display: inline-block;
        box-sizing: border-box;
        background-color: #F5F7FA;
        color: #909399;
        vertical-align: middle;
        border: 1px solid #DCDFE6;
        border-left: 0;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 0 20px;
        white-space: nowrap;
    }
}
</style>