<!-- 
    code 平台的下的 表单组件的活动控制下级开关
        $formData 是父组件表单的数据源
        code_data 是本组件的控制项数据源
 -->
<template>
    <div>
        <el-switch v-model="$formData[code_data.key]" :active-value="1" :inactive-value="0"
            inactive-color="#bbb"></el-switch>
        <div class="children-tips" v-if="code_data?.children && $formData[code_data.key]">
            {{ code_data.children.tips }}过期前
            <el-input type="text" placeholder="请输入时间" v-model="$formData[code_data.children.input]">
                <template slot="append">
                    <el-select v-model="$formData[code_data.children.picker]">
                        <el-option :value="item.value" v-for="(item, index) in code_data.children.picker_data"
                            :key="item.value" :label="item.label"></el-option>
                    </el-select>
                </template>
            </el-input>
            短信进行提醒
        </div>
    </div>
</template>

<script>
export default {
    name: "component.form.activity-switch",
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-input {
    width: 200px !important;
}

::v-deep .el-input-group__append .el-select,
::v-deep .el-input-group__append .el-select .el-input--suffix {
    width: 80px !important;
}

::v-deep .el-input-group__append .el-select .el-input--suffix .el-input__inner {
    border: 1px solid transparent;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

::v-deep .el-input-group__append .el-select .el-input--suffix .el-input__inner:hover {
    border: 1px solid var(--primary);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

::v-deep .el-input__inner {
    height: 36px;
}

::v-deep .el-input__icon {
    line-height: 36px;
}

.children-tips {
    display: flex;
    align-items: center;

    >.el-input {
        margin: 0 10px;
    }


}
</style>