<!-- 
    component.form.collection  类似采集事件
    定义：左输入框，右点击事件
    事件：走api去获取采集的结果  然后反推到 父组件  去 修改值 formData 的值
    预留方法 接收 子组件传过来的值
    -->
<template>
    <div class="collection-box">
        <el-input v-model="collectionUrl" placeholder="请输入采购商品详情地址连接"></el-input>
        <el-button :loading="collectionLoading" @click="clickButton">立即获取</el-button>
    </div>
</template>


<script>
export default {
    name: 'component.form.collection',
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {
            collectionUrl: '',
            collectionLoading: false
        }
    },
    methods: {
        clickButton() {
            let that = this;
            if (!this.collectionUrl) {
                this.$message.warning('请输入采集链接')
                return false
            }
            let data = {
                url: this.collectionUrl
            }
            this.collectionLoading = true;
            this.code.request(this, this.code_data.api, data,
                (_, res) => {
                    //console.log(res, 'res');
                    that.collectionLoading = false;
                    if (res.code == 200) {
                        // 目前只做数据反递到父组件，之后根据需求进行调整
                        this.$emit('changeChildCallback', { returnData: res.data })
                    }
                }, (_, error) => {
                    //console.log(error, 'error');
                    that.collectionLoading = false;
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.collection-box {
    width: 448px;
    display: flex;
    align-items: center;

    .el-input {
        flex: 1;

        /deep/ .el-input__inner {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 1px solid transparent;

            &:hover,
            &:focus {
                border: 1px solid var(--primary);
            }
        }
    }

    .el-button {
        border-radius: 0 4px 4px 0;
    }
}
</style>