<template>
	<div>
		<el-dialog title="视频选择器" :visible.sync="videoVisible" class="videobox">
			<div slot="title" class="dialog-title">
			    <h4 class="title-text">视频选择器 <span>(最小宽度600,最小高度600,大小不超过5MB)</span></h4>
			</div>
			<div class="photo_wrap">
				<div class="video_head">
					<span :class="[videoheadline == 1 ? 'active' : '']" @click="videoHeadclick(1)">本地视频</span>
					<span :class="[videoheadline == 2 ? 'active' : '']" @click="videoHeadclick(2)">网络提取</span>
				</div>
				<div class="photo_container" v-show="videoheadline == 1">
					<div class="photo_lefted">
						<div class="photo_leftedup clearfloat">
							<h5><i class="el-icon-folder-remove"></i> 我的视频</h5>
							<div class="photo_add">
								<el-popover placement="top" width="160" v-model="addvisible">
									<div class="photo_add_title">
										<i class="el-icon-warning"></i>
										<span>分类名称</span>
									</div>
									<div class="photo_add_input">
										<el-input v-model="photoClassName" clearable placeholder="分类名称"></el-input>
									</div>
									<div style="text-align: right" class="photo_foot_add">
										<div class="goodsbtn attr_foot_cancel" size="mini" type="text" @click="addvisible = false">
											取消
										</div>
										<div class="goodsbtn" type="primary" size="mini" @click="photoClassSubmit">
											确定
										</div>
									</div>
									<div slot="reference" class="photo_add_class el-icon-plus"></div>
								</el-popover>
							</div>
						</div>
						<div class="photo_box">
							<div v-for="(item, index) in videoClass" :key="index" @click="videoClassClick(index)"
								:class="['photo_box_item',videoClassIndex == index ? 'active' : '',]">
								<i class="el-icon-folder-remove"></i> {{ item }}
							</div>
						</div>
					</div>
					<!-- 视频此处的参数需要更改，请根据具体的操作更改 -->
					<div class="photo_right">
						<div class="photo_right_head clearfloat">
							<div class="photo_right_search">
								<el-input placeholder="请输入视频名称" clearable v-model="photoName"
									suffix-icon="el-icon-search"></el-input>
							</div>
							<div class="photo_upload pull-right clearfloat">
								<el-checkbox>全选</el-checkbox>
								<button>删除</button>
								<div class="photo_upimged">
									<el-upload action="">
										<div class="goodsbtn">+上传图片</div>
									</el-upload>
								</div>
							</div>
						</div>
						<div class="photo_right_content">
							<div class="photo_right_item" v-for="(item, index) in imgList" :key="index"
								@click="imageSelect(index)">
								<div class="img_item" :style="{ backgroundImage: `url(${item.img})` }"></div>
								<i class="img_delte el-icon-error" @click="delImage(index)"></i>
								<div class="img_select" :class="[JSON.stringify(selectImage).indexOf(JSON.stringify(item)) != -1 ? 'active' : '',]">
									<i class="el-icon-check"></i>
								</div>
								<div class="img_size">{{ item.size }}</div>
								<div class="imag_title">{{ item.title }}</div>
							</div>
						</div>
						<div class="photo_right_foot">
							<div class="photo_right_footer">
								<el-pagination :current-page.sync="photoPge" :total="5" :page-size="500"
									:page-count="photoToal" layout="prev, pager, next">
								</el-pagination>
							</div>
						</div>
					</div>
				</div>
				<div class="video_container" v-show="videoheadline == 2">
					<div class="video_wrap">
						<el-input placeholder="请输入网络视频地址"></el-input>
						<div class="goodsbtn">提取视频</div>
					</div>
					<div class="video_tips">
						<p>
							视频来源仅支持腾讯视频，添加完成后默认不自动播放（请使用腾讯视频分享链接）
						</p>
						<p>
							因腾讯视频限制，视频提取后可能无法进行播放，保存前请先确认视频是否能正常播放
						</p>
					</div>
					<div class="video_content">
						<img src="https://vipuser3.eweishop.com/static/dist/shop/image/decorate/videoPreview.png"
							alt="" />
						<video src="" controls v-if="false"></video>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<div class="goodsbtn attr_foot_cancel" @click="videoVisible = false;selectVIdeo = [];">
					取 消
				</div>
				<div class="goodsbtn" @click="videoVisible = false;selectVIdeo = [];">
					提 交
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		name: "videoSelect",
		data() {
			return {
				// 视频选择器
				imgList: [], //图片数组
				videoVisible: false, //视频选择器
				videoheadline: 1, //视频头部切换
				classVideoName: "", //搜索的图片分类名称
				videoClassIndex: 0,
				videoClass: ["全部", "分组一"], //视频分类
				selectVIdeo: [], //选中的视频
				xzclassifytype: false, //选择分类的弹窗
				classImageName: "", //搜索的图片分类名称
				addvisible: false, //新增分类弹窗
				photoClassName: "", //新增图片分类名称
				photoName: "", //搜索的图片名称
				photoPge: 1, //当前图片页数
				photoToal: 500, //总条数
			};
		},
		methods: {
			// 视频头部切换
			videoHeadclick(index) {
				this.videoheadline = index;
			},
			videoClassClick(index) {
				this.videoClassIndex = index;
			},
			photoClassSubmit() {
				if (!this.photoClassName) {
					this.$message({
						message: "请输入分类名称",
						type: "warning",
					});
					return;
				}
				this.videoClass.push(this.photoClassName);
				this.addvisible = false;
			},

			imageSelect(index) {
				let item = this.imgList[index];
				if (JSON.stringify(this.selectImage).indexOf(JSON.stringify(item)) != -1) {
					this.selectImage.splice(index, 1);
				} else {
					this.selectImage.push(item);
				}
			},

			delImage(index) {
				let item = this.imgList[index];
				if (
					JSON.stringify(this.selectImage).indexOf(JSON.stringify(item)) != -1
				) {
					this.imgList.splice(index, 1);
				}
				this.imgList.splice(index, 1);
			},
		},
	};
</script>
<style scoped>
	.video_head {
	    text-align: center;
	    margin-bottom: 18px;
	    border-bottom: 1px solid #f2f2f2;
	}
	
	.video_head span {
	    display: inline-block;
	    height: 36px;
	    color: #000;
	    cursor: pointer;
	    margin: 0 39px;
	    font-size: 14px;
	}
	
	.video_head span.active {
	    color: var(--fontColor, #fb6638);
	    border-bottom: 2px solid var(--fontColor, #fb6638);
	}
	
	.video_wrap {
	    display: flex;
	    align-items: center;
	    flex: 1;
	    justify-content: center;
	}
	
	.video_wrap .el-input {
	    width: 560px;
	    margin-right: 10px;
	}
	
	.video_wrap .el-input__inner {
	    width: 560px;
	}
	
	.video_tips p {
	    margin-top: 10px;
	}
	
	.video_content {
	    width: 100%;
	    max-height: 400px;
	    /* margin-top: 10px; */
	}
	
	.video_content img {
	    width: 100%;
	    max-height: 400px;
	    margin-top: 10px;
	}
	
	.video_content video {
	    width: 100%;
	    max-height: 400px;
	    margin-top: 10px;
	}
	.videobox .el-dialog{border-radius: 4px;width: 860px;}
</style>