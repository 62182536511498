import store from "@/store";
/**
 * 
 * @param {Array} permiArr
 * @returns 
 */
export function hasPermission(permiArr,is_multi) {
    if (!Array.isArray(permiArr)) {
        throw new Error(`permiArr is not a Array`)
    }
    if (permiArr.length == 0) {
        throw new Error(`permiArr is not defined`)
    }
    let permissions = store.state.adminPermissions || [];
    if (permissions.length > 0) {
        if (permiArr.length == 1) {
            return permissions.some(item => item == permiArr[0]);
        } else {
            if(is_multi){
                for (const perKey of permiArr) {
                    return permissions.every(item => item == perKey)
                  }
            }else{
                for (const perKey of permiArr) {
                    return permissions.some(item => item == perKey)
                  }
            }
            
        }

    }
}