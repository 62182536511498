<template>
    <div class="search-item" v-if="select_data.length">
        <el-input :placeholder="inputPlaceholder" v-model="$formData[code_data.key]['value']" clearable>
            <template slot="prepend">
                <el-select v-model="$formData[code_data.key]['key']" @change="handleChange">
                    <el-option v-for="item in select_data" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
            </template>
        </el-input>
    </div>
</template>
<script>
export default {
    name: "component.search.inputSelect",
    props: {
        code_data: {
            typeof: Object,
            required: true
        },
        $formData: {}
    },
    watch: {
        code_data: {
            handler(newVal, oldVal) {
                this.init()
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            inputPlaceholder: this.code_data.parameters.placeholder,
            select_data: []
        }
    },
    methods: {
        init() {
            const { op_select } = this.code_data;
            if (op_select.resource_type == "Enum") {
                let _select_data = []
                Object.keys(op_select.resource).forEach(item => {
                    _select_data.push({
                        value: item,
                        label: op_select.resource[item]
                    })
                })
                this.select_data = _select_data
            } else if (op_select.resource_type == "Api") {
                this.code.request(this, op_select.api, {
                    page: 1,
                    limit: 999,
                }, (domThis, res) => {
                    let _select_data = []
                    res.data.list.forEach((item, index) => {
                        if (index == 0) {
                            domThis.$formData[domThis.code_data.key]['key'] = item[domThis.code_data.op_select.api_key]
                        }
                        _select_data.push({
                            value: item[domThis.code_data.op_select.api_key],
                            label: item[domThis.code_data.op_select.api_label]
                        })
                    })
                    domThis.select_data = _select_data
                })
            } else {
                let data = {
                    keys: [
                        op_select.keys
                    ]
                };
                this.$get(this.$apis.resource, data).then((res) => {
                    let _select_data = []
                    res.data[op_select.keys].forEach((item, index) => {
                        if (index == 0) {
                            this.$formData[this.code_data.key]['key'] = item[op_select.resource_label]
                        }
                        _select_data.push({
                            value: item[op_select.resource_key],
                            label: item[op_select.resource_label]
                        })
                    })
                    this.select_data = _select_data
                }).catch((err) => {
                    this.message(_this, err)
                });
            }
        },
        handleChange(e) {
            this.inputPlaceholder = '请输入' + this.select_data.filter(item => item.value == e)[0].label
        }
    }
}
</script>