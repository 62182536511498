<template>
    <div class="search-item">
        <label class="search-label">{{ code_data.label }}：</label>
        <el-input v-model="$formData[code_data.key]['value']" type="text" :placeholder="code_data.parameters.placeholder"
            clearable :disabled="code_data.parameters.is_disabled" :maxlength="code_data.parameters?.maxlength || 128">
        </el-input>
    </div>
</template>
<script>
export default {
    name: "component.search.textInput",
    props: {
        code_data: {
            typeof: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {

        }
    }
}
</script>