<template>
    <el-switch v-model="value" :active-value="1" :inactive-value="0" inactive-color="#bbb"
        @change="grounding($event, scope)"></el-switch>
</template>
<script>
export default {
    name: "component.table.switch",
    props: {
        code_data: {
            type: Object,
            required: true
        },
        scope: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            component_id: this.code.domId(this.code_data),
            value: ''
        };
    },
    watch: {
        scope: {
            handler(newVal, oldVal) {
                if (newVal != oldVal) {
                    // 初始化
                    this.value = newVal.row[this.code_data.key]
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        grounding(val, scope) {
            const { sub_label, true_labal, false_labal } = this.code_data
            this.$confirm(`您确定${this.value ? true_labal : false_labal}该${sub_label}吗?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(res => {
                this.$emit("handleSwitch", val, scope, this.code_data)
            }).catch(error => {
                this.value = this.scope.row[this.code_data.key]
            })
        }
    }
}
</script>