<template>
    <div class="nav_head clearfloat">
        <div class="nav_btn" v-if="search.btn&&search.btn.length">
            <div v-for="(item,index) in search.btn" :key="index" class="goodsbtn" @click.stop="searchClick(item)">{{ item.name }}111</div>
        </div>
        <div class="nav_list  d-flex align-items-center flex-wrap" v-if="search.list&&search.list.length">
           <div v-for="(item,index) in search.list" :key="index" class="d-flex align-items-center">
                <span v-if="item.title">{{ item.title }}：</span>
                <template v-if="item.type=='text'">
                    <div class="table_search-input">
                        <el-input v-model="item.parameters.inputName" :placeholder="item.attributes.placeholder" :prefix-icon="item.attributes.prefixIcon" :suffix-icon="item.attributes.suffixIcon" ></el-input>
                    </div>
                </template>
                <template v-if="item.type=='select'">
                    <div class="essay_head_select ">
                        <el-select v-model="item.parameters.inputName">
                            <el-option v-for="(i,b) in item.parameters.essay" :key="b" :label="i.label?i.label:i.value" :value="i.value"></el-option>
                        </el-select>
                    </div>
                </template>
            </div> 
        </div>
    </div>
</template>
<script>
export default {
    name:"searchTop",
    props:{
        search:{
            type:Object,
            default:{}
        }
    },
    data(){
        return {

        }
    },
    methods:{
        searchClick(item){
			// if(item.action == 'redirect'){
			// 	this.$router.push(''+ item.url);
			// }
			this.$emit('searchtab',item)
		},
    }
}
</script>
<style scoped>
    .nav_head{
        padding-bottom: 20px;
        overflow: hidden;
        box-shadow: inset 0 -1px 0 #efefef;
    }
    .nav_btn{
        float: left;
    }
    .nav_btn>div{
        margin-right: 10px;
    }
    .nav_list{
        float: right;
    }
    .nav_list>div{
        margin-right: 10px;
    }
    .cation_header>span{
        padding: 0 18px;
    }
    .table_search-input>>>.el-input__inner{
    /* padding-right: 0; */
    width: 270px;
}
</style>