<template>
    <div class="search-item">
        <label class="search-label">{{ code_data.label }}：</label>
        <div class="number-of-intervals">
            <el-input-number :min="0" :max="100000000" v-model="$formData[code_data.key]['value'][0]"
                :controls="false"></el-input-number>
            <span>-</span>
            <el-input-number :min="0" :max="100000000" v-model="$formData[code_data.key]['value'][1]"
                :controls="false"></el-input-number>
        </div>
    </div>
</template>
<script>
export default {
    name: "component.search.intervalInput",
    props: {
        code_data: {
            typeof: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {

        }
    }
}
</script>