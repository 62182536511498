<template>
	<div class="layout_base">
		<component :is="code_data.type" :code_data="code_data"></component>
		<!-- layout.index -->
	</div>
</template>
<script>
export default {
	name: 'layout.index',
	props: {
		code_data: {
			type: Object,
			required: true
		},
	},
	created() {
		// console.log('created', this.code_data);
	},
	mounted() {
		// console.log('mounted', this.code_data);
	}
};
</script>
<style scoped>
.layout_base {
	display: flex;
	height: 100%;
	flex-direction: column;
}
</style>