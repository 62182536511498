<template>
    <el-dialog title="复制活动" :visible.sync="dialogShow" width="600px" :show-close="false">
        <div class="dialog-box">
            <div class="dialog-item">
                <div class="label">选择企业</div>
                <div class="right-box">
                    <el-select v-model="companyValue" clearable @change="changeCompany">
                        <el-option v-for="item in companyList" :key="item.id" :label="item.name"
                            :value="item.id"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="dialog-item">
                <div class="label">复制商品</div>
                <div class="right-box">
                    <el-radio v-model="isCopyGoods" :label="1">是</el-radio>
                    <el-radio v-model="isCopyGoods" :label="0">否</el-radio>
                </div>
            </div>
            <div class="dialog-item">
                <div class="label">复制员工</div>
                <div class="right-box">
                    <el-radio v-model="isCopyMember" :label="1" :disabled="isCopyMemberDisalabe">是</el-radio>
                    <el-radio v-model="isCopyMember" :label="0" :disabled="isCopyMemberDisalabe">否</el-radio>
                </div>
            </div>
            <div class="dialog-item">
                <div class="label">商品折扣率</div>
                <div class="right-box">
                    <el-input v-model="goodDiscount" placeholder="请输入商品折扣率"></el-input>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="clearSetDepartment">取 消</el-button>
            <el-button class="addressqr" type="primary" @click="handleSetDepartment" :loading="buttonLoading">确
                定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "component.table.copy_activity",
    data() {
        return {
            dialogShow: false,
            buttonLoading: false,
            scope: {},
            companyValue: "",
            companyList: [],
            isCopyGoods: 1,
            isCopyMember: 1,
            isCopyMemberDisalabe: false,
            goodDiscount: ""
        }
    },
    created() {
        // 获取所有的企业列表
        this.getAllCompanyList()
    },
    methods: {
        getAllCompanyList() {
            let data = {
                keys: ["company.list"],
            };
            this.common.getCommonInfo(this, data, res => {
                this.companyList = res['company.list'];
            });
        },
        changeCompany(e) {
            if ((e && e != this.scope.company_id) || !e) {
                this.isCopyMemberDisalabe = true;
                this.isCopyMember = 0;
            } else {
                this.isCopyMemberDisalabe = false;
                this.isCopyMember = 1;
            }
        },
        handleSetDepartment() {
            // 整理数据
            let searchData = {}
            if (this.companyValue === '') return this.$message.error("请选择企业")
            searchData['company_id'] = this.companyValue;
            if (this.goodDiscount === '') return this.$message.error("商品折扣率不能为空")
            searchData['goods_discount'] = this.goodDiscount;

            searchData['activity_id'] = this.scope.id;
            searchData['copy_goods'] = this.isCopyGoods;
            searchData['copy_staff'] = this.isCopyMember;

            this.buttonLoading = true;

            this.$post(this.$apis.companyActivityCopy, searchData).then(res => {
                if (res.code == 200) {
                    this.clearSetDepartment()
                    this.$message.success("复制成功");
                    this.$emit('onload', true);
                } else {
                    this.$message.warning(res.message);
                    this.buttonLoading = false;
                }
            })
        },
        clearSetDepartment() {
            this.companyValue = "";
            this.isCopyGoods = 1;
            this.isCopyMember = 1;
            this.goodDiscount = "";
            this.buttonLoading = false;
            this.dialogShow = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog-box {
    padding: 20px 30px;

    .dialog-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .label {
            width: 90px;
            font-size: 14px;
            flex-shrink: 0;
            text-align: right;
            margin-right: 16px;
        }
    }
}
</style>