<template>
    <div>
        <el-dialog :visible.sync="$formData[code_data.dialog_key]" :width="code_data.parameters.width ?? '600px'"
            :show-close="true" :title="code_data.parameters.title ?? '详情'"
            :close-on-click-modal="code_data.slot.type != 'component.form'"
            :close-on-press-escape="code_data.slot.type != 'component.form'">
            <template v-if="code_data.slot.type == 'component.form'">
                <el-form :label-width="code_data.parameters.label_width ?? '100px'" :rules="formRules" :model="formData"
                    :ref="component_id">
                    <el-form-item v-for="(item, i) in  code_data.slot.components " :label="item?.label" :prop="item?.key">
                        <!-- 
							component.form.collection  类似采集事件
							定义：左输入框，右点击事件
							事件：走api修改 formData 的值
							预留方法 接收 子组件传过来的值
							-->
                        <component :is="item.type" :code_data="item" :$formData="formData"
                            v-if="item.type == 'component.form.collection'" @changeChildCallback="childCallback">
                        </component>
                        <component :is="item.type" :code_data="item" :$formData="formData" v-else>
                        </component>
                        <div v-if="item.parameters.tips" class="default-tips">{{ item.parameters.tips }}</div>
                        <div v-if="item.parameters.toast" class="warning-toast">{{ item.parameters.toast }}</div>
                    </el-form-item>
                </el-form>
            </template>
            <template v-if="code_data.slot.type == 'component.table' && formData[code_data.parameters.data_key]">
                <div class="dialog-box" v-for="item in formData[code_data.parameters.data_key]" :key="item.id">
                    <div class="dialog-title" v-if="code_data.parameters.is_title">{{ item[code_data.parameters.title_key]
                    }}</div>
                    <el-table :data="item[code_data.parameters.table_key]">
                        <el-table-column v-for="(itm, idx) in code_data.parameters.table_item" :key="idx"
                            :property="itm.prop" :label="itm.label"></el-table-column>
                    </el-table>
                </div>
            </template>
            <template v-if="code_data.slot.type == 'component.template' && formData['template']">
                <div v-html="formData['template']"></div>
            </template>
            <template slot="footer">
                <el-button v-for="(item, index) in code_data.footer_buttons" :key="index" :type="item.type"
                    @click="globalClick(item.click_name)">{{ item.label }}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'component.dialog',
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {},
        scope: {}
    },
    data() {
        let formRules = {};
        let formData = {};
        let keyMap = {};
        const { type, components } = this.code_data.slot;
        // form -> add
        if (type == 'component.form') {
            // form的数据整理提取
            for (let i in components) {
                let component = components[i];
                //数据格式化
                component.id = this.code.domId(component);
                if (component.data_source && component?.key) {
                    component.data_source.successCallback = function(domThis, res) {
                        component.data_source = res;
                    }
                    this.code.formatDataSource(this, component.data_source);
                }

                formRules[component.key] = component.rules;
                formData[component.key] = this.code.formatDataValue(component, component?.default ?? '');
                keyMap[component.key] = component;
            }
        }

        return {
            component_id: this.code.domId(this.code_data.slot),
            formRules: formRules,
            formData: formData,
            keyMap: keyMap,
            requestData: {},
            is_multiple: false,
            uploadImge: {},
        }
    },
    watch: {
        ['$formData']: {
            handler(newVal, oldVal) {
                if (newVal) {
                    if (Object.keys(newVal).some(item => newVal[item])) {
                        this.getResources()
                    }
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        getResources() {
            // 编辑回显
            if (this.code_data.scope_type == 'edit' || this.code_data.scope_type == 'show') {
                const { resources } = this.code_data;
                if (this.$formData[this.code_data.dialog_key]) {
                    if (resources.target == 'scope') {
                        this.formData = { ...this.formData, ...this.scope.row }
                    }
                    if (resources.target == 'api') {
                        let API = { ...resources.API }
                        API.url += this.scope.row.id;
                        this.code.request(this, API, {}, (_, res) => {
                            //console.log(res, 'resres');
                            if (res.code == 200) {
                                this.formData = { ...this.formData, ...res.data }
                            } else {
                                this.$message.error(res.message)
                            }
                        }, (_, error) => {
                            this.$message.error(error.message)
                        })
                    }
                    if (resources.target == 'template') {
                        this.formData = { ...this.formData, ...resources }
                    }
                }
            }
        },
        globalClick(type) {
            /**
             * 因为定义的方法名称和服务端返回的方法名如果一致的话，动态绑定会报错 -> 处理事件的函数名称不能和data中的数据名相同
             * 所以需要做统一的处理，通过服务端返回的type去动态获取方法，然后再执行
             * 
             * 因为方法中会有自定义的操作，目前区分类型 Form表单(操作)， Table表格(展示)， Template模板(展示)
             */
            const funC = this[type];
            funC && funC()
        },
        cancel() {
            // 当前需要处理的场景  目前只有表单情况下需要做表单重置
            const { type } = this.code_data.slot;
            if (type == 'component.form') {
                this.$refs[this.component_id].resetFields();
            }
            this.$formData[this.code_data.dialog_key] = false
        },
        confirm() {
            // 当前需要处理的场景  目前只有表单情况下需要做表单验证
            const { scope_type } = this.code_data
            const { type, addApi, editApi } = this.code_data.slot;
            if (type == 'component.form') {
                if (scope_type == 'add') {
                    this.$refs[this.component_id].validate((valid) => {
                        if (valid) {
                            if (addApi) {
                                this.code.request(this, addApi, this.formData, (_, res) => {
                                    if (res.code == 200) {
                                        this.$message.success(res.message)
                                        // 因为一级parent 是card组件实例，再往上一层才是搜索组件示例 
                                        this.$parent.$parent.getTableData()
                                        this.$formData[this.code_data.dialog_key] = false
                                    } else {
                                        this.$message.error(res.message)
                                    }
                                }, (_, error) => {
                                    this.$message.error(error.message)
                                });
                            } else {
                                this.$message.warning('缺少必要参数，"添加接口"')
                            }
                        } else {
                            return false;
                        }
                    })
                } else {
                    this.$refs[this.component_id].validate((valid) => {
                        if (valid) {
                            if (editApi) {
                                let _API = { ...editApi }
                                _API.url = _API.url + this.scope.row.id;
                                this.code.request(this, _API, this.formData, (_, res) => {
                                    if (res.code == 200) {
                                        this.$message.success(res.message)
                                        // 因为一级parent 是card组件实例，再往上一层才是搜索组件示例 
                                        this.$parent.$parent.getTableData()
                                        this.$formData[this.code_data.dialog_key] = false
                                    } else {
                                        this.$message.error(res.message)
                                    }
                                }, (_, error) => {
                                    this.$message.error(error.message)
                                });
                            } else {
                                this.$message.warning('缺少必要参数，"编辑接口"')
                            }
                        } else {
                            return false;
                        }
                    })
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__footer {
    display: flex;
    justify-content: flex-end;
}

::v-deep .el-dialog__body {
    padding: 20px !important;
}

.default-tips {
    color: #636c72;
    font-size: 14px;
}

.warning-toast {
    color: #d9534f;
    font-size: 14px;
}
</style>