<template>
	<div class="layout_base">
		<component v-for="(item, i) in code_data.components" :is="item.type" :code_data="item" v-if="resource_status"
			:ref="item.id"></component>
		<!-- layout.normal -->
	</div>
</template>
<script>
export default {
	name: 'layout.normal',

	props: {//传递参数
		// v-model
		code_data: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			component_id: this.code_data.id,
			resource_status: false,
		};
	},
	watch: {
		['$route.params.name']: {
			handler(newVal, oldVal) {
				// console.log('normal 监听到了吗');
				if (newVal) {
					this.init();
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {

	},
	mounted() {

	},
	methods: {
		init() {
			if (this.code_data.resources && this.code_data.resources.length) {
				this.code.loadResources(this, function(domThis) {
					domThis.resource_status = true;
				});
			} else {
				this.resource_status = true;
			}
		}
	},

};
</script>
