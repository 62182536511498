<template>
	<el-card>
		<span class="back" @click="headback">
			<i class="el-icon-arrow-left" style="font-size:14px;"></i>
			返回
		</span>
		<i class="line">|</i>
		<span class="titile">{{ code_data.parameters.title }}</span>
	</el-card>
</template>
<script>
export default {
	name: 'component.breadcumb',

	props: {//传递参数
		// v-model
		code_data: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			component_id: this.code.domId(this.code_data)
		};
	},

	methods: {
		headback() {
			window.history.go(-1)
		},
	},

	mounted() {
		//console.log(this.component_id);
	},
};
</script>
<style scoped>
header {
	margin-bottom: 0px;
}

.back {
	cursor: pointer;
}

.titile {
	margin-left: 4px;
}
</style>
