<template>
    <div class="component-citys">
        <el-select v-model="save" placeholder="省级" @change="choseProvince">
            <el-option v-for="(item, index) in citylisted" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="cname" @change="choseCity" placeholder="市级">
            <el-option v-for="(item, $index) in city" :key="$index" :label="item.value" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="bname" @change="choseBlock" placeholder="区级">
            <el-option v-for="(item, $index) in block" :key="$index" :label="item.value" :value="item.value"></el-option>
        </el-select>
    </div>
</template>

<script>
export default {
    name: 'province_city_area',
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    watch: {
        $formData: {
            handler(newVal) {
                if (newVal) {
                    // 省市区回显
                    if (!this.$formData[this.code_data.key]) return false
                    const citys = this.$formData[this.code_data.key].split('-')
                    this.save = citys[0]
                    this.cname = citys[1]
                    this.bname = citys[2]
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            citylisted: [],
            city: [],
            shi1: [],
            save: '',
            cname: '',
            bname: '',
            block: []
        }
    },
    mounted() {
        // 获取级联数据
        if (this.code_data.data_source) {
            this.code_data.data_source['successCallback'] = function(domThis, res) {
                domThis.citylisted = res.data;
            }
            this.code_data.data_source['failCallback'] = function(domThis, err) {
                console.error('fail load data', err);
            }
        }
        this.code.formatDataSource(this, this.code_data.data_source);
    },
    methods: {
        choseProvince(e) {
            let that = this;
            that.city = [];
            that.block = [];
            that.cname = "";
            that.bname = "";
            for (var index2 in that.citylisted) {
                if (e === that.citylisted[index2].id) {
                    that.shi1 = that.citylisted[index2].list;
                    that.save = that.citylisted[index2].name;
                    that.shi1.forEach(function(citem, cindex) {
                        that.city.push({
                            id: citem.id,
                            value: citem.name,
                            children: citem.list
                        });
                    });
                }
            }
        },

        // 选市
        choseCity(e) {
            let that = this;
            that.block = [];
            for (var index3 in that.city) {
                if (e === that.city[index3].id) {
                    that.qu1 = that.city[index3].children;
                    that.cname = that.city[index3].value;
                    that.E = that.qu1[0].id;
                    that.qu1.forEach(function(bitem, bindex) {
                        that.block.push({
                            id: bitem.id,
                            value: bitem.name,
                            children: []
                        });
                    });
                }
            }
        },
        // 选区
        choseBlock(e) {
            this.bname = e;
            this.$formData[this.code_data.key] = `${this.save}-${this.cname}-${this.bname}`
        }
    }
}
</script>

<style lang="scss" scoped>
.component-citys {

    /deep/ .el-input,
    /deep/ .el-select {
        width: 133px;
    }

    .el-select+.el-select {
        margin-left: 9px;
    }
}
</style>