import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({

	state: {
		user: JSON.parse(window.localStorage.getItem('userInfo')),
		common_info: {},
		menuList: [],//菜单列表
		menuType: "base",//菜单类型--base pc collection
		// common_info: JSON.parse(sessionStorage.getItem('common_info'))
		noLoginGlobal: {},
		// style头部样式判断
		styletype: false,
		adminPermissions: []
	},
	mutations: {
		// 用户信息
		userInfo(state, data) {
			state.user = data;
			// 为了防止页面刷新数据丢失，我们还需要把数据放到本地存储中，这里仅仅是为了持久化数据
			window.localStorage.setItem('userInfo', JSON.stringify(state.user))
		},
		// 头尾公共信息
		commonInfo(state, data) {
			state.common_info = data;
			// window.sessionStorage.setItem('common_info', JSON.stringify(data));
		},
		// 获取菜单列表
		GET_MENU_LIST(state, response) {
			state.menuList = response;
		},
		// 更改menuType
		SET_MENU_TYPE(state, response) {
			state.menuType = response;
		},
		// 加载未登录全局资源
		SET_NO_LOGIN_GLOBAL(state, response) {
			state.noLoginGlobal = response;
		},
		SET_STYLE_TYPE(state, response) {
			state.styletype = response;
		},
		// 存储所有权限
		admin_permissions(state, response) {
			state.adminPermissions = response
		}
	},
	getters: {
		menuLists: state => state.menuList,
		menuType: state => state.menuType
	},
	actions: {},
	modules: {}
})
