<template>
    <el-dialog title="修改品牌" class="grounptc" :visible.sync="brandVisible">
        <div class="grounptcbox">
            <el-select v-model="brandModel" filterable clearable placeholder="请选择品牌" remote reserve-keyword
                @clear="reverseAmendbrand" @visible-change="amendTagChangebrand" :remote-method="remoteMethodamend"
                ref="my-select">
                <el-option v-for="(item, index) in compileBrand" :key="item.id" :label="item.name"
                    :value="item.id"></el-option>

                <div class="purpagebutkong"></div>
                <div class="purpagebut">
                    <el-pagination :small="true" @current-change="handleCurrentChangeamend" :current-page="brandPageRevamp"
                        layout="prev, next" :total="brandRevampSize">
                    </el-pagination>
                </div>
            </el-select>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="brandVisible = false">取 消</el-button>
            <el-button @click="brandChange" type="primary">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "component.table.change_brand",
    props: ['propsList'],
    data() {
        return {
            brandModel: '',
            compileBrand: [],	//修改品牌的数组
            brandPageRevamp: 1,
            revampBrandText: '',
            brandRevampSize: 0,
            brandVisible: false,
        }
    },
    created() {

    },
    methods: {
        // 获取修改品牌的接口
        amendBrand() {
            let data = {
                page: this.brandPageRevamp,
                limit: 10,
            };
            if (this.revampBrandText) {
                data.search.push({
                    key: "name",
                    value: this.revampBrandText,
                    op: 'search'
                })
            }
            this.$get(this.$apis.brandList, data)
                .then((res) => {
                    if (res.code == 200) {
                        this.compileBrand = res.data.list;
                        this.brandRevampSize = res.data.total;
                    } else {
                        this.common.message(this, res.message);
                    }
                })
                .catch((err) => {
                    this.common.message(this, err.message);
                });
        },
        brandChange() {
            if (!this.brandModel) {
                this.$message({
                    showClose: true,
                    message: "请选择品牌",
                    type: "warning",
                    offset: 200,
                    duration: 1000,
                });
                return false
            }
            let that = this;
            let data = {
                id: this.propsList.map(item => item.id),
                brand_id: this.brandModel
            }
            this.$put(this.$apis.batchGoodsBrand, data).then((res) => {
                if (res.code == 200) {
                    this.$message({
                        showClose: true,
                        message: "修改成功",
                        type: "success",
                        offset: 200,
                        duration: 1000,
                    });
                    that.brandVisible = false;
                    this.$emit('onload', true)
                } else {
                    this.common.message(this, res.message);
                }
            }).catch((err) => {
                this.common.message(this, err.message);
            })
        },
        reverseAmendbrand(flag) {
            this.brandText = '';
            this.brandPageRevamp = 1;
            this.amendBrand();
            // let rulesDom = this.$refs["my-select"].$el.querySelector(".el-input .el-input__suffix .el-input__suffix-inner .el-input__icon"); // 找到dom
            // if (flag) {
            // 	rulesDom.classList.add("is-reverse"); // 对dom新增class
            // } else {
            // 	rulesDom.classList.remove("is-reverse"); // 对dom新增class
            // }
        },
        amendTagChangebrand(val) {
            if (val === false) {
                this.compileBrand = []
            } else {
                this.revampBrandText = '';
                this.amendBrand();
            }
        },
        remoteMethodamend(e) {
            this.revampBrandText = e;
            this.amendBrand();
        },
        // 品牌分页
        handleCurrentChangeamend(val) {
            this.brandPageRevamp = val;
            this.amendBrand();
        },
    }
}
</script>

<style lang="scss" scoped>
.grounptc /deep/ .el-dialog {
    width: 660px;
    border-radius: 2px;
}

.grounptcbox {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.grounptcboxup {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ededed;
    box-sizing: border-box;
}

.grounptcboxup /deep/ .el-input {
    float: left;
    width: 320px;
}

.brand_content {
    margin: 20px 0;

    /deep/.el-select {
        width: 45%;

        .el-input {
            width: 100%;
        }
    }
}

.purpagebutkong {
    height: 34px;
}

.purpagebut {
    position: absolute;
    bottom: 0;
    padding: 0px 10px;
    box-sizing: border-box;
    left: 0;
    width: 100%;
    border-top: 1px solid #eee;
    background: #fff;
}

.purpagebut .btn-prev {
    float: left;
    min-width: 32px;
}

.purpagebut .btn-next {
    float: right;
    min-width: 32px;
}
</style>