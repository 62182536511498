<template>
	<span>{{ value }}</span>
</template>
<script>
export default {
	name: 'component.table.mapping',

	props: {//传递参数
		// v-model
		code_data: {
			type: Object,
			required: true
		},
		scope: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			component_id: this.code.domId(this.code_data),
			value: ''
		};
	},
	watch: {
		scope: {
			handler(newVal, oldVal) {
				if (newVal) {
					// 初始化
					this.init();
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() { },
	methods: {
		init() {
			if (this.code_data?.data_source) {
				this.code_data.data_source['successCallback'] = function(domThis, res) {
					const { key } = domThis.code_data;
					const now_value = domThis.scope.row[key]
					let _value = res[now_value];
					domThis.value = _value ?? now_value;
				}
				this.code_data.data_source['failCallback'] = function(domThis, err) {
					console.error('component.form.cascader', err);
				}
				this.code.formatDataSource(this, this.code_data.data_source);
			}

		}
	},
};
</script>
<style></style>