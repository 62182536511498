<template>
    <div v-if="scope.row.sign_type == 1">
        {{ scope.row.sign_year }}年<template v-if="scope.row.sign_month > 0">{{ scope.row.sign_month }}月</template>
    </div>
    <div v-else>
        长期
    </div>
</template>

<script>
export default {
    name: "component.table.huod_qiany_time",
    props: {
        code_data: {
            type: Object,
            required: true
        },
        scope: {
            type: Object,
            required: true
        }
    },
    data() {
        return {

        }
    }
}
</script>

<style scoped lang="scss">
.memberuser {
    width: 100%;
}

.memberuserleft {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-right: 12px;
    margin-top: 5px;
}

.memberuserleft img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.memberuseright {
    float: left;
    width: calc(100% - 52px);
}

.memberuseright p {
    font-size: 14px;
    color: #333;
    line-height: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>