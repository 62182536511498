<template>
    <div class="diy-import-more">
        <el-dialog :title="dialogTitle" :visible.sync="importMore" width="620px" @close="dialogClose">
            <div class="diy-import-content">
                <el-form :model="importForm" :rules="rules" ref="importForm" label-width="160px">
                    <el-form-item label="导入模板" prop="fail" class="import-class">
                        <el-input placeholder="请上传模板" readonly :value="importForm.fail.toString()">
                            <el-upload slot="append" class="upload-demo" ref="failUpload" action="" :multiple="false"
                                accept=".xls, .xlsx" :on-change="handeleOnchagne" :limit="1" :file-list="fileList"
                                :show-file-list="false" :auto-upload="false">
                                <el-button type="primary">
                                    选择文件
                                </el-button>
                            </el-upload>
                        </el-input>

                        <div class="diy-import-tips">请根据模板文件批量导入</div>

                    </el-form-item>
                    <el-form-item lavel="">
                        <el-button type="primary" @click="downTemplate">下载模版文件</el-button>

                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="importMore = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmit" :loading="importLoading">提 交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'diy-import-more',
    data() {
        return {
            importMore: false,
            importForm: {
                fail: []
            },
            rules: {
                fail: [{ type: 'array', required: true, message: '请选择模板文件', trigger: 'change' }],
            },
            fileList: [],
            importLoading: false,
            defaultTemplateUrl: '',
            timeoutSource: null,
			active_id:this.$route.query.active_id||this.$route.query.activity_id||'0',
            company_id:this.$route.query.company_id||'0',
        }
    },
    props: {
        // 弹窗标题
        dialogTitle: {
            type: String,
            default: '批量导入'
        },
        // 批量导入上传路径
        dialogImportUrl: {
            type: String,
            default: ''
        },
        // 公共资源下载keys
        dialogTemplateUrl: {
            type: String,
            default: ''
        },
        // 成功的回调，走后续的方法
        dialogImportCallback: {
            type: Function,
            default: () => { }
        }
    },
    created() {
        // 获取全局已登录的资源
        this.$get(this.$apis.resource, { keys: [this.dialogTemplateUrl] }).then(res => {
            if (res.code == 200) {
                this.defaultTemplateUrl = res.data[this.dialogTemplateUrl]
            }
        })
    },
    methods: {
        handeleOnchagne(file, fileList) {
            this.importForm.fail = [file['name']];
            this.fileList = fileList;
        },
        downTemplate() {
            if (!this.defaultTemplateUrl) {
                this.$message.error('缺少必要参数"模板下载路径"')
                return false;
            }
            window.parent.open(this.defaultTemplateUrl, '_self');
        },
        dialogClose() {
            this.$refs['importForm'].resetFields()
        },
        handleSubmit() {
            let that = this;
            this.$refs['importForm'].validate((valid) => {
                if (valid) {
                    if (!this.dialogImportUrl) {
                        this.$message.error('缺少必要参数"模板上传路径"')
                        return false;
                    }
                    this.importLoading = true;
                    let formData = new FormData();
                    formData.append("file", this.fileList[0].raw);
                    formData.append("company_id", this.company_id);
                    formData.append("activity_id", this.active_id);
                    let config = {
                        headers: {
                            "Content-Type": "mutipart/form-data",
                        },
                    };
                    this.$post(this.dialogImportUrl, formData, config).then((res) => {
                        if (res.code == 200) {
                            // this.$message.success(res.message);
                            // this.$refs['importForm'].resetFields()
                            // this.importMore = false;
                            // this.importLoading = false;
                            // this.$emit('dialogImportCallback', res)
                            setTimeout(() => {
                                that.checkFieldsDetail(res.data.job_id)
                            }, 2000)

                        } else {
                            this.$message.error(res.message);
                            this.importMore = false;
                        }
                    }).catch((err) => {
                        this.$message.error(err);
                    });
                } else {
                    //console.log('error submit!!');
                    return false;
                }
            })
        },
        // 查询导入状态
        checkFieldsDetail(id) {
            this.$get(`/file/${id}/get_job_status`).then(res => {
                if (res.code == 200) {
                    if (res.data[0]['job_status'] != 'WAIT') {

                        this.$message.success('导入成功');

                        this.$refs['importForm'].resetFields()
                        this.importMore = false;
                        this.importLoading = false;
                        this.$emit('dialogImportCallback', res)

                    } else {
                        this.timeoutSource = setTimeout(() => {
                            this.checkFieldsDetail(id)
                        }, 2000);
                    }
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.diy-import-more {
    .diy-import-content {
        padding: 30px 0;
        box-sizing: border-box;
    }


    .upload-demo /deep/ .el-button {
        height: 40px !important;
    }

    .diy-import-tips {
        color: #d9534f;
        line-height: 2;
        font-size: 14px;
    }

    .import-class /deep/ .el-input {
        width: 351px !important;
    }
}
</style>