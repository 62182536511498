<template>
	<div ref="contentContainer"></div>
</template>
<script>
export default {
	name: 'component.table.template2',
	props: {//传递参数
		// v-model
		code_data: {
			type: Object,
			required: true
		},
		scope: {
			type: Object,
			required: true
		}
	},
	data() {
		// 因为解析问题，暂时放弃
		//console.log(this.code_data.template);
		return {
			component_id: this.code.domId(this.code_data),
			key: this.code_data.key
		};
	},
	mounted() {
		this.renderContent();
	},
	created() { },
	methods: {
		renderContent() {
			const parser = new DOMParser();
			const doc = parser.parseFromString(this.code_data.template, 'text/html');
			const parsedContent = doc.body.innerHTML;
			this.$refs.contentContainer.innerHTML = parsedContent;
		}
	},
};
</script>
<style></style>