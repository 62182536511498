<template>
  <div>
    <div class="invite_head">
      <el-dialog title="员工选择" :visible.sync="cityDialog" width="672px" :before-close="handleClose">
        <!-- <el-transfer filterable :titles="['地区选择', '已选择20区域']" :filter-method="filterMethod" filter-placeholder="请输入城市拼音"
        v-model="value" :data="data" :render-content="renderFunc"></el-transfer>-->
        <TreeTransfer ref="transferRef" :title="['员工选择', `已选择 ${select_userNum} 个员工`]" :from_data="transferInfo.fromData"
          :to_data="transferInfo.toData" :defaultProps="{ label: 'name', children: 'list' }" pid="parent_id"
          height="540px" :filter="false">
        </TreeTransfer>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleTransferCancel">取 消</el-button>
          <el-button type="primary" @click="handleTransferSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import TreeTransfer from "el-tree-transfer"; // 引入
export default {
  components: {
    TreeTransfer,
  },
  name: "inviteUser",
  data() {
    return {
      cityDialog: false,
      company_id: this.$route.query.company_id || "0",
      active_id: this.$route.query.active_id || this.$route.query.activity_id || "0",
      transferInfo: {
        fromData: [],
        toData: [],
      },
      inviteData: [],
      cityListAll: {},
      syndicateData: {},
      select_userNum:0,
    };
  },
  watch:{
    transferInfo:{
      handler(newVal){
        if(newVal.toData){
          this.select_userNum=newVal.toData.reduce(function(pre,next,index){
            return pre+next.list.length
          },0)
        }
      },
      deep:true,
      immediate:true,
    },
  },
  props: {},
  created() {
    this.getStaff();
  },
  methods: {
    getStaff() {
      let that = this;
      let params = {
        search: [
          {
            key: "id", //用户id  在活动中和不在活动中的用户筛选
            value: 1, // value随便传
            op: "not_in", //
          },
        ],
        group_by_dep: 1,
      };
      if (this.company_id == "undefined") {
        this.company_id = "0";
      }
      if (this.active_id == "undefined") {
        this.active_id = "0";
      }
      this.$get(
        this.$apis.activeStaff + this.company_id + "/act_" + this.active_id,
        params
      ).then((res) => {
        if (res.code != 200) {
          return Message.error(res.message);
        } else {
          // console.log(res, 'res');
          let newarr=[];
          if(Array.isArray(res.data)){
            newarr=res.data;
          }else{
            newarr=res.data.list
          }
          newarr.forEach((item) => {
            if (item.id == 0) {
              item.id = -1;
            }
          });
          this.cityListAll = newarr;
          let arr = this.mapAddress(this.subResource2City(newarr));
          this.$set(this.transferInfo, "fromData", arr);
        }
      });
    },

    // 截取数据到市
    subResource2City(resource) {
      return resource.map((item) => {
        if (item.hasOwnProperty("list") && item.list.length > 0) {
          item.list = [
            ...item.list.map((ite) => {
              if (item.hasOwnProperty("list") && item.list.length) {
                ite["parent_id"] = item.id;
                ite["list"] = [];
                ite["isLast"] = true;
                ite["name"] = ite.nickname;
                return ite;
              } else {
                return ite;
              }
            }),
          ];
          return item;
        }
      });
    },
    // 地址映射
    mapAddress(arr) {
      // console.log(arr);
      const result = arr.map((item) => {
        item.parent_id = 0;
        // if(item.)
        return item;
      });
      return result;
    },
    handleTransferSubmit() {
      // 判断是否选择了地址
      if (this.transferInfo.toData.length == 0) {
        return Message.warning("请选择员工!");
      }
      this.inviteData = this.inviteData.concat(this.transferInfo.toData);
      this.inviteData.forEach((item) => {
        item.list.forEach((i) => {
          i.name = item.name;
        });
        item.children = item.list;
      });
      this.$set(this, "inviteData", this.inviteData);
      this.edit();
    },
    handleClose() {
      this.handleTransferCancel();
    },
    handleTransferCancel() {
      this.$refs["transferRef"]["clearChecked"]();
      // this.$set(this.transferInfo, "toData", []);
      this.cityDialog = false;
      this.modifyAddIndex = -1;
    },
    // 提交
    edit() {
      let that = this;
      if (!this.inviteData.length) {
        this.$message({
          type: "warning",
          message: "请添加邀请的员工",
          showClose: true,
          offset: 200,
          duration: 1000,
        });
        return false;
      }
      let department = [],
        user_id = [];
      this.inviteData.forEach((item) => {
        if (item.hasOwnProperty("list")) {
          item.list.forEach((i) => {
            user_id.push(i.id);
          });
        }
      });
      let params = {
        company_id: this.company_id,
        activity_id: this.active_id,
        user: user_id,
      };
      this.$post(this.$apis.addInviteUser, params)
        .then((res) => {
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "邀请成功",
              showClose: true,
              offset: 200,
              duration: 1000,
            });
            that.getStaff();
            that.$emit("change", true)
            setTimeout(() => {
              that.cityDialog = false;
            }, 1500);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.invite_head {
  & /deep/.el-dialog__header {
    border-bottom: none;
  }

  & /deep/.el-dialog__footer {
    border: none;
  }

  & /deep/.el-dialog__body {
    padding: 0 32px !important;
    box-sizing: border-box;
  }

  // transfer css
  & /deep/.transfer-left,
  /deep/.transfer-right {
    width: 280px;
    // height: 450px;
    background: #ffffff;
    border-radius: 3px;
    // border: 1px solid #DDDFE6;
    border: none;

    & .transfer-title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      display: flex;
      align-items: center;
      background-color: #fff;
      border: none;

      & .el-checkbox {
        display: none;
      }
    }

    & .transfer-main {
      width: 100%;
      border: 1px solid #ebeef5;

      & .el-tree {
        margin-top: 10px;
      }

      & .el-tree-node {
        min-height: 38px;

        & .el-tree-node__content {
          width: 100%;
          min-height: 38px !important;
        }
      }

      & .el-tree-node__expand-icon {
        font-size: 14px;
        // background: #FFFFFF;
        color: rgba(0, 0, 0, 0.6);
      }

      & .custom-tree-node {
        // width: 42px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 22px;
      }

      & .el-checkbox {
        position: absolute;
        right: 0;
      }
    }
  }

  & /deep/.transfer-center {
    width: 48px;
    left: 46%;

    & button {
      width: 24px;
      height: 24px;
      background: #eeeeee !important;
      border: 1px solid #dcdcdc !important;
      border-radius: 4px;
      padding: 0 !important;

      & i::before {
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }
}
</style>