<template>
    <div>
        <div v-if="code_data.template == 'name_id_image'">
            <div class="memberuser clearfloat">
                <div class="memberuserleft">
                    <img :src="scope.row.cover_pic" alt="" />
                </div>
                <div class="memberuseright">
                    <p>{{ scope.row.name }}</p>
                    <p>ID: {{ scope.row.id }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "component.table.template",
    props: {
        code_data: {
            type: Object,
            required: true
        },
        scope: {
            type: Object,
            required: true
        }
    },
    data() {
        return {

        }
    }
}
</script>

<style scoped lang="scss">
.memberuser {
    width: 100%;
}

.memberuserleft {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-right: 12px;
    margin-top: 5px;
}

.memberuserleft img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.memberuseright {
    float: left;
    width: calc(100% - 52px);
}

.memberuseright p {
    font-size: 14px;
    color: #333;
    line-height: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
</style>