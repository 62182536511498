<template>
	<div>
		<el-dialog title="选择图片" :visible.sync="photoVisible" class="picturebox" @close='closeDialog'>
			<div slot="title" class="dialog-title">
				<h4 class="title-text">选择图片 <span>(最小宽度600,最小高度600,大小不超过5MB)</span></h4>
			</div>
			<div class="photo_wrap">
				<div class="photo_container">
					<div class="photo_lefted">
						<div class="photo_leftedup clearfloat">
							<h5><i class="el-icon-folder-remove"></i> 我的图片</h5>
							<div class="photo_add">
								<el-popover placement="top" width="160" v-model="addvisible">
									<div class="photo_add_title">
										<i class="el-icon-warning"></i>
										<span>分类名称</span>
									</div>
									<div class="photo_add_input">
										<el-input v-model="photoClassName" clearable placeholder="分类名称" maxlength="32">
										</el-input>
									</div>
									<div style="text-align: right" class="photo_foot_add">
										<el-button size="mini" @click="cleargroup">取消</el-button>
										<el-button size="mini" type="primary" @click="photoClassSubmit">确定</el-button>
									</div>
									<div slot="reference" class="photo_add_class el-icon-plus"></div>
								</el-popover>
							</div>
						</div>
						<div class="photo_box">
							<div v-for="(item, index) in photoClass" :key="index"
								@click="photoClassClick(index,item.id)"
								:class="['photo_box_item',photoClassIndex == index ? 'active' : '',]">
								<i class="el-icon-folder-remove wjicon"></i>
								<span class="addtext">{{ item.name }}</span>
								<div class="photo_add">
									<el-popover placement="top" width="160" v-model="item.checked" trigger="manual">
										<div class="photo_add_title">
											<i class="el-icon-warning"></i>
											<span>分类名称</span>
										</div>
										<div class="photo_add_input">
											<el-input v-model="item.name" clearable placeholder="分类名称" maxlength="32">
											</el-input>
										</div>
										<div style="text-align: right" class="photo_foot_add">
											<el-button size="mini" @click="clearedit(index)">取消</el-button>
											<el-button size="mini" type="primary" @click="photoClassedit(item)">确定</el-button>
										</div>
										<i class="el-icon-edit groupeidt" v-if="item.is_default == 0" slot="reference"
											@click.stop="imgGroupeidt(item.id,index)"></i>
									</el-popover>
								</div>
								<i class="el-icon-delete groupicon" v-if="item.is_default == 0"
									@click.stop="delectgroup(item.id)"></i>
							</div>
						</div>
					</div>
					<div class="photo_right">
						<div class="photo_right_head clearfloat">
							<div class="photo_right_search">
								<el-input placeholder="请输入图片名称" clearable v-model="photoName" ref="photosearchtext">
									<i slot="suffix" class="el-input__icon el-icon-search curpoter"
										@click="photosearch()"></i>
								</el-input>
							</div>
							<div class="photo_upload pull-right clearfloat">
								<el-checkbox @change="imgAllChange" v-model="allcheckbox">全选</el-checkbox>
								<button @click="allimgdelect()">删除</button>
								<div class="photo_upimged">
									<el-upload action="String" :file-list= fileList
										:on-change="(file, fileList) => {handleChange(file, fileList);}"
										:auto-upload="false" accept="image/*" :limit="imgLimit" :show-file-list="false"
										multiple :on-success="handleAvatarSuccess"
										:before-upload="beforeAvatarUpload" :on-exceed="handleExceed"
										:on-error="imgUploadError">
										<el-button type="primary">+上传图片</el-button>
									</el-upload>
								</div>
							</div>
						</div>
						<div class="photo_right_content clearfloat">
							<template v-if="imgList && imgList.length">
								<div class="photo_right_item" v-for="(item, index) in imgList" :key="index"
									@click.stop="imageSelect(index)">
									<div class="img_item" :style="{ backgroundImage: `url(${item.file_url})` }"></div>
									<i class="img_delte el-icon-error" @click.stop="delImage(index)"></i>
									<div class="img_select"
										:class="[JSON.stringify(selectImage).indexOf(JSON.stringify(item)) != -1 ? 'active' : '',]">
										<i class="el-icon-check"></i>
									</div>
									<div class="img_size">{{item.img_width}} × {{item.img_height}}</div>
									<div class="imag_title">{{ item.file_name ? item.file_name : '-' }}</div>
								</div>
							</template>
							<template v-else>
								<div v-if="datatype" class="picturezw">
									暂无数据
								</div>
							</template>
						</div>
						<div class="photo_right_foot">
							<div class="photo_right_footer">
								<el-pagination :current-page.sync="photoPge" hide-on-single-page
									@current-change="handleCurrentChange" :total="photoToal" :page-size="eachPage"
									:page-count="5" layout="prev, pager, next">
								</el-pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini"  @click="photoVisible = false;selectImage = [];">取 消</el-button>
				<el-button size="mini" type="primary"  @click="imgSubmit()">提 交</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		props: ['multipled'],
		name: "pictureSelect",
		data() {
			return {
				// 图片选择
				editvisible: false,
				photoVisible: false, //图片选择器
				addvisible: false, //新增分类弹窗
				photoClassName: "", //新增图片分类名称
				photoClassIndex: 0,
				photoClass: [], //图片分类
				photoName: "", //搜索的图片名称
				photoPge: 1, //当前图片页数
				eachPage: 15,
				photoToal: 0, //总条数
				groupId: 0, //组的id
				imgList: [], //图片数组
				selectImage: [], //选中的图片数组
				imgdelectlist: [], //删除的id数组
				datatype: false,
				allcheckbox: false,
				productImgs: [],
				// isMultiple: false,
				imgLimit: 8,
				fileList: [],
			};
		},
		created() {
		},
		watch:{
			multipled(val){
				this.imgdelectlist = [];
				if(val == 0){
					this.imgdelectlist = '';
				}
			}
		},
		mounted: function() {
			// //console.log(this.multipled);  //可判断是否需要多选 0 单选  1多选,
			this.imggroup();
			this.imglist();
		},
		methods: {
			// 获取图片list
			imglist() {
				this.datatype = false;
				let data = {
					search: [{
						key: "file_name",
						value: this.photoName,
						op: "search"
					}, {
						key: "group_id",
						value: this.groupId,
						op: "equal"
					}],
					order: [{
						key: "id",
						value: "desc"
					}],
					page: this.photoPge,
					limit: this.eachPage
				};
				this.$get(this.$apis.imgList, data).then(res => {
					if (res.code == 200) {
						this.imgList = res.data.list;
						this.photoToal = res.data.total;
						this.datatype = true;
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					this.common.message(this, res.message);
				})
			},
			// 获取分组
			imggroup() {
				this.$get(this.$apis.imgGroup + "?type=image").then(res => {
					// //console.log(res)
					if (res.code == 200) {
						this.photoClass = res.data;
						for (let i in this.photoClass) {
							this.photoClass[i].checked = false;
						}
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},

			// 图片分类的选择
			photoClassClick(index, id) {
				if (this.photoClassIndex != index) {
					this.photoClassIndex = index;
					this.groupId = id;
					if (id == -1) {
						this.groupId = "";
					}
					this.selectImage = [];
					this.imgdelectlist = [];
					this.allcheckbox = false;
					this.imglist();
				}
			},
			// 删除分类组
			delectgroup(id) {
				this.$delete(this.$apis.groupimgDelete + id).then(res => {
					// //console.log(res)
					if (res.code == 200) {
						this.imggroup();
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
			// 全选
			imgAllChange() {
				this.selectImage = [];
				this.imgdelectlist = [];
				if (this.allcheckbox) {
					for (let i = 0; i < this.imgList.length; i++) {
						this.selectImage.push(this.imgList[i]);
						this.imgdelectlist.push(this.imgList[i].id);
					}
				}
			},
			// 全选删除
			allimgdelect() {
				if (this.imgdelectlist && this.imgdelectlist.length) {
					this.delectimg(this.imgdelectlist);
					this.allcheckbox = false;
				} else {
					this.$message({
						showClose: true,
						message: '请选择要删除的图片',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
				}
			},
			// 单个删除图片
			delImage(index) {
				this.imgdelectlist = [];
				let item = JSON.parse(JSON.stringify(this.imgList[index]));
				if (JSON.stringify(this.selectImage).indexOf(JSON.stringify(item)) != -1) {
					for (let i = 0; i < this.selectImage.length; i++) {
						if (this.selectImage[i].id == item.id) {
							this.selectImage.splice(i, 1);
							i--;
						}
					}
				}
				this.imgList.splice(index, 1);
				this.imgdelectlist.push(item.id);
				this.delectimg(this.imgdelectlist);
			},
			// 删除图片
			delectimg(id) {
				let data = {
					ids: id
				};
				this.$delete(this.$apis.imglistDelete, data).then(res => {
					if (res.code == 200) {
						this.imglist();
						this.imgdelectlist = []
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
			// 添加分类组
			photoClassSubmit() {
				if (!this.photoClassName) {
					this.$message({
						message: "请输入分类名称",
						type: "warning",
					});
					return;
				}
				let data = {
					name: this.photoClassName,
					type: "image",
				};
				this.$post(this.$apis.addImgroup, data).then(res => {
					// //console.log(res)
					if (res.code == 200) {
						this.imggroup();
						this.photoClassName = '';
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
				this.addvisible = false;
			},
			cleargroup() {
				this.addvisible = false;
				this.photoClassName = '';
			},
			clearedit(index) {
				this.photoClass[index].checked = false;
				this.$forceUpdate()
				this.$nextTick(() => {
					//console.log(this.photoClass);
				})
			},
			imgGroupeidt(id, index) {
				this.photoClass[index].checked = true;
				this.$forceUpdate()
			},
			photoClassedit(item) {
				if (!item.name) {
					this.$message({
						message: "请输入分类名称",
						type: "warning",
					});
					return;
				}
				let data = {
					name: item.name,
				};
				this.$put(this.$apis.editImgroup + item.id, data).then(res => {
					// //console.log(res)
					if (res.code == 200) {
						this.imggroup();
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
			// 搜索分组
			photosearch() {
				let searchtext = this.$refs.photosearchtext.value;
				this.photoName = searchtext;
				this.imglist();
			},
			imageSelect(index) {
				
				let item = this.imgList[index];
				if (JSON.stringify(this.selectImage).indexOf(JSON.stringify(item)) != -1) {
					for (let i = 0; i < this.selectImage.length; i++) {
						if (this.selectImage[i].id == item.id) {
							this.selectImage.splice(i, 1);
							this.imgdelectlist.splice(i, 1)
							i--;
						}
					}
					this.allcheckbox = false;
				} else {
					if(this.multipled){
						this.selectImage.push(item);
						this.imgdelectlist.push(item.id);
						// //console.log(this.multipled)
					}else{
						// //console.log(this.imgdelectlist,111)
						let arr=[];
						arr.push(item)
						this.selectImage=arr;
						this.imgdelectlist=item.id;
					}
				}
			},
			handleCurrentChange(val) {
				this.photoPge = val;
				this.imglist();
			},

			beforeAvatarUpload(file) {
				//console.log(111)
				//文件上传之前调用做一些拦截限制
				const isLt2M = file.size / 1024 / 1024 < 2;
				
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2MB!");
				}
				return isLt2M;
			},
			handleAvatarSuccess(res, file) {
				//图片上传成功
				//console.log(res);
				//console.log(file);
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			handleExceed(files, fileList) {
				//图片上传超过数量限制
				this.$message.error("上传图片不能超过8张!");
			},
			imgUploadError(err, file, fileList) {
				//图片上传失败调用
				this.$message.error("上传图片失败!");
			},
			// 选择图片
			handleChange(file, fileList) {
				let formData = new FormData();
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2MB!");
					return false;
				}
				formData.append("file", file.raw);
				formData.append("group_id", this.groupId);
				let config = {
					headers: {
						"Content-Type": "mutipart/form-data",
					},
				};
				this.$post(this.$apis.upImg, formData, config).then((res) => {
					if (res.code == 200) {
						// //console.log(res)
						this.$message({
							showClose: true,
							message: '上传成功',
							type: 'success',
							offset: 200,
							duration: 1000
						});
						this.fileList = []
						this.imglist();
					} else {
						this.common.message(this, res.message);
					}
				}).catch((err) => {
					this.common.message(this, err);
				});
			},
			closeDialog(){
				this.photoVisible = false;
				this.allcheckbox = false;
				this.selectImage = [];
			},
			// 提交
			imgSubmit(){
				this.photoVisible = false;
				this.$emit('change', this.selectImage)
				this.allcheckbox = false;
				this.selectImage = [];
			},
		},
	};
</script>
<style scoped>
	/* 图片选择器 */
	
	.photo_wrap {
	    padding: 16px 20px;
	    min-height: 108px;
	    overflow: visible;
	    font-size: 12px;
	    line-height: 1.5;
	    color: #666;
	    max-height: 620px;
	}
	
	.photo_container {
	    display: flex;
	    height: 520px;
	    box-sizing: border-box;
	    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
	
	.photo_left {
	    width: 170px;
	    height: 100%;
	    color: #333;
	    float: left;
	    position: relative;
	    display: flex;
	    flex-direction: column;
	}
	
	.photo_search {
	    width: 144px;
	    display: inline-block;
	    position: relative;
	    vertical-align: middle;
	    line-height: normal;
	}
	
	.photo_box {
	    flex: 1;
	    overflow-y: auto;
	    padding-bottom: 40px;
	    margin-top: 10px;
	    overflow-x: hidden;
	}
	
	.photo_box_item {
	    height: 32px;
	    line-height: 32px;
	    width: 100%;
	    font-size: 14px;
	    padding: 0 8px 0 12px;
	    box-sizing: border-box;
	    border-radius: 4px;
	    cursor: pointer;
	    position: relative;
	    transition: .3s;
	}
	
	.photo_box_item.active {
	    color: var(--fontColor, #fb6638) !important;
	    background: var(--primary-tips, #fff3ef) !important;
	}
	
	.photo_box_item .addtext {
	    margin-left: 18px;
	    display: inline-block;
	    max-width: 106px;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	}
	
	.photo_box_item .wjicon {
	    position: absolute;
	    top: 9px;
	    left: 10px;
	}
	
	.photo_box_item .groupicon {
	    position: absolute;
	    top: 10px;
	    right: 8px;
	    cursor: pointer;
	    display: none;
	}
	
	.photo_box_item:hover {
	    color: var(--fontColor, #fb6638) !important;
	    background: var(--primary-tips, #fff3ef) !important;
	}
	
	.photo_box_item:hover .groupicon {
	    display: block;
	}
	
	.photo_box_item .groupeidt {
	    position: absolute;
	    top: 10px;
	    right: 30px;
	    cursor: pointer;
	    display: none;
	}
	
	.photo_box_item:hover .groupeidt {
	    display: block;
	}
	
	.photo_class_add {
	    position: absolute;
	    bottom: 0;
	    display: inline-block;
	}
	
	.photo_add_title {
	    font-size: 14px;
	}
	
	.photo_add_title i {
	    color: #fbc712;
	    margin-right: 4px;
	}
	
	.photo_add_input {
	    width: 100%;
	    height: 38px;
	    margin: 10px 0;
	}
	.photo_add_input .el-input{width: 100%;}
	
	.photo_add_class {
	    padding: 0 16px;
	    height: 38px;
	    font-size: 14px;
	    border: solid 1px #ccc;
	    border-radius: 4px;
	    line-height: 38px;
	}
	
	.photo_foot_add {
	    margin: 0;
	    margin-top: 5px;
	}
	
	.photo_foot_add .el-button {
	    height: 28px;
	    line-height: 26px;
	    font-size: 12px;
		padding: 0 16px;
		margin-left: 0;
	    margin-right: 6px;
	}
	
	.photo_foot_add .el-button:last-child {
	    margin-right: 0;
	}
	
	.photo_right {
	    flex: 1;
	    position: relative;
	    padding: 0 0 0 20px;
	}
	
	.photo_right::after {
	    content: "";
	    position: absolute;
	    left: 0;
	    top: -20px;
	    bottom: -20px;
	    border-right: 1px solid #f2f2f2;
	}
	
	.photo_right_head {
	    padding-bottom: 16px;
	}
	
	.photo_right_search {
	    display: inline-block;
	    width: 320px;
	    position: relative;
	    vertical-align: middle;
	    line-height: normal;
	}
	.photo_right_search /deep/ .el-input{width: 100%;}
	
	.photo_right_search .curpoter {
	    cursor: pointer;
	}
	
	.pull-right {
	    float: right;
	    font-size: 14px;
	}
	
	.photo_upload_tips {
	    line-height: 26px;
	    padding-right: 10px;
	    color: #999;
	    margin-top: 4px;
	}
	
	.photo_upload_tips i {
	    font-size: 16px;
	}
	
	.photo_right_item {
	    width: 110px;
	    height: 140px;
	    float: left;
	    margin-right: 12px;
	    position: relative;
	    border-radius: 4px;
	    font-size: 12px;
	    text-align: center;
	    cursor: pointer;
	}
	
	.photo_right_item:nth-child(5n) {
	    margin-right: 0;
	}
	
	.photo_right_item .img_item {
	    width: 110px;
	    height: 110px;
	    background-size: contain;
	    background-repeat: no-repeat;
	    background-position: 50%;
	    border-radius: 4px;
	}
	
	.photo_right_item .img_delte {
	    position: absolute;
	    top: -7px;
	    right: -7px;
	    display: none;
	    font-size: 16px;
	}
	
	.photo_right_item:hover .img_delte {
	    display: block;
	}
	
	.photo_right_item .img_size {
	    position: absolute;
	    height: 24px;
	    line-height: 24px;
	    text-align: center;
	    bottom: 30px;
	    left: 0;
	    right: 0;
	    background: rgba(0, 0, 0, .5);
	    border-bottom-left-radius: 4px;
	    border-bottom-right-radius: 4px;
	    color: #fff;
	    display: none;
	}
	
	.photo_right_item:hover .img_size {
	    display: block;
	}
	
	.photo_right_item .imag_title {
	    overflow: hidden;
	    white-space: nowrap;
	    text-overflow: ellipsis;
	    -o-text-overflow: ellipsis;
	    margin-top: 5px;
	}
	
	.photo_right_item .img_select {
	    position: absolute;
	    line-height: 110px;
	    text-align: center;
	    top: 0;
	    bottom: 30px;
	    left: 0;
	    right: 0;
	    background: rgba(0, 0, 0, .5);
	    border-radius: 4px;
	    color: #fff;
	    display: none;
	    overflow: hidden;
	}
	
	.photo_right_item .img_select i {
	    font-size: 16px;
	}
	
	.photo_right_item .img_select.active {
	    display: block;
	}
	
	.photo_right_foot {
	    line-height: 28px;
	    position: absolute;
	    bottom: -2px;
	    left: 20px;
	    right: 0px;
	}
	
	.photo_right_footer {
	    display: flex;
	    justify-content: flex-end;
	}
	
	.photo_right_footer>.el-pagination>.btn-prev,
	.photo_right_footer>.el-pagination>.btn-next {
	    border: none !important;
	}
	.picturezw {
		padding: 10% 5%;
		box-sizing: border-box;
		font-size: 14px;
		text-align: center;
	}

	.picturebox /deep/ .el-dialog {
		border-radius: 4px;
		width: 860px;
	}

	.dialog-title .title-text {
		font-size: 16px;
		color: #333;
		font-weight: bold;
	}

	.dialog-title .title-text span {
		font-size: 14px;
		color: #939599;
		font-weight: 500;
	}

	.photo_lefted {
		width: 200px;
		height: 100%;
		padding-right: 16px;
		box-sizing: border-box;
	}

	.photo_leftedup {
		width: 100%;
	}

	.photo_leftedup h5 {
		font-size: 16px;
		color: #333;
		font-weight: 500;
		float: left;
	}

	.photo_add {
		float: right;
		cursor: pointer;
	}

	.photo_add_class {
		line-height: 1.5;
		border: 0;
		padding: 0;
		font-size: 17px;
		color: #666;
		cursor: pointer;
		height: auto;
		border-radius: 0;
	}

	.photo_upload .el-checkbox {
		float: left;
		line-height: 38px;
		margin-right: 8px;
		font-size: 14px;
		color: #333;
	}

	.photo_upload button {
		float: left;
		height: 38px;
		padding: 0 12px;
		border: 0;
		background: var(--fontColor);
		font-size: 14px;
		color: #fff;
		border-radius: 4px;
		margin-right: 8px;
	}

	.photo_upimged {
		float: left;
	}
</style>
