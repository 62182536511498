<template>
  <div ref="goods_table">
    <template v-if="scope.row.url">
      <div class="goods_detail d-flex align-items-center">
        <div class="goods_detail_img" @click.stop="goodDetail(scope)">
          <img :src="scope.row.cover_pic" alt="" :onerror="errorImg" />
        </div>
        <div class="table_name" @click.stop="goodDetail(scope)">
          <span v-if="scope.row.vr_type == 0" class="pt">{{
            common_info["goods.vr_type.map"][scope.row.vr_type]
          }}</span>
          <span v-else-if="scope.row.vr_type == 1" class="xn">{{
            common_info["goods.vr_type.map"][scope.row.vr_type]
          }}</span>
          {{ scope.row.name }}
        </div>
        <div class="table_iconed">
          <el-popover
            placement="top"
            width="515"
            trigger="click"
            :ref="'nameVisible' + scope.row.id" @show="popoverShow($event,scope.row.name)"
          >
            <div class="popover">
              <div class="popover_wrap d-flex align-items-center">
                <div class="popover_input">
                  <el-input v-model="goodName"></el-input>
                </div>
                <div class="popover_group">
                  <span @click.stop="modifyname(scope.row.id, goodName)"
                    >保存</span
                  >
                  <span @click.stop="nameCancel(scope)">取消</span>
                </div>
              </div>
            </div>
            <div class="goodcompile" slot="reference">
              <i class="el-icon-edit"></i>
            </div>
          </el-popover>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "component.table.goods_detail",
  props: ["code_data", "scope"],
  data() {
    return {
      errorImg:
        'this.src="' + require("../../../assets/images/lodingsb.svg") + '"', //图片加载失败
        goodName:'',
    };
  },
  computed: {
    common_info: {
      get() {
        let stateuser = {
          "goods.vr_type.map": {},
        };
        let val = this.$store.state.common_info || stateuser;
        return val;
      },
      set() {},
    },
  },
  mounted() {
    // console.log(this);
  },
  methods: {
    goodDetail(scope) {
      let url = scope.row.url;
      if (url) {
        window.open(url, "_blank");
      }
    },
    popoverShow(e,value){
       console.log(e,value)
       this.goodName=value;
    },
    // 修改商品名称
    modifyname(id, name) {
      if (!name) {
        this.$message({
          showClose: true,
          message: "商品名称不可为空",
          type: "warning",
          offset: 200,
          duration: 1000,
        });
        return false;
      }
      let data = {
        name: name,
      };
      this.$put(this.$apis.modifyGoodname + id, data)
        .then((res) => {
          // //console.log(res)
          if (res.code == 200) {
            this.$refs.goods_table.click();
            this.scope.row.name=name;
          } else {
            this.common.message(this, res.message);
          }
          // this.tableloading = false;
        })
        .catch((err) => {
          // //console.log(err)
          this.common.message(this, err.message);
        });
    },
    nameCancel(value) {
      let index = value.row.id;
      // 无奈之操作，如有更好，请替代
      this.$refs.goods_table.click();
    },
  },
};
</script>
<style lang="scss" scoped>
.goods_detail {
  width: 100%;
  cursor: pointer;
  .goods_detail_img {
    width: 50px;
    height: 50px;
    margin-right: 5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .table_name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    flex: 1;
    span {
      color: #2f74f5;
      background: #e9f0ff;
      padding: 1px 4px;
      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      border-radius: 2px;
    }
    .pt {
      background: #e9f0ff;
    }
    .xn {
      background: #ffebe0;
      color: #ff6b2c;
    }
  }
  .table_iconed {
    width: 42px;
  }
  &:hover {
    .goodcompile {
      display: block;
    }
  }
  .goodcompile:active {
    display: block;
  }
  .goodcompile {
    position: static;
  }
}
</style>