<template>
    <el-dialog title="设置部门" :visible.sync="setDepartmentDialog" width="600px">
        <div class="depart-dialog-box">
            <span class="label"> 选择部门： </span>
            <el-select v-model="setDepartmentValue" clearable>
                <el-option v-for="item in classArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="clearSetDepartment">取 消</el-button>
            <el-button class="addressqr" type="primary" @click="handleSetDepartment">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "component.table.change_department",
    props: ['propsList'],
    data() {
        return {
            setDepartmentDialog: false,
            setDepartmentValue: '',
            classArray: []
        }
    },
    created() {
        // 获取分组数据
        this.getCLassData()
    },
    methods: {
        getCLassData() {
            var dep_url = '/company/department/list';
            if (this.$route.query['company_id'] > 0) {
                dep_url = '/company/' + this.$route.query['company_id'] + '/department/list';
            }

            this.$get(dep_url).then(res => {
                this.classArray = res.data.list;
            })

        },
        // 确认修改部门
        handleSetDepartment() {
            if (!this.setDepartmentValue) return this.$message.warning('请选择部门')

            const id = this.propsList.map(item => item.id)
            this.$put('/staff/departmentBatch', { id, department_id: this.setDepartmentValue }).then(res => {
                if (res.code == 200) {
                    this.$message.success(res.message);
                    this.clearSetDepartment()
                    this.$emit('onload', true)
                } else {
                    this.$message.warning(res.message);
                }
            })
        },
        // 取消修改部门
        clearSetDepartment() {
            this.setDepartmentValue = "";
            this.setDepartmentDialog = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.depart-dialog-box {
    padding: 20px 30px;

    .label {
        width: 100px;
    }
}
</style>