<template>
    <div>
		<el-dialog title="修改库存" class="inventory" :visible.sync="repertoryVisible">
			<div class="repertory_content d-flex align-items-center justify-content-center">
				<p>库存：</p>
				<el-input type="number" v-model.number="repertoryModel" clearable placeholder="请输入库存" @input="repertoryInput" ></el-input>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="repertoryVisible = false">取 消</el-button>
				<el-button @click="repertoryChange" type="primary">确 定</el-button>
			</div>
		</el-dialog>
    </div>
</template>
<script>
export default {
    name:"component.table.inventory",
    data(){
        return {
            repertoryVisible:false,
            repertoryModel:0,
            goods_id:[],
        }
    },
    methods:{
        // 批量设置库存
			repertoryChange(){
				let that=this;
				let data={
					id:this.goods_id,
					stock:this.repertoryModel,
				}
				this.$put(this.$apis.batchGoodsStock,data).then((res)=>{
					if(res.code==200){
						this.$message({
							showClose: true,
							message: "修改成功",
							type: "success",
							offset: 200,
							duration: 1000,
						});
						that.repertoryVisible=false;
						that.$emit("change",true)
					}else{
						this.common.message(this, res.message);
					}
				}).catch((err)=>{
					this.common.message(this, err.message);
				})
			},
			repertoryInput(val){
				val = String(val).replace(/[^\d]/g,'')
			},
    }
}
</script>
<style lang="scss" scoped>
.repertory_content{
		margin:20px 0;
		/deep/.el-input{
			width: 45%;
		}
	}
</style>