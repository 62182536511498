<template>
    <div>
        <el-switch v-model="$formData[code_data.key]" :active-value="1" :inactive-value="0" inactive-color="#bbb"></el-switch>
    </div>
</template>

<script>
export default {
    name: "component.form.switch",
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped></style>