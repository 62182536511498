export default {
	groupList: '/group/list',//商品分组
	supportList: '/label/label_list',//标签列表
	brandList: '/brand/list',//品牌列表
	imgList: '/file/image/list',//图片选择器列表
	imgGroup: '/file/group',//图片选择器分组
	pcLogin: '/login', //登录
	refresh: '/token/refresh',//刷新token
	quit: '/logout/',//退出
	addGroup: '/group/group_add',//新增商品分组
	addSupport: '/label/label_add',//新增标签
	addBrand: '/brand/brand_add',//新增品牌
	delBatchBrand: "/brand/brand_del_batch",	//批量删除品牌
	supportAlldelect: '/label/label_batch_del',//批量删除
	addImgroup: '/file/group',//创建图片选择器组
	editImgroup: 'file/group/',//编辑图片分类
	loopBrand: '/brand/brand_detail/',//查看品牌详情
	brandedit: '/brand/brand_edit/',//编辑品牌报存
	groupedit: 'group/group_edit/',//编辑商品分组
	supportedit: '/label/label_edit/',//编辑标签
	imgGroupedit: '/file/group/',//编辑图片选择器分组
	groupDelete: '/group/group_del',//删除商品分组
	branDelete: '/brand/brand_del',//删除品牌
	brandexist: '/brand/exist/',//判断品牌是否存在
	groupimgDelete: '/file/group/',//删除图片选择器组
	imglistDelete: '/file/files',//删除图片选择器图片
	batchGoodsBrand: "/goods/goods_brand",	//商品列表批量修改品牌
	batchGoodsStock: "/goods/goods_stock",	//商品列表批量修改库存
	editExpress: "/order/express_edit/",	//修改物流信息
	//?员工角色接口
	roleList: '/permission/role',//角色列表页
	meNu: '/permission_all_list',//角色编辑新增页面的列表
	addiTion: '/permission/user',//员工列表
	increAsed: '/permission/user',//员工新增
	addRole: '/permission/role',//添加角色
	deletrole: '/permission/role',//删除角色
	deletStaff: '/permission/user/',//删除员工
	edIa: '/permission/user',//编辑员工
	obtain: '/permission/role_all_list',//获取所有角色
	xpassWord: '/permission/user_password',//修改密码
	editRole: '/permission/role',//修改角色
	ingleRole: '/permission/role',//单个角色信息

	//三方服务接口
	switchk: '/setting/option/trd_service',//获取开关按钮
	swutrtext: '/setting/option/sms',//获取短信详情接口
	editswutrtext: '/setting/option/sms',//修改短信详情接口
	objecooke: '/setting/option/file_storage',//获取对象存储接口
	exprekd: '/setting/option/express',//获取物流接口信息
	mailed: "/setting/option/mail",//获取邮件信息
	upImg: '/file/image',//上传图片
	infoList: '/cat/info/',//分类列表/删除分类
	infoDetail: '/cat/info/',	//商品分类详情
	catparentList: '/cat/parents/act_',//获取父级分类信息
	argumentsList: '/cat_param/param_list/',//商品参数列表
	argumentDelete: '/cat_param/param_del/',//删除商品参数
	catList: '/cat/all/',//商品参数分类
	loopPara: '/cat_param/param_detail/',//查看商品参数
	paramEdit: '/cat_param/param_edit/',//编辑商品参数
	addParam: '/cat_param/param_add/',//新增商品参数
	spuList: '/spu/list/',//获取spu列表
	spuDetail: '/spu/spu_detail/',//查看spu详情
	spuEdit: '/spu/spu_edit_preview/',//编辑spu预览
	goodsList: '/goods/list/act_',//商品列表
	orderList: '/order/list/',//订单列表
	resource: '/resources/config',//资源接口（需要登陆）
	grounding: '/goods/goods_on',//商品上架接口
	offshelf: '/goods/goods_off',//商品下架接口
	goodsDelete: '/goods/goods_del/',//商品删除接口
	setHot: '/goods/goods_hot/',//商品批量设置取消热卖
	setBest: '/goods/goods_best/',//商品批量设置取消推荐
	allPrice: '/goods/goods_price/',//商品批量改价
	allGroup: '/goods/goods_group/',//商品批量改分组
	modifyGoodname: '/goods/edit_name/',//修改商品名称
	allCat: '/goods/goods_cat/',//批量设置分类
	goodSku: '/goods/set_sku/',//商品详情商品货号
	addGoods: '/goods/goods_add/',//新增商品
	goodsEdit: '/goods/goods_detail/',//查看商品详情
	goodsEduted: '/goods/goods_edit/',//编辑报存接口
	recycle: '/recycle/list/',//回收站列表
	recycleDelete: '/recycle/recycle_del/',//回收站删除
	recycleRestore: '/recycle/recycle_restore/',//回收站恢复
	shoptcList: '/pc/select/goods/',//商品选择器商品列表
	attrEdit: '/spu/spu_edit/',//商品spu编辑报存接口
	addshopspu: '/spu/spu_add/',//商品spu新增报存接口
	delectAttr: '/spu/spu_del_goods/',//删除SPU绑定的商品
	addspu: '/spu/spu_preview/',//商品绑定spu预览
	addservice: '/content/article_type_search/',//售后保障，包装清单
	servicelabel: '/label/label_type_list/',//服务和标签
	goodslog: '/operate/log/goods/',//商品操作日志

	// 消息通知接口
	dtavsq: '/setting/option/sms_notify',//获取短信通知列表


	// 装修模快
	pickLink: "/pc/diy/get_pick_link",	//链接选择器
	brandSelect: "/select/brand",	//品牌选择器
	getElemnt: "pc/diy/get_modules_list",	//获取模块列表
	savefinish: "pc/diy/template",	//保存装修模块||获取模板列表
	temSetIndex: "pc/diy/",	//设置为首页
	delTemPlate: "pc/diy/template/",	//删除模板
	pickClass: "pc/select/category",	//装修中所需的分类列表
	templatExtend: "pc/diy/",	//获取模板扩展信息
	pcGoodsList: "/pc/select/goods/act_",	//pc装修的商品选择
	pcCatList: "/pc/select/category/act_",//pc装修的分类选择

	//会员模块接口
	memberUsers: '/user/user', //会员用户列表
	memberUserFreezeAndrescind: '/user/user_freeze', //会员冻结、解绑
	memberModifyPwd: '/user/user_password', //修改密码
	memberBalance: '/user/user_account/recharge',//增加/修改余额
	memberUserLevel: '/user/level',//会员等级列表
	memberLevel: '/user/level',//会员等级
	memberAdvanceUpdate: '/setting/option/member_advance_setting',//会员升级设置
	memberUserBlackList: '/user/user_blacklist',//拉黑用户
	levelIsExistApi: '/user/level_is_exist',//判断当前会员等级是否存在
	levelNameIsExistApi: '/user/level_name_is_exist',//会员名称是否存在

	// 财务模块接口
	financeRecordApi: '/finance/re_order',//充值记录列表
	finaceUserAccountApi: '/finance/user_account_log',//余额流水列表

	//获取所有管理愿列表
	permissonUserList: '/permission/user_all_list',

	// 文章分类列表
	contentArticleClassApi: '/content/article_cat',
	// 单个文章分类详情
	ownArticleCatInfo: '/content/article_cat/',
	// 订单
	orderattrbuite: '/order/remarks/',//订单备注
	cityList: '/district_list/',//省市区接口
	loopdelivery: '/order/to_send/',//查看订单可发货商品信息
	deliveryopen: '/order/send_submit/',//发货提交按钮
	deliveryopenNew: '/order/send_submit_new/',//发货提交按钮(新)
	orderoperate: '/operate/log/order/',//订单日志
	orderDetail: '/order/order_detail/',//订单详情
	addressedit: '/order/address/',//订单修改收货地址
	ordertrack: '/order/track/',//订单详情物流信息
	orderDelete: "/order/order_del",	//订单删除
	orderRecyclelist: "/order/recycle/list",	//订单回收站列表
	orderRecycleRecover: "/order/recycle",	//订单回收站的恢复
	orderRecycleDelete: "/order/recycle",	//订单回收站的删除
	orderRecycleDetail: "/order/recycle/",	//订单回收站的详情
	// 售后订单
	refund: '/aftersale/list/',//售后列表
	exchange: '/aftersale/handle/',//同意/拒绝退换货
	seaddress: '/aftersale/aftersale_address/',//获取售后地址
	servicedetails: '/aftersale/aftersale_detail/',//售后订单详情
	aftereceipt: '/aftersale/aftersale_receipt/',//确认收货
	aftereship: '/aftersale/send/',//重新发货
	refundtk: '/aftersale/aftersale_refund/',//售后退款
	// 订单评价
	evaluate: '/comment/list/',//评价列表
	commenthide: '/comment/hidden/',//评论隐藏
	commentdelect: '/comment/delete/',//删除评论
	commentreview: '/comment/comment_review/',//批量审核通过
	commentreply: '/comment/comment_reply/',//评论批量回复
	commentdetail: '/comment/comment_detail/',//评论详情
	// 发票
	invoicelist: '/invoice/list/',//发票列表
	invoicenumber: '/invoice/invoice_code/',//设置发票号
	invoicepexpress: '/invoice/invoice_express/',//发票发货
	invoiceupload: '/invoice/invoice_upload/',//上传发票
	invoicedetail: '/invoice/invoice_detail/',//发票详情
	// 订单售后设置
	setservice: '/setting/option/order_refund/',//获取订单退换货配置信息
	setservicesave: '/setting/option/order_refund/',//编辑订单退款货配置

	// 文章管理列表
	contentArticleApi: '/content/article',
	hbtntz: '/setting/option/email_notify',//获取邮件通知列表
	falytz: '/setting/option/sms_notify',//获取消息通知控制按钮
	artfalytz: '/setting/option/sms_notify',//修改通知按钮
	articleTypeApi: '/content/article_type', //编辑文章类型
	// 通用设置接口
	commonMemberApi: '/setting/option/common_member',//获取修改会员
	// 获取和修改订单设置
	commonOrderApi: '/setting/option/common_order',// 获取和修改订单设置
	commonGoodApi: '/setting/option/common_goods',// 获取和修改商品设置
	commonShowApi: '/setting/option/common_show',//获取修改显示设置接口
	ment: '/setting/option/alipay',//获取修改支付宝信息
	yliaw: '/setting/option/union_pay_btc',//获取修改银联网关
	newyliaw: '/setting/option/union_pay_btb',//获取修改新银联网关
	newffline: '/setting/option/offline_pay',//获取修改线下支付
	wxment: '/setting/option/wechat_pay',//获取修改微信支付
	switchTypesApi: '/setting/option/pay_service',//支付服务状态
	switchNotifyApi: '/setting/option/notify_service',//消息通知状态

	// 获取菜单列表
	MenuList: '/menu_list',

	// 修改密码-个人
	modifyPwd: '/user_password',
	// 消息中心
	noticeList: '/message/get_message_list',
	// 设置所有的消息已读
	allReadmessage: '/message/all_read_message',
	// 升级优化->清除缓存
	clearCache: '/setting/option/clear_cache',
	// 升级优化 -> 系统更新 -> 信息列表
	sysUpdateInfo: '/cloud/version/data',
	// 升级优化 -> 系统更新 -> 进行更新
	sysUpdateRenew: '/cloud/version/renew',
	// 设置 -> 员工管理 -> 操作日志 -> 操作人 只有id和名字
	sysStaffLogIdName: '/permission/user_operator_list',
	// 设置 -> 员工管理 -> 操作日志 -> 列表
	sysOperateLog: '/operate/log',
	// 设置 -> 员工管理 -> 操作日志 -> 查看详情
	sysOperateLogDetail: '/operate/log/',
	// 应用 -> 应用插件 -> 列表
	appPluginList: '/cloud/plugin/list',
	// 应用 -> 应用插件 -> 插件详情
	appPluginDetail: '/cloud/plugin/detail/',
	// 应用 -> 应用插件 -> 购买下单
	appPluginBuy: '/cloud/plugin/create_order/',
	// 应用 -> 应用插件 -> 应用安装
	appPluginInstall: '/cloud/plugin/install/',
	// 应用 -> 应用插件 -> 应用升级
	appPluginUpdate: '/cloud/plugin/renew/',
	// 应用 -> 权限 -> 添加邀请码
	appPluginSecret: '/cloud/authorize',
	// 全局未登录资源
	noLoginGlobal: '/resources/global',
	// 提交反馈
	setFeedback: '/cloud/feedback',
	// 设置 -> 敏感词库列表
	getSensitive: '/content/sensitive_words',
	// 设置 -> 添加敏感词库
	addSensitive: '/content/sensitive_words',
	// 设置 -> 编辑敏感词库
	editSensitive: '/content/sensitive_words/',
	// 应用 -> 采集助手 -> 单个采集
	singleAppCollection: '/collection/collection/',
	// 应用 -> 采集助手 -> 模板导入
	importAppCollection: '/collection/import/',
	// 获取采集助手默认资源
	configCollection: '/collection/resources/config',
	// 获取采集助手设置
	settingCollection: '/collection/setting',
	// 获取采集助手日志
	logCollection: '/collection/log',
	// 首页获取是否有系统更新消息
	sysUpdateMessage: '/message/get_sys_msg',
	// 设置首页弹窗是否已读
	setUpdateMessageRead: '/message/read_sys_msg',

	modifyPwd: '/user_password',

	//平台设置
	platSettingApi: '/setting/option/platform_setting',
	platServiceApi: "/setting/option/platform_customer_setting",
	platClientApi: "/setting/option/client",
	middleApi: "/open/option/platform",	//中台设置
	aboutApi: "/setting/option/common_agreement",	//协议设置
	// 平台设置-后台设置
	backSettingApi: '/setting/option/platform_backend_setting',
	code: '/code',

	//渠道设置
	getPcSeverice: "pc/option/customer_service",	//获取客服设置 
	getPcPcBottom: "pc/option/pc_bottom",	//获取底部设置
	getPcTopHead: "pc/option/pc_top",	//获取顶部设置
	getPcTrench: "pc/option/pc_base",	//获取pc渠道配置
	getChannelList: "cloud/plugin/client",	//渠道首页

	// 配送规则
	deliveryRuleApi: '/setting/delivery/rules', //获取运费模版
	// 退货地址Api
	refundAddressApi: '/setting/refund_address',

	// 移动端
	mobileShopSlect: "/app/select/goods/act_",	//移动端的商品选择
	mobileBrandSlect: "/app/select/brand",	//移动端的品牌选择
	mobileGroupSlect: "/app/select/group",	//移动端的商品分组
	mobileClassSelect: '/app/select/category/act_',	//移动端分类选择
	mobileLinkSelect: "/app/diy/get_pick_link",	//移动端链接选择器
	mobileFinishSave: "/app/diy/template",	//移动端装修保存
	mobileFinishList: "/app/diy/template/act_",	//移动端装修列表
	mobileFinishHome: "/app/diy/",	//移动端装修设置首页
	mobileFinishDel: "/app/diy/template/",	//移动端装修删除模板
	mobileFinishDetail: "/app/diy/template/",	//移动端装修详情
	mobileFinishDetailextend: `/app/diy/`,	//移动端装修详情
	shopImport: "/open/goods/import",	//导入商品

	// 从中台拉取商品
	shopgoodsList: "/open/select/goods",//商品列表
	shopGoodBrand: "/open/select/brand",	//品牌列表
	shopGoodGroup: "/open/select/group",	//商品分组列表
	shopGoodClass: "/open/select/category",	//商品分类列表


	// 包邮规则
	parcelapi: "/setting/free_delivery_rules/",//包邮规则
	parceldelect: "/setting/free_delivery_rules/",//删除包邮规则
	loopparce: "/setting/free_delivery_rules/",//查看包邮规则

	//批量发货
	logistics: "/order/express_list/",//批量发货获取物流
	consignmentlist: "/shipment/list/",//批量发货列表
	deliveryapi: "/shipment/import/",//批量发货发货接口
	deliveryapixg: "/shipment/update/",//批量发货修改发货接口
	batchdownload: "/shipment/download/",//批量发货下载文件

	// 公司
	corporActive: "/company/activity/",	//根据活动id查询活动信息和企业
	batchCreate: "/staff/batchCreate",	//批量生成员工
	activeStaff: "/staff/list/co_",	//查询该活动下有哪些员工
	editStaffPassword: "/user/user_password/",	//修改员工的密码
	addInviteUser: "/company/activity/user",	//添加活动用户
	aftersaleHandle: "/aftersale/aftersale_handle",	//同意/拒绝退换货
	allCompany: "/company/all",	//获取所有公司
	companyDetail: "/company/",	//获取公司详情

	// 数据
	information_list: '/data/statistics', //获取平台的数据统计
	information_detail: '/goods/exchange_record', // 获取员工的兑换统计

	// 所有的活动复制
	companyActivityCopy: '/company/activity/copy',
}


