<template>
    <div class="edit_head">
        <div class="back" @click="handleRoute">
            <i class="el-icon-arrow-left"></i>
            <span>返回</span>
        </div>
        <div class="hande_name" v-for="(item, index) in headName" :key="index">
            <i>/</i>
            <span>{{ item }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'EditHead',
    props: {
        headName: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    },
    methods: {
        handleRoute() {
            this.$emit('handleRoute')
        }
    }

}
</script>
<style scoped lang="less">
.edit_head {
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 3px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .back {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        box-sizing: border-box;
        cursor: pointer;

        & span {
            padding: 0 0 0 4px;
        }
    }

    & .hande_name {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        height: 20px;
        line-height: 20px;

        & i {
            color: #999999;
            padding: 0 8px;
        }
    }
}
</style>