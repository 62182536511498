<template>
	<div>
		<el-input v-model="$formData[code_data.key]" type="textarea" :maxlength="code_data.parameters.maxlength ?? 512"
			:show-word-limit="code_data.parameters.show_word_limit" :placeholder="code_data.parameters.placeholder ?? ''"
			:autosize="{ minRows: 2, max_rows: code_data.parameters?.max_rows || 2 }"></el-input>
	</div>
</template>
<script>
export default {
	name: 'component.form.textarea',

	props: {
		code_data: {
			type: Object,
			required: true
		},
		$formData: {}
	},
	data() {
		return {

		}
	}
};
</script>
<style scoped lang="scss">
::v-deep .el-textarea {
	.el-textarea__inner {
		padding-bottom: 20px;
	}

	.el-input__count {
		line-height: 20px;
		bottom: 2px;
		display: inline-block;
		width: calc(100% - 20px);
		text-align: right;
	}
}
</style>