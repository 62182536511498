<template>
    <el-dialog :visible.sync="dialogShow" width="600px" :show-close="false">
        <div class="dialog-box" v-if="Object.keys(scope).length">
            <img :src="scope.qrcode_url">
            <div class="link-item" v-if="scope.saas_link">
                管理端网址：
                <span>{{ scope.saas_link }}</span>
                <img src="/static/copy.png" @click="copyUrl(scope.saas_link)">
            </div>
            <div class="link-item" v-if="scope.link">
                链接：
                <span>{{ scope.link }}</span>
                <img src="/static/copy.png" @click="copyUrl(scope.link)">
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogShow = false" type="primary">关 闭</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'component.table.share',
    data() {
        return {
            dialogShow: false,
            scope: {}
        }
    },
    methods: {
        copyUrl(url) {
            if (!url) return false
            navigator.clipboard.writeText(url).then(() => {
                this.$message.success('复制成功');
            });
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
    display: none;
}

.dialog-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 60px 0 40px;
    box-sizing: border-box;

    .link-item {
        display: flex;
        align-items: center;
        margin-top: 24px;


        img {
            margin-left: 24px;
            width: 20px;
            cursor: pointer;
        }
    }
}
</style>