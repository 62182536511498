<template>
    <div class="component-form-input">
        <!-- 如果需要通过某些接口返回的数据进行渲染 -->
        <template v-if="code_data?.is_source">
            <el-input v-model="source_data[code_data.source_key]" type="text"
                :maxlength="code_data.parameters.maxlength ?? 128" :placeholder="code_data.parameters.placeholder ?? ''"
                :disabled="code_data.parameters?.is_disabled">
                <template v-if="code_data.parameters?.append" slot="append">{{ code_data.parameters?.append }}</template>
            </el-input>
        </template>
        <!-- 如果服务端返回的数据 需要进行往下解析一层数据 -->
        <template v-else-if="code_data?.is_filter">
            <el-input v-model="$formData[code_data.key][code_data.filter_key]" type="text"
                :maxlength="code_data.parameters.maxlength ?? 128" :placeholder="code_data.parameters.placeholder ?? ''"
                :disabled="code_data.parameters?.is_disabled">
                <template v-if="code_data.parameters?.append" slot="append">{{ code_data.parameters?.append }}</template>
            </el-input>
        </template>
        <!-- 正常渲染 -->
        <template v-else>
            <el-input v-model="$formData[code_data.key]" type="text" :maxlength="code_data.parameters.maxlength ?? 128"
                :placeholder="code_data.parameters.placeholder ?? ''" :disabled="code_data.parameters?.is_disabled">
                <template v-if="code_data.parameters?.append" slot="append">{{ code_data.parameters?.append }}</template>
            </el-input>
        </template>
    </div>
</template>

<script>
export default {
    name: 'component.form.input',
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {
            source_data: {}
        }
    },
    watch: {
        code_data: {
            handler(newVal, oldVal) {
                this.init()
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        init() {
            // 获取级联数据
            if (this.code_data.data_source && this.code_data.data_source.type == 'Api') {
                this.code_data.data_source['successCallback'] = function(domThis, res) {
                    domThis.source_data = res.data;
                }
                this.code_data.data_source['failCallback'] = function(domThis, err) {
                    console.error('component.form.cascader', err);
                }
                this.code.formatDataSource(this, this.code_data.data_source);
            }
        },
    }
}
</script>

<style lang="scss" scoped></style>