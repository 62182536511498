<template>
  <div>
    <el-dialog title="修改积分" :visible.sync="integralVisble">
      <div class="intergral_content">
        <el-form :model="interForm" label-width="220px">
          <el-form-item label="类型">
            <el-radio-group v-model="interForm.recharge_type">
              <el-radio :label="1">增加</el-radio>
              <el-radio :label="2">减少</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="数量">
            <el-input
              v-model="interForm.num"
              placeholder="请输入数量"
              type="number"
              @change="numInput"
            ></el-input>
          </el-form-item>
          <template v-if="interForm.recharge_type == 1">
            <el-form-item label="失效类型">
              <div>
                <el-radio-group v-model="interForm.efficacy_type">
                  <el-radio :label="1">按发放后</el-radio>
                  <el-radio :label="2">按周期</el-radio>
                  <el-radio :label="3">永久</el-radio>
                </el-radio-group>
              </div>
              <template v-if="interForm.efficacy_type == 1">
                <div class="d-flex align-items-center days_number">
                  <el-input
                    v-model="interForm.days"
                    type="number"
                    :min="0"
                  ></el-input>
                  <p>天</p>
                </div>
              </template>
              <template v-if="interForm.efficacy_type == 2">
                <el-date-picker
                  v-model="interForm.createvalue"
                  type="datetimerange"
                  start-placeholder="开始日期"
                  format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </template>
              <div class="intergral_toast">
                注：只有增加积分时，才会有失效时间
              </div>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click.stop="inter_close">取消</el-button>
        <el-button type="primary" @click.stop="inter_submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "integralEdit",
  data() {
    return {
      integralVisble: false,
      interForm: {
        recharge_type: 1,
        num: 0,
        efficacy_type: 3,
        days: 1, //天数
        createvalue: [],
      },
      user_id: "",
      multiple_select:'',
    };
  },
  props: {
    requestMethods: {
      type: String,
      default() {
        return "get";
      },
    },
    requestUrl: {
      type: String,
      default: "",
    },
  },
  created(){
  },
  methods: {
    inter_close() {
      this.integralVisble = !this.integralVisble;
    },
    numInput(e) {},
    getDate(n) {
      // n为多少天，-代表往前，+代表往后
      let date1 = new Date(),
        time1 =
          date1.getFullYear() +
          "-" +
          (date1.getMonth() + 1) +
          "-" +
          date1.getDate(); //time1表示当前时间
      let date2 = new Date(date1);
      date2.setDate(date1.getDate() + n);
      let time2 =this.common.timeStampTurnTime(date2);
      return time2;
    },
    inter_submit() {
      let that = this,
        request = "$" + this.requestMethods;
      if (!this.requestUrl) {
        return false;
      }
      if (!this.interForm.num) {
        that.$message({
          type: "warning",
          message: "请填写数量",
          showClose: true,
          offset: 200,
          duration: 1000,
        });
        return false
      }
      if (this.interForm.num < 0) {
        that.$message({
          type: "warning",
          message: "数量不可小于0",
          showClose: true,
          offset: 200,
          duration: 1000,
        });
        return false
      }
      if (this.interForm.efficacy_type == 2) {
        if (!this.interForm.createvalue.length) {
          that.$message({
            type: "warning",
            message: "请选择日期",
            showClose: true,
            offset: 200,
            duration: 1000,
          });
          return false;
        }
      }
      if (this.interForm.efficacy_type == 1) {
        if (!this.interForm.days || this.interForm.days < 0) {
          that.$message({
            type: "warning",
            message: "请填写正常的天数",
            showClose: true,
            offset: 200,
            duration: 1000,
          });
          return false;
        }
      }
      let params = {
        recharge_type: this.interForm.recharge_type, // 1增加 2减少
        price: this.interForm.num,
        desc: "管理员调账",
        user_id: this.user_id,
        activity_id: this.$route.query.activity_id||this.$route.query.active_id || "0",
      };
      if(this.multiple_select){
        let requestData = Object.keys(this.multiple_select).map(item => this.multiple_select[item]);
        let _ansRequest = [];
        for (let i = 0; i < requestData.length; i++) {
          let ansRequest = {}
          // 如果为数组，两项都有值再提取数据
          if (Array.isArray(requestData[i].value) && !requestData[i].value[0] && !requestData[i].value[1]) continue;
          // 如果为单项值，有值再提取数据
          if (requestData[i].value === null || requestData[i].value === '') continue;

          ansRequest['op'] = requestData[i].op
          ansRequest['key'] = requestData[i].key
          ansRequest['value'] = requestData[i].value
          if (requestData[i].type == 'component.search.dateRangeInput' && requestData[i].value != null && requestData[i].value != "") {
            ansRequest['value'] = [this.common.timeStampTurnTime(requestData[i].value[0]), this.common.timeStampTurnTime(requestData[i].value[1])]
          }
          else if (requestData[i].type == 'component.search.intervalInput' && requestData[i].value[0] && requestData[i].value[1]) {
            if (requestData[i].value[0] > requestData[i].value[1]) {
              this.$message.warning(`${requestData[i].label}区间输入有误！`)
              return false
            }
          }
          _ansRequest.push(ansRequest)
        }
        _ansRequest.push({
          key:"id",
          op:"in",
          value:"1"
        })
        params.search=_ansRequest
      }
      if (
        this.interForm.efficacy_type == 2 &&
        this.interForm.createvalue.length
      ) {
        params.start_at = this.common.timeStampTurnTime(
          this.interForm.createvalue[0]
        );
        params.end_at = this.common.timeStampTurnTime(
          this.interForm.createvalue[1]
        );
      }
      if (this.interForm.efficacy_type ==1 && this.interForm.days > 0) {
        params.start_at = this.common.timeStampTurnTime(new Date());
        params.end_at = this.getDate(Number(this.interForm.days));
      }
      this[request](this.requestUrl, params)
        .then((res) => {
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "修改成功",
              showClose: true,
              offset: 200,
              duration: 1000,
            });
            this.$emit("change",true)
            setTimeout(() => {
              that.integralVisble = !that.integralVisble;
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.intergral_content {
  margin: 20px 0;
}
.days_number {
  margin-top: 10px;
  /deep/.el-input {
    width: 100px;
  }
  > p {
    margin-left: 10px;
  }
}
.intergral_toast {
  font-size: 14px;
  color: #d9001b;
}
</style>