<template>
    <el-dialog title="操作日志" class="journaltc" :visible.sync="log_status">
        <div class="journalbox">
            <div class="batchtable">
                <div class="batchtablecon">
                    <table>
                        <thead>
                            <tr>
                                <th class="tableleft">事件</th>
                                <th class="tableleft">员工</th>
                                <th>时间</th>
                            </tr>
                        </thead>
                        <colgroup>
                            <col style="width: 40%" />
                            <col style="width: 30%" />
                            <col style="width: 30%" />
                        </colgroup>
                        <tbody>
                            <tr v-for="(item, index) in loglist" :key="index">
                                <td class="tableleft">{{ item.req_route }}</td>
                                <td class="tableleft">{{ item.opt_user }}</td>
                                <td>{{ item.created_at }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="orderlastbut clearfloat" style="padding-bottom: 10px">
                    <div class="orderlastbutright">
                        <el-pagination @size-change="logChange" hide-on-single-page @current-change="logCurrentChange"
                            :current-page.sync="pages" :page-sizes="[10, 20, 30, 40]" :page-size="limit"
                            layout="sizes, prev, pager, next, jumper" :pager-count="5" :total="logtotal">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="log_status = false">取 消</el-button>
            <el-button @click="log_status = false" type="primary">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'component.table.log',
    data() {
        return {
            log_status: false,
            goods_id: 0,
            pages: 1,
            limit: 20,
            loglist: [],
            logtotal: 0
        }
    },
    methods: {
        logChange(val) {
            this.limit = val;
            this.getLogList();
        },
        logCurrentChange(val) {
            this.pages = val;
            this.getLogList();
        },
        getLogList() {
            let data = {
                page: this.pages,
                limit: this.limit
            };
            this.$get(this.$apis.goodslog + this.goods_id, data)
                .then((res) => {
                    //console.log(res)
                    if (res.code == 200) {
                        this.loglist = res.data.list;
                        this.logtotal = res.data.total;
                        this.log_status = true
                    } else {
                        this.common.message(this, res.message);
                    }
                })
                .catch((err) => {
                    // //console.log(err)
                    this.common.message(this, err.message);
                });
        }
    },
    watch: {
        goods_id(navl) {
            //console.log(this, navl, 'navl');
            this.getLogList()
        }
    }
}
</script>

<style lang="scss" scoped>
.journalbox {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
</style>