<template>
  <div>
    <div class="boxchild">
      <div :class="['boxchildname',sub_navTitle?'active':'']">
        <el-tooltip class="item" effect="dark" :content="navtitle" placement="bottom">
          <p :class="['box_title',sub_navTitle?'active':'']">{{ navtitle }}</p>
        </el-tooltip>
        <template v-if="sub_navTitle">
          <el-tooltip class="item" effect="dark" :content="sub_navTitle" placement="bottom">
            <p class="box_title active">{{ sub_navTitle }}</p>
          </el-tooltip>
        </template>
      </div>
      <div class="boxchildlist">
        <ul>
          <!-- {{ secondList }} -->                         
          <div v-for="(item, index) in secondList" :key="item.id">
            <template v-if="item.children && item.children.length">
              <!--  item.children.length ? 'showactive' : ''   hasChildrenRoute(item.children) ||  -->
              <li
                :class="['boxchildtab', ((item.secondClickRoute) || item.children.some(i => i.childClickRoute == true) ? 'active' : ''), item.secondClickRoute ? 'baDegIcon' : 'degIcon']"
                @click.stop="secondClick(item)">
                <a href="javascript:void(0);">{{ item.permission_name }}<i class="el-icon-caret-bottom"></i></a>
                <el-collapse-transition>
                  <div class="boxchildown" v-show="(item.secondClickRoute)">
                    <!-- @click.stop="childClick(index, b)" -->
                    <div v-for="(ite) in item.children" :key="ite.id" @click="childClick($event, item.children, ite)">
                      <router-link :to="ite.route"
                        :class="[($route.fullPath == ite.route) || ite.childClickRoute == true ? 'on' : '',newpath == ite.route ? 'on' : '']">{{
                          ite.permission_name }}</router-link>
                    </div>
                  </div>
                </el-collapse-transition>
              </li>
            </template> 
            <template v-else>
              <li :class="[$route.fullPath == item.route ? 'active' : '', newpath == item.route ? 'active' : '']" @click.stop="secondClick(item)">
                <router-link :to="item.route">{{ item.permission_name }}</router-link>
              </li>
            </template>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import routes from '@/router'

export default {
  name: "secondNav",
  props: {
    navtitle: {
      type: String,
      default() {
        return "";
      }
    },
    secondList: {
      type: Array,
      default() {
        return [];
      }
    },
    newpath:{
      type:String,
      default(){
        return ''
      }
    },
    sub_navTitle:{
      type:String,
      default(){
        return ""
      }
    }
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    // 截取第一级路由
    subStringRoute(routePath) {
      if (routePath.lastIndexOf("/") != routePath.indexOf("/")) {
        let subString = routePath.substring(0, routePath.lastIndexOf("/"));
        if (subString.indexOf("/") != subString.lastIndexOf("/")) { //判断多级
          subString = this.subStringRoute(subString);
        }
        return subString;
      }
    },
    // 根据route.js判断子级是否选中
    hasChangeOfRouteJs() {
      // 根据当前路由截取父路由
      let parentRoute = this.subStringRoute(this.$route.fullPath);
      // 根据父路由在router.js中查找子级
      const { children } = routes.options.routes.find(item => item.path == parentRoute);
      //console.log(children);
      // 判断当前路由是否存在children中
      //console.log(children.some(item => this.$route.fullPath.includes(item.path)));
      return children.some(item => this.$route.fullPath.includes(item.path));
    },
    // 判断当前路由是否是某children的子级
    hasChildrenRoute(children) {
      if (Array.isArray(children)) {
        return children.some(ite => ite.route == this.$route.fullPath);
      } else {
        return false;
      }
    },
    // 一级点击
    secondClick(item) {
      this.$store.commit("SET_MENU_TYPE",item.group);
      //点击之前清空所有的secondClickRoute状态为false
      if ((item)) {
        this.secondList.filter(ite=>ite.id != item.id).map(ite => {
          // && item.route != ""
          if ((ite.children && ite.children.length > 0) ) {
            this.$set(ite, 'secondClickRoute', false);
            const children = ite.children.map(it => {
              this.$set(it, 'childClickRoute', false);
              if (it.hasOwnProperty('children') && it.children.length > 0) {
                this.$set(it, 'children', this.secondClick(it));
              }
              return it;
            })
            this.$set(ite, 'children', children)
          }
          return ite;
        })
        this.$set(item, 'secondClickRoute', !item.secondClickRoute);
      }
      // 判断是否存在子级
      //if (item && item.children.length > 0) { //存在子级
      // this.$set(item, 'secondClickRoute', !item.secondClickRoute);
      // 需要手动跳转第一个子级(考虑到后期添加子路由后，不知道重定向到哪个，故暂不实现)
      // this.$router.push({ path: item.children[0].route });
      //}
    },
    // 二级点击
    childClick(event, children, item) {
      // this.$store.commit("SET_MENU_TYPE",item.group);
      event.stopPropagation();
      if (children.length && item.route != "") {
        children.map(ite => {
          this.$set(ite, 'childClickRoute', false)
        })
        this.$set(item, 'childClickRoute', !item.childClickRoute);
      }
      // 判断是否存在子级
      //if (item) { //存在子级
      // this.$set(item, 'childClickRoute', !item.childClickRoute);
      // 需要手动跳转第一个子级(考虑到后期添加子路由后，不知道重定向到哪个，故暂不实现)
      // this.$router.push({ path: item.children[0].route });
      //}
    }
  }
};
</script>

<style scoped lang="less">
	.boxchild {
	    position: fixed;
	    top: 0;
	    left: 106px;
	    height: 100%;
	    width: 134px;
	    background: #fff;
	    border-right: 1px solid #eee;
	    box-sizing: border-box;
	    padding-top: 50px;
	    z-index: 10;
	}
  .box_title{
    font-size: 14px;
	  color: #333;
    padding-right: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .box_title.active{
    line-height: 37px;
  }
	
	.boxchildname {
	    width: 100%;
	    height: 50px;
	    line-height: 50px;
	    padding-left: 23px;
	    box-sizing: border-box;
	    font-size: 14px;
	    color: #333;
	    border-bottom: 1px solid #eee;
	}
  .boxchildname.active{
    height: 80px;
  }
	
	.boxchildlist {
	    width: 100%;
	    padding: 10px 0;
	    box-sizing: border-box;
	}
	
	.boxchildlist ul li {
	    width: 100%;
	}
	
	.boxchildlist ul li>a {
	    display: block;
	    height: 38px;
	    line-height: 38px;
	    padding-left: 23px;
	    box-sizing: border-box;
	    font-size: 15px;
	    color: #666;
	    transition: all 0.3s;
	}
	
	.boxchildlist ul li>a i {
	    display: inline-block;
	    margin-left: 14px;
	    font-size: 12px;
	    transition: all 0.3s;
	}
	
	.boxchildlist ul li.active>a {
	    color: var(--navFontActive1, #fb6638);
	    background: var(--navFontActiveBg1, #f3f4f7);
	}
	
	.boxchildlist ul li.active>a i {
	    color: var(--fontColor, #fb6638);
	    transform: rotate(180deg);
	}
	
	.boxchildown {
	    width: 100%;
	    display: none;
	}
	
	.boxchildown a {
	    display: block;
	    height: 38px;
	    line-height: 38px;
	    padding-left: 33px;
	    box-sizing: border-box;
	    font-size: 14px;
	    color: #666;
	    transition: all 0.3s;
	}
	
	.boxchildown a.on {
	    background: #f3f4f7;
	    color: var(--fontColor, #fb6638);
	}
	
	.boxchildown a:hover {
	    background: #f3f4f7;
	    color: var(--fontColor, #fb6638);
	}
	
	.boxchildlist ul li.boxchildtab.active .boxchildown {
	    display: block;
	}
.isShow {
  display: block !important;
}

.boxchildown {
  display: block;
}

.degIcon {
  & a>i {
    color: #666;
    transform: rotate(0) !important;
  }
}

.baDegIcon {
  & a>i {
    color: #666;
    transform: rotate(180deg) !important;
  }
}
</style>
