<!-- 
    type  时间选择器类型，
        - datetime 即可在同一个选择器里同时进行日期和时间的选择  
        - datetimerange 即可选择日期和时间范围
    default-time  默认时间（可选）
        - datetime  default-time="12:00:00"
        - datetimerange  default-time="['12:00:00', '08:00:00']"
            进行范围选择时，在日期选择面板中选定起始与结束的日期，时间默认为00:00:00
            数组1为起始时间，2为结束时间
    range-separator 时间范围分隔符（可选）
    placeholder 选择框默认显示内容（可选）
    start-placeholder 开始时间选择框的占位内容（可选）
    end-placeholder 结束时间选择框的占位内容（可选）
    disabled 禁用
    format 绑定值的格式  默认： yyyy-MM-dd HH:mm:ss / yyyy-MM-dd

    picker-options 选择器选项（可选）
    is_picker_options 是否是选择器选项
        - datetime 对应  datetime_options
        - datetimerange 对应  datetimerange_options
 -->
<template>
    <div>
        <el-date-picker v-model="$formData[code_data.key]" :type="code_data.parameters.type" clearable
            :placeholder="code_data.parameters?.placeholder" :start-placeholder="code_data.parameters?.start_placeholder"
            :end-placeholder="code_data.parameters?.end_placeholder" :disabled="code_data.parameters?.is_disabled"
            :format="code_data.parameters?.format" :default-time="code_data.parameters?.default_time"
            :range-separator="code_data.parameters?.range_separator"
            :picker-options="code_data.parameters?.is_picker_options && this[code_data.parameters.is_picker_options + '_options']">
        </el-date-picker>
    </div>
</template>

<script>
export default {
    name: "component.form.date-time-picker",
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {
            datetime_options: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },
            datetimerange_options: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const start = new Date().setHours(0, 0, 0, 0);
                        const end = new Date().setHours(23, 59, 59, 59);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '7天',
                    onClick(picker) {
                        const end = new Date().setHours(23, 59, 59, 59);
                        const start = new Date();
                        start.setTime(end - (3600 * 1000 * 24 * 7 - 1000));
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '30天',
                    onClick(picker) {
                        const end = new Date().setHours(23, 59, 59, 59);
                        const start = new Date();
                        start.setTime(end - (3600 * 1000 * 24 * 30 - 1000));
                        picker.$emit('pick', [start, end]);
                    }
                }]

            }
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor.el-input,
::v-deep .el-date-editor.el-input__inner {
    width: 275px !important;

}
</style>