<template>
    <div>
        <el-dialog :title="dialogTitle" :visible.sync="exportMore" width="620px" @close="dialogClose"
            :show-close="!exportLoading" :close-on-click-modal="false" :close-on-press-escape="false"
            :destroy-on-close="true">
            <div class="check-box">
                <el-checkbox :indeterminate="isIndeterminate" v-model="exportAllSelect" @change="handleCheckAllChange"
                    :disabled="exportLoading">全选</el-checkbox>
                <el-checkbox-group v-model="exportFieldList" @change="handleCheckedCitiesChange">
                    <el-checkbox v-for="item in fieldList" :label="item.key" :key="item.key" :disabled="exportLoading">{{
                        item.value }}</el-checkbox>
                </el-checkbox-group>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog">取 消</el-button>
                <el-button type="primary" @click="handleSubmit" :loading="exportLoading">提 交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'diy-export-more',
    data() {
        return {
            exportMore: false,
            exportLoading: false,
            fieldList: [],
            exportFieldList: [],
            exportAllSelect: false,
            isIndeterminate: false,
            // 时间标识
            timeoutSource: '',
            export_user_id: [],  //导出的人员id
        }
    },
    props: {
        // 弹窗标题
        dialogTitle: {
            type: String,
            default: '导出报表'
        },
        // 批量导出全局资源keys
        dialogExportKeys: {
            type: String,
            default: ''
        },
        // 订单导出api
        dialogExportUrl: {
            type: String,
            default: ''
        },
        // 导出是否需要父组件的搜索数据
        dialogExportIsSearch: {
            type: Boolean,
            default: false
        },
        dialogExportIdList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {
        if (!this.dialogExportKeys) {
            this.$message.error('缺少必要参数"批量导出全局资源keys"')
            return false;
        }
        this.$get(this.$apis.resource, { keys: [this.dialogExportKeys] }).then(res => {
            if (res.code == 200) {
                this.fieldList = res.data[this.dialogExportKeys];
            }
        })
    },
    methods: {
        dialogClose() {
            this.exportMore = false;
            this.exportLoading = false;
            this.exportFieldList = [];
            this.isIndeterminate = false;
            this.exportAllSelect = false;
        },
        cancelDialog() {
            if (this.timeoutSource) {
                clearTimeout(this.timeoutSource)
            }
            this.dialogClose()
        },
        // 兼容低代码，向上查找
        findExtractSearch(_this) {
            if (!_this.extractSearch && typeof _this.extractSearch != 'function') {
                return this.findExtractSearch(_this.$parent)
            } else {
                return _this
            }
        },
        handleSubmit() {
            if (!this.exportFieldList.length) {
                this.$message.warning('请选择需要导出的选项，最少选择一条');
                return false;
            }
            if (!this.dialogExportUrl) {
                this.$message.error('缺少必要参数"订单导出api"')
                return false;
            }
            this.exportLoading = true;
            // 通过组件父级实例获取search值
            let data = {};
            if (this.dialogExportIsSearch) {
                const extractSearch = this.findExtractSearch(this.$parent);
                console.log(extractSearch, 'extractSearch');
                if (!!!extractSearch) return this.$message.error('缺少必要方法"extractSearch"')
                data = extractSearch.extractSearch();
            }
            data['fields'] = this.exportFieldList;
            if (this.dialogExportIdList.length) {
                data['id'] = this.dialogExportIdList
            }
            if (this.export_user_id.length) {
                data['id'] = this.export_user_id
            }
            data['company_id'] = this.$route.query.company_id || '0'
            data['activity_id'] = this.$route.query.activity_id || '0'

            this.$get(this.dialogExportUrl, data).then(res => {
                //console.log(res, 'exportUrl');
                if (res.code == 200) {
                    setTimeout(() => {
                        this.checkFieldsDetail(res.data.job_id)
                    }, 2000)

                }
            })
        },

        checkFieldsDetail(id) {
            this.$get(`/file/${id}/get_job_status`).then(res => {
                if (res.code == 200) {
                    if (res.data[0]['job_status'] != 'WAIT') {
                        const { domain, download_url } = res.data[0]
                        if (domain) {
                            window.parent.open(domain + '/' + download_url, '_self');
                        } else {
                            window.parent.open(download_url, '_self');
                        }

                        this.$message.success('导出成功');

                        this.exportMore = false;
                        this.exportLoading = false;
                        this.exportFieldList = [];
                        this.isIndeterminate = false;
                        this.exportAllSelect = false;
                    } else {
                        this.timeoutSource = setTimeout(() => {
                            this.checkFieldsDetail(id)
                        }, 3000);
                    }
                }
            })
        },
        handleCheckAllChange(val) {
            this.exportFieldList = val ? this.fieldList.map(item => item.key) : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.exportAllSelect = checkedCount === this.fieldList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.fieldList.length;
        }
    }
}
</script>

<style lang="scss" scoped>
.check-box {
    padding: 30px 20px;
}
</style>