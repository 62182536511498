<!-- 
    参数详解
    - show-all-levels 是否展示完整路径
    - separator 选项分隔符
    - filterable 是否可搜索
    - clearable 是否可清空选项
    - placeholder 输入框占位符
    - disabled 是否禁用
 -->
<template>
    <div class="component-form-input">
        <el-cascader :append-to-body="false" :placeholder="code_data.parameters.placeholder ?? ''" clearable filterable
            v-model="$formData[code_data.key]" :options="apiData" :disabled="code_data.parameters?.is_disabled"
            :show-all-levels="code_data.parameters?.show_all_levels ?? true" :separator="code_data.parameters?.separator"
            :props="{
                value: code_data.parameters.value,
                label: code_data.parameters.label,
                children: code_data.parameters.children,
            }"></el-cascader>
    </div>
</template>

<script>
export default {
    name: 'component.form.cascader',
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {
            apiData: [],
        }
    },
    watch: {
        code_data: {
            handler(newVal, oldVal) {
                this.init()
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        init() {
            if (this.code_data.data_source && this.code_data.data_source.type == 'Api') {
                this.code_data.data_source['successCallback'] = function(domThis, res) {
                    if (domThis.code_data?.parameters?.is_filter) {
                        domThis.apiData = domThis.checkTreeData(res.data.list)
                    } else {
                        domThis.apiData = res.data.list;
                    }
                }
                this.code_data.data_source['failCallback'] = function(domThis, err) {
                    console.error('component.form.cascader', err);
                }
                this.code.formatDataSource(this, this.code_data.data_source);
            }
        },
        checkTreeData(data) {
            // 在级联选择器下，过滤的key为用户指定的children字段
            const filter_key = this.code_data.parameters.children;
            for (var i = 0; i < data.length; i++) {
                if (data[i][filter_key].length < 1) {
                    data[i][filter_key] = undefined;
                } else {
                    this.checkTreeData(data[i][filter_key]);
                }
            }
            return data;
        }
    }
}
</script>

<style lang="scss" scoped></style>