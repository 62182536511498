<template>
	<div>
		<template v-if="modifytype">
			<el-dialog title="批量调价" class="modify" :visible.sync="modifytype">
				<div class="changepice">
					<div class="changepiceup clearfloat">
						<span class="changepicetext">改价方式：</span>
						<div class="changepiceupradio">
							<template>
								<el-radio v-model="piceradio" label="1" @change="complile">公式修改</el-radio>
								<el-radio v-model="piceradio" label="0" @change="complile">直接修改</el-radio>
							</template>
						</div>
					</div>
					<div class="ttycgsxg clearfloat">
						<div class="ttycpt">
							<el-select  v-model="moneytext">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
						<span class="ttyctext">=</span>
						<template v-if="piceradio == 1">
							<div class="ttycpt">
								<el-select v-model="moneytexted">
									<el-option v-for="item in options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
							<div class="ttycpt">
								<el-select v-model="valueopt" placeholder="请选择">
									<el-option v-for="item in options1" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
						</template>
						<div class="ttycinp">
							<el-input-number type="number" :min="0" :max="99999999.99" v-model="picemoney" :controls="false" :precision="0">
								
							</el-input-number>
							<span class="spantext">{{valueopt == 3 || valueopt == 4  ? "%" : "元"}}</span>
						</div>
						<el-button class="ttycyljg" @click="tjpreview()">预览价格</el-button>
					</div>
					<div class="ttycbox">
						<el-table :data="tableDatalist" style="width: 100%">
							<el-table-column label="商品名称" width="260">
								<template slot-scope="scope">
									<div class="piceshop clearfloat">
										<div class="piceshopimg">
											<img :src="scope.row.cover_pic" alt="">
										</div>
										<div class="piceshoptext">
											<p>{{scope.row.name}}</p>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="售 价" align="center">
								<template slot-scope="scope">
									<div class="picetext">{{scope.row.price}}</div>
								</template>
							</el-table-column>
							<el-table-column label="划线价格" align="center">
								<template slot-scope="scope">
									<div class="picetext">{{scope.row.purchase_price}}</div>
								</template>
							</el-table-column>
							<el-table-column :label="moneytext == 1 ? '售价改价后' : '划线价格改价后'" align="center">
								<template slot-scope="scope">
									<div class="picetext">{{scope.row.priceafter}}</div>
								</template>
							</el-table-column>
							<el-table-column label="改价幅度" align="center">
								<template slot-scope="scope">
									<div class="picetext">{{scope.row.fd}}</div>
								</template>
							</el-table-column>
							<template slot="empty">
								<div class="empty_data">
									<p>暂无数据</p>
								</div>
							</template>
						</el-table>
					</div>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="clearprice()">取 消</el-button>
					<el-button @click="pricedit()" type="primary" :disabled="nodefind">确定修改</el-button>
				</div>
			</el-dialog>
		</template>
	</div>
</template>

<script>
	export default {
		name: "changePrice",
		props: ['picelist'],
		data() {
			return {
				nodefind:true,
				moneytext: '1',
				moneytexted: '1',
				modifytype: false, //控制批量调价是否显示
				piceradio: '1', //判断改价方式
				options: [{
					value: '1',
					label: '售 价'
				}, {
					value: '2',
					label: '划线价格'
				}],
				options1: [{
					value: '1',
					label: '加'
				}, {
					value: '2',
					label: '减'
				}, {
					value: '3',
					label: '乘'
				}, {
					value: '4',
					label: '除'
				}],
				valueopt: '',
				picemoney: 0,
				tableDatalist: this.picelist, //表格数据
			};
		},
		watch: {
			modifytype(val) {
				if (val) {
					this.tableDatalist = this.picelist;
					// console.log(this.picelist)
				}
			}
		},
		mounted() {},
		methods: {
			proving1() {
				this.picemoney=picemoney.replace(/[^\.\d]/g,''); //清除"数字"和"."以外的字符
				this.picemoney=picemoney.replace(/^\./g,'');  //验证第一个字符是数字而不是.
				this.picemoney=picemoney.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的.
				this.picemoney=picemoney.replace(".","$#$").replace(/\./g,"").replace("$#$",".");//只允许输入一个小数点
				this.picemoney=picemoney.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); //只能输入两个小数
			},
			complile(){
				if(this.piceradio == 0){
					this.valueopt = '';
				}
				this.picemoney = 0;
			},
			tjpreview(){
				let error = 0;
				let tabnew = JSON.parse(JSON.stringify(this.tableDatalist));
				if(!this.picemoney || Number(this.picemoney) <= 0){
					this.$message({
						showClose: true,
						message: '请输入大于0的价格信息',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false
				}
				for (let i = 0; i < tabnew.length; i++) {
					// 公式修改
					if(this.piceradio == 1){
						if(!this.valueopt){
							this.$message({
								showClose: true,
								message: '请选择加减乘除',
								type: 'warning',
								offset: 200,
								duration: 1000
							});
							return false
						}
						if(this.valueopt == 1){
							tabnew[i].priceafter = this.moneytexted == 1 ? Number(tabnew[i].price)+ Number(this.picemoney) : Number(tabnew[i].purchase_price) + Number(this.picemoney)
						}else if(this.valueopt == 2){
							tabnew[i].priceafter = this.moneytexted == 1 ? Number(tabnew[i].price) - Number(this.picemoney) : Number(tabnew[i].purchase_price) - Number(this.picemoney)
						}else if(this.valueopt == 3){
							tabnew[i].priceafter =  this.moneytexted == 1 ? (Number(tabnew[i].price) * Number(this.picemoney))/100 : (Number(tabnew[i].purchase_price) * Number(this.picemoney))/100
						}else if(this.valueopt == 4){
							tabnew[i].priceafter = this.moneytexted == 1 ? (Number(tabnew[i].price) / Number(this.picemoney))*100 : (Number(tabnew[i].purchase_price) / Number(this.picemoney))*100
						}
						tabnew[i].priceafter = Number(tabnew[i].priceafter).toFixed(2);
						tabnew[i].fd = this.moneytexted == 1 ? Number(tabnew[i].priceafter) - Number(tabnew[i].price) : Number(tabnew[i].priceafter) - Number(tabnew[i].purchase_price)
						tabnew[i].fd = tabnew[i].fd.toFixed(2);
						if(tabnew[i].priceafter<0){
							error ++;
						}
					}
					// 直接修改
					if(this.piceradio == 0){
						tabnew[i].priceafter = Number(this.picemoney)
						tabnew[i].priceafter = tabnew[i].priceafter.toFixed(2);
						tabnew[i].fd = this.moneytext == 1 ? Number(tabnew[i].priceafter) - Number(tabnew[i].price) : Number(tabnew[i].priceafter) - Number(tabnew[i].purchase_price) 
						tabnew[i].fd = tabnew[i].fd.toFixed(2);
					}
				}
				// console.log(tabnew)
				if(error>0){
					this.$message({
						showClose: true,
						message: '价格有误，请重新计算',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
				}
				this.nodefind = false;
				this.tableDatalist = tabnew;
				this.$forceUpdate();
			},
		
			clearprice(){
				this.modifytype = false;
			},
			pricedit(){
				let idlist=[];
				for (let i = 0; i < this.tableDatalist.length; i++) {
					idlist.push({"goods_id":this.tableDatalist[i].id,"price":this.tableDatalist[i].priceafter})
				}
				let data = {
					type:this.moneytext,
					list: idlist
				};
				// return false;
				this.$put(this.$apis.allPrice, data).then(res => {
					// console.log(res)
					if (res.code == 200) {
						this.valueopt = '';
						this.picemoney = 0;
						this.$emit("showCityName");
						this.nodefind = true;
					} else {
						this.valueopt = '';
						this.picemoney = 0;
						this.nodefind = true;
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// console.log(err)
					this.common.message(this, err.message);
				});
				this.modifytype = false;
			}
		},
	};
</script>

<style scoped>
	.modify /deep/ .el-dialog {
		width: 820px;
		border-radius: 2px;
	}
	
	.changepice {
		width: 100%;
		padding: 20px;
		box-sizing: border-box;
	}
	
	.changepiceup {
		width: 100%;
		margin-bottom: 20px;
	}
	
	.changepiceup .changepicetext {
		float: left;
		font-size: 14px;
		color: #333;
		font-weight: 400;
		margin-right: 8px;
	}
	
	.changepiceupradio {
		float: left;
		font-size: 14px;
		color: #333;
	}
	
	.ttycgsxg {
		width: 100%;
		margin-bottom: 20px;
	}
	
	.ttycpt {
		float: left;
		width: 122px;
		max-width: 140px;
		margin-right: 12px;
	}
	.ttycpt /deep/ .el-select{width: 100%;}
	.ttycpt /deep/ .el-select > .el-input{width: 100%;}
	
	.ttycpt .el-input__inner {
		height: 40px;
	}
	
	.ttyctext {
		float: left;
		font-size: 14px;
		color: #000;
		opacity: 0.4;
		line-height: 40px;
		margin-right: 12px;
	}
	
	.ttycinp {
		float: left;
		width: 155px;
		max-width: 155px;
		margin-right: 12px;
	}
	
	.ttycinp /deep/ .el-input {
		width: 100px;
	}
	.ttycinp /deep/ .el-input .el-input__inner{border-radius: 4px 0 0 4px;}
	
	.ttycbox {
		width: 100%;
		overflow-y: auto;
		height: 350px;
	}
	
	.piceshop {
		width: 100%;
	}
	
	.piceshopimg {
		float: left;
		width: 50px;
		height: 50px;
		border-radius: 4px;
		margin-right: 10px;
	}
	
	.piceshopimg img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 4px;
	}
	
	.piceshoptext {
		float: left;
		width: calc(100% - 60px);
	}
	
	.piceshoptext p {
		font-size: 14px;
		color: #333;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	
	.picetext {
		width: 100%;
	}
	
	.picetext p {
		font-size: 14px;
		color: #333;
		font-weight: 400;
	}
	.ttycinp .el-input-number{float: left;width: 100px;}
	.ttycinp .el-input-number .el-input__inner{border-radius: 4px 0 0 4px;}
	.ttycinp .spantext{float: left;text-align: center;line-height: 38px;padding: 0 16px;border: 1px solid #dcdfe6;
	border-left: 0;background: #f5f7fa;color: #909399;border-radius: 0 4px 4px 0;font-size: 14px;box-sizing: border-box;}
</style>
