<template>
    <div class="diy-footer-box">
        <div class="fill-footer"></div>
        <div class="button-footer">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'diy-footer-button'
}
</script>

<style lang="scss" scoped>
.diy-footer-box {

    .fill-footer {
        height: 60px;
    }

    .button-footer {
        position: fixed;
        bottom: 0;
        right: 0;
        width: calc(100% - 240px);
        height: 60px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }
}
</style>