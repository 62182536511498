<!-- Layout  -->
<template>
  <div class="layout">
    <!-- navleft -->
    <div class="sidebar">
      <Navleft @handleRouteLink="handleRouteLink"></Navleft>
    </div>
    <div class="main-container">
      <!-- head -->
      <Header></Header>
      <!-- secondNav -->
      <!-- 首页不需要 v-if="$route.fullPath !='/'"-->
      <secondNav
        :secondList="route"
        :navtitle="title" :sub_navTitle="subtitle"
        v-if="$route.fullPath != '/'"
        :newpath="path"
      ></secondNav>
      <!-- app-main -->
      <AppMain></AppMain>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header";
import Navleft from "@/components/navleft";
import AppMain from "./components/AppMain.vue";
import secondNav from "@/components/secondNav";
import { mapGetters } from "vuex";
import { Message } from "element-ui";
export default {
  name: "Layout",
  components: {
    AppMain,
    Header,
    Navleft,
  },
  data() {
    return {
      route: [], //二级路由
      title: "", //二级路由tite
      menuMap: {},
      path: "",
      routeMap: {},
      company_data: "",
      syndicateData:"",
      subtitle:'',
    };
  },
  async mounted() {
    // await this.getNoLoginGlobal()
    this.getMenuList();
    // if (this.menuLists) {
    //     if (this.$route.fullPath) {
    //         if (this.$route.fullPath.lastIndexOf("/") != this.$route.fullPath.indexOf("/")) {
    //             let subString = this.$route.fullPath.substring(0, this.$route.fullPath.lastIndexOf("/"));
    //             if (subString.indexOf("/") != subString.lastIndexOf("/")) { //判断多级
    //                 subString = subString.substring(0, subString.lastIndexOf("/"));
    //                 const refrenChildren = this.changeMenuList2Map(this.menuLists).find(item => item.route == (subString));
    //                 if (refrenChildren) {
    //                     this.$nextTick(() => {
    //                         this.$set(this, "route", refrenChildren.children);
    //                         this.$set(this, "title", refrenChildren.title);
    //                     });
    //                     this.$forceUpdate();
    //                     //console.log("refrenChildren-1", refrenChildren);
    //                 }
    //             } else {
    //                 const refrenChildren = this.changeMenuList2Map(this.menuLists).find(item => item.route == (subString));
    //                 if (refrenChildren) {
    //                     this.$nextTick(() => {
    //                         this.$set(this, "route", refrenChildren.children);
    //                         this.$set(this, "title", refrenChildren.title);
    //                     });
    //                     this.$forceUpdate();
    //                 }
    //                 //console.log("refrenChildren-2", refrenChildren);
    //             }
    //         } else {
    //             const refrenChildren = this.changeMenuList2Map(this.menuLists).find(item => item.route == (this.$route.fullPath));
    //             this.$nextTick(() => {
    //                 this.$set(this, "route", refrenChildren.children);
    //                 this.$set(this, "title", refrenChildren.title);
    //             });
    //             this.$forceUpdate();
    //         }
    //     }
    // }
  },
  watch: {
    watchData: {
      handler(newVal) {
        let that = this;
        if (
          this.subStringRoute(this.$route.fullPath) == "/code" &&
          this.$route.query.company_id
        ) {
          if(this.$route.query.activity_id){
            this.common.getCorporation(this)
          }else{
            that.syndicateData="";
            this.getCompany();
          }
        } else {
          that.company_data = "";
          that.syndicateData="";
        }
        if(this.$route.query.activity_id){
          this.common.getCorporation(this)
        }else{
          that.syndicateData="";
        }
        let path = this.$route.path,
          pathName = this.$route.name;
        let map = this.routeMap;
        if (map && map[pathName]) {
          path = map[pathName];
        }
        this.path = path;
        let menuType = newVal.menuType;
        if (this.menuMap[this.$route.path]) {
          menuType = this.menuMap[path][0];
        } else {
          for (let i in this.menuMap) {
            if (path.indexOf(i) >= 0) {
              menuType = this.menuMap[i][0];
            }
          }
        }
        // console.log(menuType,this.subStringRoute(this.$route.fullPath))
        this.$store.commit("SET_MENU_TYPE", menuType);
        if (newVal && newVal.menuLists[menuType]) {
          if (this.$route.fullPath) {
            let subString = this.subStringRoute(this.$route.fullPath);
            if (menuType == "base") {
              const refrenChildren = this.changeMenuList2Map(
                newVal.menuLists[menuType]
              ).find((item) => item.route == subString);
              // 此判断是决定要企业模块下活动列表，员工列表，更改二级路由；
              if (
                this.subStringRoute(this.$route.fullPath) == "/code" &&
                this.$route.query.company_id
              ) {
                let children = [
                  {
                    group: "base",
                    icon: "el-icon-s-company",
                    id: 538,
                    menu_type: 1,
                    permission_key: "",
                    permission_name: "企业管理",
                    pid: 536,
                    children: [
                      {
                        pid: 538,
                        permission_key: "",
                        group: "base",
                        permission_name: "活动列表",
                        route:
                          "/code/index/com-activity-list?company_id=" +
                          this.$route.query.company_id,
                        menu_type: 1,
                        icon: "",
                        id: 590,
                        children: [],
                      },
                    ],
                    route:"/code/index/company-list",
                  },
                ];
                let second_child=[];
                if (this.$route.query.activity_id) {
                  second_child = [
                    {
                      pid: 538,
                      permission_key: "",
                      group: "base",
                      permission_name: "DIY装修",
                      route:
                        "/code/fitment?activity_id=" +
                        this.$route.query.activity_id +
                        "&company_id=" +
                        this.$route.query.company_id,
                      menu_type: 1,
                      icon: "",
                      id: 591,
                      children: [],
                    },
                    {
                      pid: 538,
                      permission_key: "",
                      group: "base",
                      permission_name: "活动员工",
                      route:
                        "/code/index/com_actvity_staff?activity_id=" +
                        this.$route.query.activity_id +
                        "&company_id=" +
                        this.$route.query.company_id,
                      menu_type: 1,
                      icon: "",
                      id: 592,
                      children: [],
                    },
                    {
                      pid: 538,
                      permission_key: "",
                      group: "base",
                      permission_name: "商品管理",
                      route:
                        "/code/index/goods-list?activity_id=" +
                        this.$route.query.activity_id +
                        "&company_id=" +
                        this.$route.query.company_id,
                      menu_type: 1,
                      icon: "",
                      id: 593,
                      children: [],
                    },
                    {
                      pid: 538,
                      permission_key: "",
                      group: "base",
                      permission_name: "商品分类",
                      route:
                        "/code/goods_manage?activity_id=" +
                        this.$route.query.activity_id +
                        "&company_id=" +
                        this.$route.query.company_id,
                      menu_type: 1,
                      icon: "",
                      id: 594,
                      children: [],
                    },
                  ];
                } else {
                  second_child = [
                    {
                      pid: 538,
                      permission_key: "",
                      group: "base",
                      permission_name: "企业员工",
                      route:
                        "/code/demo/index?company_id=" +
                        this.$route.query.company_id,
                      menu_type: 1,
                      icon: "",
                      id: 539,
                      children: [],
                    },
                    {
                      pid: 538,
                      permission_key: "",
                      group: "base",
                      permission_name: "批量生成员工",
                      route:
                        "/code/index/company_batch-create?company_id=" +
                        this.$route.query.company_id,
                      menu_type: 1,
                      icon: "",
                      id: 595,
                      children: [],
                    },
                  ];
                }
                children[0].children=children[0].children.concat(second_child)
                refrenChildren.children = children;
                refrenChildren.title = this.company_data.name;
                // console.log(this.company_data,'com',refrenChildren)
              }
              let refchildre = refrenChildren
                ? this.handleRouteActive(
                    JSON.parse(JSON.stringify(refrenChildren))
                  )
                : "";
              // console.log(refrenChildren,refchildre.children)
              this.$nextTick(() => {
                this.$set(this, "route", refrenChildren && refchildre.children);
                this.$set(this, "title", refrenChildren.title);
                this.$set(this, "subtitle", '');
              });
              this.$forceUpdate();
            } else {
              const routeArr = newVal.menuLists[menuType].map((item) => ({
                permission_name: item.permission_name,
                route: item.route,
                children: item.children,
                group: item.group,
              }));
              const refrenChildren = this.changeMenuList2Map(routeArr).find(
                (item) => item.route == subString
              );
              let refchildre = refrenChildren
                ? this.handleRouteActive(
                    JSON.parse(JSON.stringify(refrenChildren))
                  )
                : "";
              this.$nextTick(() => {
                this.$set(this, "route", refrenChildren && refchildre.children);
                this.$set(
                  this,
                  "title",
                  refrenChildren && refrenChildren.title
                );
              });
              this.$forceUpdate();
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
    company_data: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$set(this, "title", newVal.name);
            this.$set(this, "subtitle", '');
          });
          this.$forceUpdate();
        }
      },
      deep: true,
      immediate: true,
    },
    syndicateData:{
      handler(newVal){
        if(newVal){
          this.$nextTick(() => {
            this.$set(this, "title", newVal.company.name);
            this.$set(this, "subtitle", newVal.name);
          });
          this.$forceUpdate();
        }
      },
      deep:true,
      immediate:true,
    },
  },
  methods: {
    // 截取第一级路由
    subStringRoute(routePath) {
      if (routePath.lastIndexOf("/") != routePath.indexOf("/")) {
        let subString = routePath.substring(0, routePath.lastIndexOf("/"));
        if (subString.indexOf("/") != subString.lastIndexOf("/")) {
          //判断多级
          subString = this.subStringRoute(subString);
        }
        return subString;
      } else {
        return "/";
      }
    },
    formatdd(data, arr, type) {
      for (let i in arr) {
        data[arr[i]["route"]] = type;
        if (arr[i]["children"].length > 0) {
          let tmp = JSON.parse(JSON.stringify(type));
          tmp.push(arr[i]["route"]);
          data = this.formatdd(data, arr[i]["children"], tmp);
        }
      }
      return data;
    },
    // 获取菜单列表
    getMenuList() {
      this.$get(this.$apis.MenuList).then((res) => {
        if (res.code == 200) {
          this.routeMap = res.data["route_mapping"];
          // this.$set(this, 'navList', res.data.base);
          // this.$store.commit('GET_MENU_LIST', res.data.base);
          let data = {};
          for (let i in res.data) {
            if (i != "route_mapping") {
              data = this.formatdd(data, res.data[i], [i]);
            }
          }
          this.menuMap = data;
          this.$store.commit("GET_MENU_LIST", res.data);
        } else {
          Message.error(`获取菜单列表失败!-[${res.message}]`);
        }
      });
    },
    // 改变menuList格式 --->  [{route:children}]
    changeMenuList2Map(list) {
      if (Array.isArray(list)) {
        const arr = list.reduce((pre, ite) => {
          const obj = {};
          obj["route"] = ite["route"];
          obj["children"] = ite["children"];
          obj["title"] = ite["permission_name"];
          pre.push(obj);
          // 判断是否存在children
          if (ite.hasOwnProperty("children")) {
            // 转换格式
            pre = [...pre, ...this.changeMenuList2Map(ite.children)];
            return pre;
          }
          return pre;
        }, []);
        return arr;
      } else {
      }
    },
    handleRouteLink(title, route) {
      // 重定向到route的第一个
      this.$set(this, "title", title);
      this.$set(this, "route", route);
    },
    handleRouteActive(route) {
      let path = this.$route.path,
        pathName = this.$route.name,
        map = this.routeMap,
        fullpath = this.$route.fullPath;
      if (map && map[pathName]) {
        path = map[pathName];
      }
      if (route.children.length) {
        route.children.forEach((item) => {
          if (item.route == path) {
            route.secondClickRoute = true;
          } else if (item.route == fullpath) {
            route.secondClickRoute = true;
          }
          if (item.children.length) {
            this.handleRouteActive(item);
          }
        });
      }
      return route;
    },
    // 获取企业信息
    getCompany(callback) {
      let that = this;
      let data = {
        fields: ["name"],
      };
      this.$get(this.$apis.companyDetail + this.$route.query.company_id, data)
        .then((res) => {
          if (res.code == 200) {
            that.company_data = res.data;
            // console.log(that.company_data)
            if (callback) {
              callback(res);
            }
            return res.data;
          }
        })
        .catch((err) => {});
    },
  },
  computed: {
    // ...mapGetters(['menuLists']),
    // ...mapGetters(['menuType']),
    watchData() {
      const { menuLists, menuType } = this.$store.getters;
      const { fullPath } = this.$route;
      return { menuLists, menuType, fullPath };
    },
  },
};
</script>

<style scoped lang="less">
.layout {
  width: 100%;
  display: flex;

  & .sidebar {
    z-index: 99;
  }

  .main-container {
    width: calc(100% - 106px);
  }
}
</style>