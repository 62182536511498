import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index/index.vue'
import Home1 from '../views/index/index1.vue'
import Signin from '../views/logon/register.vue'
// import { component } from 'vue/types/umd'
import Layout from '@/components/Layout'

Vue.use(VueRouter)

const routes = [
	// 登录
	{
		path: '/signin',
		name: 'signin',
		meta: {
			requireAuth: true
		}, // 判断是否需要登录
		component: Signin //登录

	},
	// 首页
	{
		path: '/',
		component: Layout,
		children: [{
			path: '',
			// component: Home
			component: Home1
		}]
	},
	//装修
	{
		path: "/pc/fitment",
		name: "fitment",
		component: () =>
			import("@/views/fitment/index"),	//装修
	},
	// 商品
	{
		path: '/goods',
		component: Layout,
		redirect: '/goods/index',
		children: [{
			path: "index",
			meta: {
				secondIndex: 0,
				keepAlive: true,
			},
			component: () => import("@/views/good/goods_home"), //商品管理
		},
		{
			path: 'goods_add/:id',
			name: 'goods_add',
			meta: {
				secondIndex: 0,
			},
			component: () =>
				import("@/views/good/goods_add"), //创建商品
		},
		{
			path: 'goods_addspu/:id',
			name: 'goods_addspu',
			meta: {
				secondIndex: 1,
			},
			component: () =>
				import("@/views/good/goods_addspu"), //新增spu
		},
		{
			path: 'goods_bindspu/:id',
			name: 'goods_bindspu',
			meta: {
				secondIndex: 0,
			},
			component: () =>
				import("@/views/good/goods_bindspu"), //绑定spu
		},
		{
			path: 'goods_spu',
			meta: {
				secondIndex: 1,
			},
			component: () =>
				import("@/views/good/goods_spu"), //spu列表
		},
		{
			path: 'goods_lookspu',
			name: "goods_lookspu",
			meta: {
				secondIndex: 1,
			},
			component: () =>
				import("@/views/good/goods_lookspu"), //查看spu关联商品列表
		},
		{
			path: 'goods_grouping',
			meta: {
				secondIndex: 2,
			},
			component: () =>
				import("@/views/good/good_grouping"), //商品分组
		},
		{
			path: 'goods_manage',
			meta: {
				secondIndex: 3,
			},
			component: () =>
				import("@/views/good/goods_manage"), //分类管理
		},
		{
			path: 'class_edit/:id',
			name: "class_edit",
			meta: {
				secondIndex: 3,
			},
			component: () =>
				import("@/views/good/class_edit"), //分类管理添加/编辑
		},
		// {
		//   path:'goods_specifi',
		//   meta:{
		//     secondIndex: 2,
		//   },
		//   component:()=>
		//       import("@/views/good/goods_specifi")
		// },
		{
			path: 'goods_support',
			meta: {
				secondIndex: 4,
			},
			component: () =>
				import("@/views/good/goods_support"), //标签管理
		},
		{
			path: 'goods_arguments',
			meta: {
				secondIndex: 5,
			},
			component: () =>
				import("@/views/good/goods_arguments"), //商品参数
		},
		{
			path: 'argument_edit/:id',
			name: 'argument_edit',
			meta: {
				secondIndex: 5,
			},
			component: () =>
				import("@/views/good/argument_edit"), //商品参数创建/编辑
		},
		{
			path: 'goods_brand',
			meta: {
				secondIndex: 6,
			},
			component: () =>
				import("@/views/good/goods_brand"), //品牌管理
		},
		{
			path: 'brand_edit',
			name: "brand_edited",
			meta: {
				secondIndex: 6,
			},
			component: () =>
				import("@/views/good/brand_edit"), //品牌管理编辑/创建
		},
		{
			path: 'recycle',
			meta: {
				secondIndex: 7,
			},
			component: () =>
				import("@/views/good/recycle"), //回收站
		},
		]
	},
	// 订单
	{
		path: '/order',
		redirect: "/order/order_manage",
		component: Layout, //商品订单
		children: [{
			path: "index",
			meta: {
				secondIndex: 0,
			},
			component: () => import("@/views/order/order_home"), //商品订单首页
		}, {
			path: 'order_manage',
			name: 'order_manage',
			meta: {
				secondIndex: 1,
				keepAlive: true,
			},
			component: () => import('@/views/order/order_manage'), //商品订单列表
		}, {
			path: 'order_details',
			name: 'order_details',
			meta: {
				secondIndex: 1,
			},
			component: () => import('@/views/order/order_details'), //商品订单详情
		}, {
			path: 'order_service',
			name: 'order_service',
			meta: {
				secondIndex: 2,
			},
			component: () => import('@/views/order/order_service'), //商品售后订单
		}, {
			path: 'order_servicedetails',
			name: 'order_servicedetails',
			meta: {
				secondIndex: 2,
			},
			component: () => import('@/views/order/order_servicedetails'), //商品售后订单详情
		}, {
			path: 'order_batch',
			name: 'order_batch',
			meta: {
				secondIndex: 3,
			},
			component: () => import('@/views/order/order_batch'), //商品订单批量发货
		},
		{
			path: 'order_comment_list',
			name: 'order_comment_list',
			meta: {
				secondIndex: 4,
			},
			component: () => import('@/views/order/order_commentlist'), //商品订单评价列表
		},
		{
			path: 'order_commentdetail',
			name: 'order_commentdetail',
			meta: {
				secondIndex: 4,
			},
			component: () => import('@/views/order/order_commentdetail'), //商品订单评价详情
		},
		{
			path: 'order_setcomment',
			name: 'order_setcomment',
			meta: {
				secondIndex: 4,
			},
			component: () => import('@/views/order/order_setcomment'), //商品订单评价设置
		},
		{
			path: 'order_setservice',
			name: 'order_setservice',
			meta: {
				secondIndex: 5,
			},
			component: () => import('@/views/order/order_setservice'), //售后设置
		},
		{
			path: 'order_invoice',
			name: 'order_invoice',
			meta: {
				secondIndex: 6,
			},
			component: () => import('@/views/order/order_invoice'), //发票中心
		},
		{
			path: 'order_invoicedetail',
			name: 'order_invoicedetail',
			meta: {
				secondIndex: 6,
			},
			component: () => import('@/views/order/order_invoicedetail'), //发票详情
		},
		{
			path: 'order_recycle',
			name: "orderRecycle",
			component: () => import('@/views/order/order_recycle'), //订单回收站
		},
		{
			path: "orderRecycle_detail",
			name: "orderRecycleDetail",
			component: () => import('@/views/order/order_recycle_detail'),	//订单回收站的详情
		}
		]
	},
	// 设置
	{
		path: '/setup',
		component: Layout, //设置
		redirect: "/setup/platform_setting",
		children: [
			// 平台设置
			{
				path: "platform_setting",
				component: () => import('@/views/setup/platformSetting.vue')
			},
			// 通用设置
			{
				path: "general",
				component: () => import('@/views/setup/general.vue')
			},
			// 短信通知
			{
				path: "notifyinde",
				component: () => import("@/views/setup/notify"),
				children: [{
					path: "notifyinform",
					name: "notifyindeNotifyinform",
					meta: {
						secondIndex: 1,
					},
					component: () => import("@/views/setup/notify/inform")
				},]
			},
			// 消息通知
			{
				path: "notifyhome",
				meta: {
					secondIndex: 0,
				},
				component: () => import("@/views/setup/notify/home"),
			},
			//  敏感词库
			{
				path: "sensitive_words",
				meta: {
					secondIndex: 0,
				},
				component: () => import("@/views/setup/sensitive_words"),
			},
			// 邮件通知
			{
				path: "emilindex",
				// redirect: "/setup/emilindex/milbt",
				component: () => import("@/views/setup/notify/emilindex"),
				children: [{
					path: "milbt",
					name: "emilindexMilbt",
					component: () => import("@/views/setup/notify/milbt")
				},]
			},
			// 三方配置
			{
				path: "setup_home",
				component: () =>
					import("@/views/setup/setup_home"),
			},
			// 三方配置 - 短信接口配置
			{
				path: "/setup/textmessage",
				redirect: '/setup/textmessage/alibaba',
				component: () =>
					import("@/views/setup/textmessage"),
				children: [{
					path: "/setup/textmessage/alibaba",
					name: "setupTextmessage",
					component: () => import("@/views/setup/alibaba")
				},]
			},
			// 三方配置 - 对象存储配置
			{
				path: "/setup/obctstore",
				redirect: "/setup/obctstore/boalibaba",
				meta: {
					secondIndex: 1,
				},
				component: () =>
					import("@/views/setup/obctstore"),
				children: [{
					path: "/setup/obctstore/boalibaba",
					name: "obctstoreBoalibaba",
					meta: {
						secondIndex: 2,
					},
					component: () => import("@/views/setup/obctstore/boalibaba")
				},]

			},
			// 三方配置 - 邮件通知配置
			{
				path: "/setup/mail",
				// redirect: "/setup/circulation/hundred",
				name: "setupMail",
				meta: {
					secondIndex: 1,
				},
				component: () =>
					import("@/views/setup/mail"),
				children: []
			},
			// 三方配置 - 物流通知配置
			{
				path: "/setup/circulation",
				redirect: "/setup/circulation/hundred",
				meta: {
					secondIndex: 1,
				},
				component: () =>
					import("@/views/setup/circulation"),
				children: [{
					path: "/setup/circulation/hundred",
					name: "circulationHundred",
					meta: {
						secondIndex: 2,
					},
					component: () => import("@/views/setup/circulation/hundred")
				},]

			},
			// 角色列表
			{
				path: "management",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/management"),
			},
			// 添加角色
			{
				path: "management/role",
				name: "managementrole",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/management/role"),
			},
			// 员工列表
			{
				path: "management/staff",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/management/staff"),
			},
			// 添加员工
			{
				path: "management/staffadd",
				name: "staffaddManage",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/management/staffadd"),
				props: route => ({ id: route.query.id, type: route.query.type })
			},
			// 编辑员工
			{
				path: "management/eide",
				name: "eide",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/management/eide"),
			},
			// 操作日志
			{
				path: "management/operation_log",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/management/operationLog"),
			},
			// 支付设置
			{
				path: "payment",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/setup/payment"),
			},
			// 互联网
			{
				path: "/payment/network",
				name: "payNetwork",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/setup/payment/network"),
			},
			// 支付设置-支付宝
			{
				path: "payment/alipay",
				name: "payAlipay",
				component: () => import("@/views/setup/payment/alipay"),
			},
			// 支付设置-线下支付
			{
				path: "payment/offline",
				name: "payOffline",
				component: () => import("@/views/setup/payment/offline"),
			},
			// 支付设置-银联企业网银支付
			{
				path: "payment/enterprise",
				name: "payEnterprise",
				component: () => import("@/views/setup/payment/enterprise"),
			},
			// 支付设置-微信支付
			{
				path: "payment/wechat",
				name: "payWechat",
				component: () => import("@/views/setup/payment/wechat"),
			},

			// 配送规则 - 运费规则
			{
				path: "ordination",
				component: () => import("@/views/setup/ordination"),
			},
			// 配送规则 - 运费规则 - 添加运费模版
			{
				path: "yfmbrout",
				name: "yfmbrout",
				component: () => import("@/views/setup/ordination/yfmbrout"),
			},

			// 配送规则 - 包邮规则
			{
				path: "orparcel",
				name: "orparcel",
				component: () => import("@/views/setup/ordination/parcel"),
			},
			// 配送规则 - 包邮规则详情
			{
				path: "yfparcel",
				name: "yfparcel",
				component: () => import("@/views/setup/ordination/yfparcel"),
			},
			// 配送规则 - 退货
			{
				path: "return_goods",
				component: () => import("@/views/setup/ordination/returnGoods"),
			},
			// 升级优化 -> 清除缓存
			{
				path: "up_optimization/clear_cache",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/setup/upOptimization/clearCache"),
			},
			// 升级优化 -> 系统更新
			{
				path: "up_optimization/system_updates",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/setup/upOptimization/systemUpdates"),
			}
		]
	},



	// 渠道
	{
		path: "/channel",
		component: Layout,
		redirect: '/channel/index',		//渠道管理
		children: [
			// 渠道首页
			{
				path: "index",
				name: "ditchIndex",
				meta: {
					secondIndex: 0,
				},
				component: () =>
					import("@/views/ditch/home")
			},
			{
				path: "finance/recharge_record",
				component: () => import("@/views/finance/finance_recharge_record"), //充值记录
			},

			{
				path: "finance/balance_statement",
				name: "balanceStatement",
				meta: {
					secondIndex: 0,
				},
				component: () => import("@/views/finance/finance_balance_statement"), //余额流水
			},
			{
				path: "finance/balance_withdrawal",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/finance/finance_balance_withdrawal"), //余额提现
			},
			{
				path: "finance/financial_log",
				meta: {
					secondIndex: 2,
				},
				component: () => import("@/views/finance/finance_financial_log"), //财务日志
			},
			{
				path: "content/article_manage",
				meta: {
					secondIndex: 0,
				},
				component: () => import("@/views/content/content_article_manage"), //文章管理
			},
			{
				path: "content/article_class",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/content/content_article_class"), //文章分类
			},
			{
				path: "content/article_edit",
				name: "articleEdit",
				meta: {
					secondIndex: 0,
				},
				component: () => import("@/views/content/content_article_edit"), //文章添加/编辑
			},
			{
				path: "content/article_class_oper",
				name: "articleClassOper",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/content/content_article_class_edit"), //文章分类/添加、编辑
			},
			{
				path: "member/member_manage",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/member/member_manage"), //会员管理
			},
			{
				path: "member/member_level",
				meta: {
					secondIndex: 2,
				},
				component: () => import("@/views/member/member_level"), //会员等级
			},
			{
				path: "member/member_level_detail",
				name: "memberLevelDetail",
				meta: {
					secondIndex: 2,
				},
				component: () => import("@/views/member/member_level_detail")
			}
		],
	},
	// 装修
	{
		path: '/client',
		component: Layout,
		redirect: '/client/pc/setting',
		children: [
			{
				path: "pc/setting",
				name: "channelIndex",
				meta: {
					secondIndex: 0,
				},
				component: () =>
					import("@/views/channel/home"),
				props: route => ({ type: route.query.type })
			},
			{
				path: "pc/diy",
				name: "channelFitment",
				meta: {
					secondIndex: 1,
				},
				component: () =>
					import("@/views/channel/fitment"),	//首页装修
			},
			{
				path: "pc/customer",
				name: "channelService",
				meta: {
					secondIndex: 2,
				},
				component: () =>
					import("@/views/channel/service"),	//客服设置
			},
			{
				path: "pc/top",
				name: "channelTop",
				meta: {
					secondIndex: 3,
				},
				component: () =>
					import("@/views/channel/topRoof"),	//顶部设置
			},
			{
				path: "pc/bottom",
				name: "bottomBase",
				meta: {
					secondIndex: 4,
				},
				component: () =>
					import("@/views/channel/bottomBase"),	//底部设置
			},
			{
				path: 'mobile/finish',
				component: () => import("@/views/mobile/finish"),	//手机端的装修列表
			},
			{
				path: "mobile/fitment",
				name: "mobileFitment",
				component: () => import("@/views/mobile/fitment/index"),	//手机端的装修界面
			}
		],
	},
	// PC渠道
	// {
	// 	path: "/pc",
	// 	component: Layout,
	// 	redirect: '/pc/setting',		//渠道管理
	// 	children: [
	// 		// 渠道管理

	// 	],
	// },

	// 反馈
	{
		path: '/feedback',
		redirect: '/feedback/index',
		component: Layout,
		children: [
			{
				path: "index",
				meta: {
					secondIndex: 0,
				},
				component: () => import("@/views/feedback/index"), //文章管理
			},
			{
				path: "message",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/feedback/message"), //文章管理
			}
		]
	},
	{
		path: "/code",		//企业
		component: Layout,
		redirect: "/code/index",
		children: [
			{
				path: "index/:name",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/code/index"),
			},

			{
				path: "goods_manage",
				component: () => import("@/views/good/goods_manage")
			},
			{
				path: "fitment",
				component: () => import("@/views/channel/decoration")
			},
			{
				path: "fitment/app_finish",
				component: () => import("@/views/mobile/fitment/index")
			},
			{
				path: "goods_edit/:id",
				component: () => import("@/views/good/goods_add")
			},
			{
				path: "batch/create",
				component: () => import("@/views/member/member_staff")
			},
			{
				path: 'staff_detail', // 员工兑换详情
				meta: {
					secondIndex: 2,
				},
				component: () => import("@/views/information/staff_detail"),
			}
		]
	},
	{
		path: '/activity',		//活动
		component: Layout,
		redirect: '/activity/index',
		children: [
			{
				path: 'index/:name',
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/code/index")
			},
			{
				path: "goods_manage",
				component: () => import("@/views/good/goods_manage")
			},
			{
				path: "fitment",
				component: () => import("@/views/channel/decoration")
			},
			{
				path: 'staff_detail', // 员工兑换详情
				meta: {
					secondIndex: 2,
				},
				component: () => import("@/views/information/staff_detail"),
			}
		],
	},
	{
		path: '/integral',		//资金明细
		component: Layout,
		redirect: '/integral/index',
		children: [
			{
				path: 'index/:name',
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/code/index")
			}
		],
	},
	{
		path: "/code/demo",
		component: Layout,
		redirect: "/demo/index",
		children: [
			{
				path: "index",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/demo/index"),
			}
		]
	},
	{
		path: "/demo",
		component: Layout,
		redirect: "/demo/index",
		children: [
			{
				path: "index",
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/demo/index"),
			}
		]
	},
	// {
	// 	path: '/mobile',
	// 	component: Layout,
	// 	redirect: '/mobile/setting',
	// 	children: [
	// 		{
	// 			path: 'setting',
	// 			component: () => import("@/views/mobile/home"),	//手机端的设置
	// 		},
	// 		{
	// 			path: 'finish',
	// 			component: () => import("@/views/mobile/finish"),	//手机端的装修列表
	// 		},
	// 		{
	// 			path: "fitment",
	// 			component: () => import("@/views/mobile/fitment/index"),	//手机端的装修界面
	// 		}
	// 	],
	// }
	{
		path: "/staff",
		component: Layout,
		redirect: "/staff/index",
		children: [
			{
				path: 'index',
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/demo/user"),	
			},
			{
				path: 'index/:name',
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/code/index"),
			},
			{
				path: "batch",	//批量生成员工
				component: () => import("@/views/member/member_staff")
			},
			{
				path: 'staff_detail', // 员工兑换详情
				meta: {
					secondIndex: 2,
				},
				component: () => import("@/views/information/staff_detail"),
			}
		],
	},

	// 数据统计
	{
		path: "/information",
		component: Layout,
		redirect: "/information/index",
		children: [
			{
				path: 'index',
				meta: {
					secondIndex: 0,
				},
				component: () => import("@/views/information/index"),
			},
			{
				path: 'list', // 平台兑换详情
				meta: {
					secondIndex: 1,
				},
				component: () => import("@/views/information/list"),
			},
			{
				path: 'staff_detail', // 员工兑换详情
				meta: {
					secondIndex: 2,
				},
				component: () => import("@/views/information/staff_detail"),
			}
		],
	},

]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	// mode: "history",
	mode: "hash",
	routes
})

// router.beforeEach((to, from, next) => {
// 	var tokenStr = window.localStorage.getItem('userInfo');
// 	if (to.path === '/signin') {
// 		next();
// 	}
// 	if (!tokenStr) {
// 		next('/signin')
// 	} else {
// 		next()
// 	}
// })
export default router
