<template>
  <div id="classifyCom">
    <el-dialog
      title="选择分类"
      class="xzclassifytc"
      :visible.sync="xzclassifytype"
    >
      <div class="xzclassifytcbox">
        <div class="newaddseach">
          <i class="el-icon-search newaddicon"></i>
          <input
            type="text"
            placeholder="搜索您想要查找的分类"
            class="cat_search"
          />
          <div class="newseachlist">
            <ul>
              <li class="clearfloat">
                <p><b>服装</b>鞋包</p>
                <span>></span>
                <p><b>服装</b>电器</p>
              </li>
              <li class="clearfloat">
                <p><b>服装</b>鞋包</p>
                <span>></span>
                <p><b>服装</b>电器</p>
                <span>></span>
                <p><b>服装</b>电器</p>
                <span>></span>
                <p><b>服装</b>电器</p>
              </li>
              <li class="clearfloat">
                <p><b>服装</b>鞋包</p>
                <span>></span>
                <p><b>服装</b>电器</p>
                <span>></span>
                <p><b>服装</b>电器</p>
              </li>
              <li class="clearfloat">
                <p><b>服装</b>鞋包</p>
                <span>></span>
                <p><b>服装</b>电器</p>
              </li>
              <li class="clearfloat">
                <p><b>服装</b>鞋包</p>
                <span>></span>
                <p><b>服装</b>电器</p>
                <span>></span>
                <p><b>服装</b>电器</p>
                <span>></span>
                <p><b>服装</b>电器</p>
              </li>
              <li class="clearfloat">
                <p><b>服装</b>鞋包</p>
                <span>></span>
                <p><b>服装</b>电器</p>
                <span>></span>
                <p><b>服装</b>电器</p>
              </li>
              <li class="clearfloat">
                <p><b>服装</b>鞋包</p>
                <span>></span>
                <p><b>服装</b>电器</p>
              </li>
              <li class="clearfloat">
                <p><b>服装</b>鞋包</p>
                <span>></span>
                <p><b>服装</b>电器</p>
                <span>></span>
                <p><b>服装</b>电器</p>
                <span>></span>
                <p><b>服装</b>电器</p>
              </li>
              <li class="clearfloat">
                <p><b>服装</b>鞋包</p>
                <span>></span>
                <p><b>服装</b>电器</p>
                <span>></span>
                <p><b>服装</b>电器</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="newaddconter clearfloat">
          <div class="cat-list">
            <label class="cat-item active">
              家用电器
              <input value="111" type="radio" />
              <i class="el-icon-arrow-right"></i>
            </label>
            <label class="cat-item" v-for="(item, index) in 10" :key="index">
              家用电器
              <input value="111" type="radio" />
              <i class="el-icon-arrow-right"></i>
            </label>
          </div>
          <div class="cat-list">
            <label class="cat-item" v-for="(item, index) in 10" :key="index">
              电视
              <input value="2" type="radio" />
              <i class="el-icon-arrow-right"></i>
            </label>
          </div>
          <div class="cat-list">
            <label class="cat-item" v-for="(item, index) in 10" :key="index">
              超薄电视
              <input value="3" type="radio" />
            </label>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="xzclassifytype = false">取 消</el-button>
        <el-button @click="xzclassifytype = false" class="addressqr"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "classifyCom",
  data() {
    return {
        xzclassifytype:false,
    };
  },
};
</script>
<style scoped>
</style>