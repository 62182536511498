<template>
    <div class="component-form-input">
        <el-checkbox-group v-model="$formData[code_data.key]">
            <el-checkbox :label="item.key" v-for="(item, index) in code_data.data_source.source_data" :key="index">{{
                item.value }}</el-checkbox>
        </el-checkbox-group>
    </div>
</template>

<script>
export default {
    name: 'component.form.checkbox',
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped></style>