<template>
    <div class="component-form-input">
        <el-input v-model="$formData[code_data.key]" type="number" :placeholder="code_data.parameters.placeholder ?? ''">
            <template v-if="code_data.parameters?.append" slot="append">{{ code_data.parameters?.append }}</template>
        </el-input>
    </div>
</template>

<script>
export default {
    name: 'component.form.number',
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped></style>