<template>
    <section class="app-main"
        :class="{ 'route-class': $route.fullPath == '/', 'information-class': $route.fullPath == '/information/index' }">
        <!-- <keep-alive :include="cachedViews">
            <router-view :key="key" />
        </keep-alive> -->
        <keep-alive :max="5">
            <router-view v-if="$route.meta?.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta?.keepAlive" />
    </section>
</template>
<script>
export default {
    name: 'AppMain',
    computed: {
        // cachedViews() {
        //     return this.$store.state.tagsView.cachedViews
        // },
        // key() {
        //     return this.$route.path
        // }
    }
}
</script>

<style>
::-webkit-scrollbar-thumb {
    background-color: #535353;
}
</style>
<style lang="less" scoped>
.app-main {
    /* 50= head  50  */
    width: calc(100% - 134px);
    // max-height: 100vh;
    // overflow-x: hidden;
    padding: 70px 20px 20px 20px;
    box-sizing: border-box;
    // margin-left: 134px;
    margin-left: 240px;

    & /deep/.boxright {
        width: 100%;
        padding: 0;
        position: static;
        margin-left: 0;
    }
}

.route-class {
    width: 100%;
    // margin-left: 0;
    margin-left: 106px;
}

.information-class {
    min-width: 1400px;
    overflow: auto;


}
</style>
