<template>
    <el-dialog title="改分组" class="grounptc" :visible.sync="change_class">
        <div class="grounptcbox">
            <div class="grounptcboxup clearfloat">
                <el-input placeholder="搜索分组名称" v-model="grouptext"></el-input>
                <el-button type="primary" @click="getCLassData()">搜索</el-button>
            </div>
            <div class="grounpbutlist">
                <el-checkbox-group v-model="checkList">
                    <el-checkbox v-for="(item, index) in grouplist" :key="index" :label="item.id">{{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cleargroup()">取 消</el-button>
            <el-button type="primary" @click="sealgroup()">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "component.table.change_class",
    props: ['propsList'],
    data() {
        return {
            change_class: false,
            grouptext: "",
            grouplist: [],
            checkList: []
        }
    },
    created() {
        // 获取分组数据
        this.getCLassData()
    },
    methods: {
        getCLassData() {
            let _data = {
                search: [],
                page: 1,
                limit: 999,
            };
            if (this.grouptext) {
                _data.search.push({
                    key: "name",
                    value: this.grouptext,
                    op: "search",
                })
            }
            this.$get(this.$apis.groupList, _data).then((res) => {
                if (res.code == 200) {
                    this.grouplist = res.data.list;
                } else {
                    this.common.message(this, res.message);
                }
            }).catch((err) => {
                this.common.message(this, err.message);
            });

        },
        cleargroup() {
            this.grouptext = ""
            this.checkList = []
            this.change_class = false
        },
        sealgroup() {
            //console.log(this.checkList, 'this.grouplistthis.grouplist');
            if (!this.checkList.length) {
                this.$message.warning("请选择分组")
                return false
            }

            let _data = {
                id: this.propsList.map(item => item.id),
                group_id: this.checkList,
            };
            this.$confirm("您确定修改商品分组?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$put(this.$apis.allGroup, _data)
                    .then((res) => {
                        if (res.code == 200) {
                            this.$message.success(res.message)
                            this.change_class = false
                            this.$emit('callback', true)
                        } else {
                            this.common.message(this, res.message);
                        }
                    })
                    .catch((err) => {
                        this.common.message(this, err.message);
                    });
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.grounptc /deep/ .el-dialog {
    width: 660px;
    border-radius: 2px;
}

.grounptcbox {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.grounptcboxup {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ededed;
    box-sizing: border-box;
}

.grounptcboxup /deep/ .el-input {
    float: left;
    width: 320px;
}
</style>