<template>
    <div>
        <Editor v-model="$formData[code_data.key]" :placeholder="code_data.parameters.placeholder"
            :height="code_data.parameters?.editor_height || 500" @change="editor_detail" @uploadImgs="uploadImgs" />
        <!-- 图片选择器 -->
        <pictureSelect ref="imglist" multipled="1" @change="imgbrand"></pictureSelect>
    </div>
</template>

<script>
export default {
    name: 'component.form.editor',
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {

        }
    },
    methods: {
        editor_detail(val) {
            this.$formData[this.code_data.key] = val;
        },
        uploadImgs() {
            this.$refs.imglist.photoVisible = true;
        },
        imgbrand(val) {
            for (let i = 0; i < val.length; i++) {
                this.$formData[this.code_data.key] += `<p><img src="${val[i].file_url}" style="max-width:100%;"/></p>`;
            }
        }
    }
}
</script>

<style lang="scss" scoped></style>