<template>
    <div v-if="data_value">
        <div :style="{
            backgroundColor: data_value?.color.split(',')[0],
            color: data_value?.color.split(',')[1]
        }" style="
                width:auto;
                float:left;
                box-sizing:border-box;
                padding: 6px 14px
            ">
            {{ data_value?.value ?? scope.row.source }}
        </div>
    </div>
</template>
<script>
export default {
    name: "component.table.mark",
    props: {
        code_data: {
            type: Object,
            required: true
        },
        scope: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            component_id: this.code.domId(this.code_data),
            data_value: ""
        };
    },
    created() {

    },
    watch: {
        scope: {
            handler(newVal, oldVal) {
                if (newVal) {
                    // 初始化
                    this.init();
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        init() {
            if (this.code_data?.data_source) {
                this.code_data.data_source['successCallback'] = function(domThis, res) {
                    const { source } = domThis.scope.row;
                    domThis.data_value = res[source];
                }
                this.code_data.data_source['failCallback'] = function(domThis, err) {
                    console.error('component.form.cascader', err);
                }
                this.code.formatDataSource(this, this.code_data.data_source);
            }
        }
    },
}
</script>