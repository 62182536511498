<template>
  <el-card ref="code_table">
    <!-- 需要挂载的外部弹窗组件 -->
    <div v-for="(item, index) in code_data.other_dialog" :key="index">
      <!-- 调价 -->
      <changePrice ref="change_pice" :picelist="multipleSelection" @showCityName="priceCallback"
        v-if="item?.type == 'tiaojia'"></changePrice>
      <!-- 改分组 -->
      <component.table.change_class ref="change_class" :propsList="multipleSelection" @callback="classCallback"
        v-if="item?.type == 'gaifenzu'"></component.table.change_class>
      <!-- 改品牌 -->
      <component.table.change_brand ref="change_brand" :propsList="multipleSelection" v-if="item?.type == 'gaipinpai'"
        @onload="componentChangeOnload"></component.table.change_brand>
      <!-- 转移分组 -->
      <component.table.transfer_class ref="transfer_class" :propsList="multipleSelection" @callback="transferCallback"
        v-if="item?.type == 'zuanyifenlei'" :allCat="item.allCat" :setCat="item.setCat">
      </component.table.transfer_class>
      <!-- 日志 -->
      <component.table.log ref="table_log" v-if="item?.type == 'log'"></component.table.log>
      <!-- 导入 -->
      <diy-import-more ref="diyImportMore" :dialogTitle="item.dialog_title" v-if="item.type == 'import'"
        :dialogImportUrl="item.dialog_import_url" :dialogTemplateUrl="item.dialog_template_url"
        @dialogImportCallback="importCallback"></diy-import-more>
      <!-- 导出 -->
      <diy-export-more ref="diyExportMore" :dialogTitle="item.dialog_title" v-if="item?.type == 'export'"
        :dialogExportKeys="item.dialog_export_keys" :dialogExportUrl="item.dialog_export_url"
        :dialogExportIsSearch="item.dialogExportIsSearch"></diy-export-more>
      <!-- 修改积分 -->
      <integralEdit v-if="item?.type == 'editIntegral'" ref="editIntegral" :requestMethods="item.dialog_requestMethos"
        :requestUrl="item.dialog_import_url" @change="intergrlChange"></integralEdit>
      <!-- table 分享 -->
      <component.table.share ref="table_share" v-if="item?.type == 'table_share'"></component.table.share>
      <!-- table 复制活动 -->
      <component.table.copy_activity ref="copy_activity" v-if="item?.type == 'copy_activity'"
        @onload="componentChangeOnload">
      </component.table.copy_activity>
      <!-- 修改库存 -->
      <component.table.inventory v-if="item?.type == 'repertory'" ref="repertory" @change="repertoryChange">
      </component.table.inventory>
      <!-- 修改部门 -->
      <component.table.change_department v-if="item?.type == 'change_department'" ref="change_department"
        :propsList="multipleSelection" @onload="componentChangeOnload"></component.table.change_department>
    </div>
    <!-- 需要挂载的自定义弹窗组件 -->
    <component v-for="(item, i) in code_data.dialog_dom" :is="item.type" :code_data="item" :$formData="childAndFather"
      :scope="active_scope" :ref="item.ref">
    </component>
    <!-- 表头tabs -->
    <el-tabs v-model="code_data.tabOpt.active_name" @tab-click="handleClick" v-if="tabDataList.length">
      <!-- todo  全部单写，且搜索数据整合 -->
      <el-tab-pane label="全部"></el-tab-pane>
      <el-tab-pane v-for="(item, index) in tabDataList" :key="index" :label="item.value"></el-tab-pane>
    </el-tabs>
    <!-- 表头按钮 -->
    <div style="
        margin-left: 18px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      " class="table-buttons">
      <el-checkbox style="margin-right: 10px" @change="handleSelectAll" v-if="code_data.tabOpt.select_all_tips"
        v-model="all_table_checked">当前页全选</el-checkbox>
      <template v-if="code_data.action_buttons.length > 0 && code_data.action_buttons_top
        ">
        <el-button v-for="(item, index) in code_data.action_buttons.slice(
            0,
            code_data.action_buttons_set
          )" :key="index" @click="handleClickBtn(item)" :type="item.type" :size="item.size">{{ item.label
  }}</el-button>
        <el-select v-model="changeValue" placeholder="请选择" style="margin-left: 8px" size="small"
          @change="handle_table_select" v-if="code_data.action_buttons.length > code_data.action_buttons_set">
          <el-option v-for="item in code_data.action_buttons.slice(
            code_data.action_buttons_set
          )" :key="item.key" :label="item.label" :value="item.key"></el-option>
        </el-select>
      </template>
    </div>
    <!-- 表格 -->
    <el-table ref="table" :data="tableData" style="width: 100%" @selection-change="singletable" @sort-change="table_sort">
      <!-- 表头多选按钮 -->
      <el-table-column type="selection" v-if="code_data.tabOpt.select_all"
        :width="code_data.tabOpt.select_all_width"></el-table-column>
      <!-- 表列 -->
      <el-table-column v-for="(item, index) in code_data.components" :prop="item.key" :label="item.label"
        :min-width="item.parameters.min_width" :align="item.parameters.align" :key="index" :sortable="item?.sortable">
        <template slot-scope="scope">
          <component :is="item.type" :code_data="item" :scope="scope.row[item.key] && item?.is_filter
            ? scope.row[item.key][item.filter_key]
            : scope.row[item.key]
            " v-if="item.type === 'component.table.text'"></component>
          <component :is="item.type" :code_data="item" :scope="scope" v-else @handleSwitch="handleSwitch">
          </component>
        </template>
      </el-table-column>
      <!-- 操作按钮，冻结在右侧 -->
      <el-table-column v-if="code_data?.data_operate" :prop="code_data.data_operate.key"
        :label="code_data.data_operate.label" :width="code_data.data_operate.parameters.width"
        :type="code_data.data_operate.type" fixed="right" align="center">
        <template slot-scope="scope">
          <!-- <component :is="code_data.data_operate.type" :code_data="code_data.data_operate" :scope="scope">
					</component> -->
          <component v-for="(item, i) in code_data.data_operate.buttons" :scope="scope" is="component.button"
            :code_data="item" :code_this="code_this" :$formData="childAndFather">
          </component>
        </template>
      </el-table-column>
    </el-table>
    <!-- 表底按钮 -->
    <div class="table_foot">
      <div class="foot_left" style="display: flex">
        <!-- 是否需要展示 共..条 -->
        <div v-if="code_data.tabOpt.select_all_tips" style="margin-left: 18px; display: flex; align-items: center">
          <el-checkbox @change="handleSelectAll" v-model="all_table_checked">共 {{ totalEntry }} 条</el-checkbox>
        </div>
        <div v-if="code_data.action_buttons.length > 0 &&
          code_data.action_buttons_bottom
          " style="display: flex; align-items: center">
          <el-button v-for="(item, index) in code_data.action_buttons.slice(
              0,
              code_data.action_buttons_set
            )" :key="index" @click="handleClickBtn(item)" :type="item.type" :size="item.size">{{ item.label
  }}</el-button>
          <el-select v-model="changeValue" placeholder="请选择" style="margin-left: 8px" size="small" class="foot_cascader"
            v-if="code_data.action_buttons.length > code_data.action_buttons_seth
              ">
            <el-option v-for="item in code_data.action_buttons.slice(
              code_data.action_buttons_seth
            )" :key="item.key" :label="item.label" :value="item.key"></el-option>
          </el-select>
        </div>
      </div>
      <!-- 分页选择器,都是固定配置 -->
      <div class="main_pages">
        <el-pagination :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="eachPage"
          :total="totalEntry" layout="sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
        <!-- hide-on-single-page -->
      </div>
    </div>
  </el-card>
</template>
<script>
export default {
  name: "component.table",
  props: {
    code_data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // 自定义按钮数据集
      childAndFather: {},
      // 表格数据项
      tableData: [],
      // 当前页码
      currentPage: 1,
      // 分页页数
      eachPage: 10,
      // 总条数
      totalEntry: 0,
      // 全选选中项
      multipleSelection: [],
      // 提交数据
      searchObject: null,
      // tabs当前选中项
      tabIndex: 0,
      // tabs数据源
      tabDataList: [],
      // 表头表尾下拉选择
      changeValue: "",
      // todo 优化 改分组弹窗
      change_class: false,
      // todo 优化 改分组弹窗内部输入框
      grouptext: "",
      // todo 优化 改分组弹窗内部分组数据源
      grouplist: [],
      // todo 优化 改分组弹窗内部分组选中项
      checkList: [],
      // todo 优化 选中项的所有id
      arrId: [],
      // 当前组件的实例，用于子级传递
      code_this: this,
      // 默认排序key
      sort_prop: "id",
      // 默认排序type
      sort_type: "desc",
      // 当前操作行的数据
      active_scope: {},
      all_table_checked: false,
    };
  },
  created() { },
  watch: {
    code_data: {
      handler(newVal, oldVal) {
        this.tableData = [];
        const result = Object.is(newVal, oldVal);
        //console.log(newVal, oldVal, 'table  监听到了吗');
        if (!result) {
          // 初始化
          this.init();
          // todo 优化 获取其她数据
          // this.grouplistapi();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    init() {
      // 如果表头有tabs，加载tabs的数据
      if (this.code_data?.tabList?.type == "Resource") {
        const _tabDataList = this.code.formatDataSource(
          this,
          this.code_data.tabList
        );
        if (!_tabDataList?.length) return false;
        _tabDataList.forEach((item, index) => {
          item["search_obj"] = {
            key: "status",
            value: item.key,
            op: "equal",
          };
          item["key"] = index + 1 + "";
        });
        this.tabDataList = _tabDataList;
      } else if (this.code_data?.tabList?.type == "Enum") {
        this.tabDataList = this.code_data.tabList.tabItem;
      }

      // 需要处理成对象，因为子组件需要通过key直接操控父组件
      let childAndFather = {};
      for (let i in this.code_data.dialog_dom) {
        let dialog_key = this.code_data.dialog_dom[i].dialog_key;
        if (dialog_key) {
          childAndFather[dialog_key] = false;
        }
      }
      this.childAndFather = childAndFather;

      // 当前的页码数和页面条数
      this.currentPage = this.code_data.currentPage;
      this.eachPage = this.code_data.eachPage;
      // 获取table数据
      this.getTableData();
    },
    getTableData() {
      // 整理搜索的数据
      let searchObj = {
        search: [],
        order: [
          {
            key: this.sort_prop,
            value: this.sort_type,
          },
        ],
        page: this.currentPage,
        limit: this.eachPage,
      };
      if (this.code_data?.bind) {
        searchObj = {
          ...searchObj,
          ...this.$parent.$refs[this.code_data.bind][0].extractSearch(),
        };
      }

      this.code.request(
        this,
        this.code_data.data_source.api,
        searchObj,
        (domThis, res) => {
          if (res.code == 200) {
            this.tableData = res.data.list;
            this.totalEntry = res.data.total;
            if (this.all_table_checked) {
              this.all_table_checked = false;
            }
          } else {
            domThis.$message.error(res.message);
          }
        },
        (domThis, error) => {
          domThis.$message.error(error.message);
        }
      );
    },
    table_sort(val) {
      //console.log(val);
      this.sort_prop = val.prop;
      if (val.order == "descending") {
        this.sort_prop = val.prop;
        this.sort_type = "desc";
      } else if (val.order == "ascending") {
        this.sort_prop = val.prop;
        this.sort_type = "asc";
      } else if (val.order == null) {
        this.sort_prop = "id";
        this.sort_type = "desc";
      }
      this.getTableData();
    },
    loadData(domThis, res) {
      //console.log("....", res)
      this.$nextTick(() => {
        this.tableData = res.list;
        this.totalEntry = res.total;
        this.$forceUpdate();
      });
    },
    singletable(val) {
      this.multipleSelection = val;
      if (val.length == this.tableData.length) {
        this.all_table_checked = true;
      } else {
        this.all_table_checked = false;
      }
    },
    getSearchData() {
      if (
        this.$parent.$refs[this.code_data.bind] &&
        this.$parent.$refs[this.code_data.bind][0]
      ) {
        this.searchObject = this.$parent.$refs[this.code_data.bind][0];
        //console.log(this.searchObject.requestData)
        this.searchObject.submit();
      }
    },
    // 子组件回调刷新列表
    componentChangeOnload() {
      this.getTableData()
    },
    searchParams() {
      return this.requestData;
    },
    handleSizeChange(val) {
      this.eachPage = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.common.backTop();
      this.getTableData();
    },
    // 改分组回调
    classCallback(callback) {
      if (callback) {
        this.getTableData();
      }
    },
    // 转移分类回调
    transferCallback(callback) {
      if (callback) {
        this.getTableData();
      }
    },
    // 调价回调
    priceCallback(val) {
      this.$message.success("操作成功");
      this.getTableData();
    },
    handleSelectAll(value) {
      if (!value) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.toggleAllSelection();
      }
      this.all_table_checked = value;
      // this.$refs.table.toggleAllSelection();
    },
    // tabs 切换
    handleClick(data) {
      this.tabIndex = Number(data.index);
      this.getTableData();
    },
    groundapi(resource) {
      const ids = this.multipleSelection.map((item) => item.id);
      let data = {
        id: ids,
      };
      if (Object.keys(resource.api.parameters).length) {
        data = Object.assign({}, data, resource.api.parameters);
      }
      this.$confirm(`您确定${resource.label}该${resource.sub_label}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.code.request(
          this,
          resource.api,
          data,
          (domThis, res) => {
            //console.log(res);
            if (res.code == 200) {
              domThis.$message.success(res.message);
              domThis.getTableData();
            } else {
              domThis.$message.warning(res.message);
            }
          },
          (_, error) => {
            this.$message.error(error.message);
          }
        );
      });
    },
    // 表头 按钮点击
    // handleClickBtn(data) {
    //   //console.log(data);

    //   if (!this.multipleSelection.length) {
    //     this.$message({
    //       message: `请选择需要${data.label}的${data.sub_label}`,
    //       type: "warning",
    //     });
    //     return false;
    //   }

    //   this.code.request(
    //     this,
    //     this.code_data.data_source.api,
    //     searchObj,
    //     (domThis, res) => {
    //       if (res.code == 200) {
    //         this.tableData = res.data.list;
    //         this.totalEntry = res.data.total;
    //       } else {
    //         domThis.$message.error(res.message);
    //       }
    //     },
    //     (domThis, error) => {
    //       domThis.$message.error(error.message);
    //     }
    //   );
    // },
    // table_sort(val) {
    //   //console.log(val);
    //   this.sort_prop = val.prop;
    //   if (val.order == "descending") {
    //     this.sort_prop = val.prop;
    //     this.sort_type = "desc";
    //   } else if (val.order == "ascending") {
    //     this.sort_prop = val.prop;
    //     this.sort_type = "asc";
    //   } else if (val.order == null) {
    //     this.sort_prop = "id";
    //     this.sort_type = "desc";
    //   }
    //   this.getTableData();
    // },
    // loadData(domThis, res) {
    //   //console.log("....", res)
    //   this.$nextTick(() => {
    //     this.tableData = res.list;
    //     this.totalEntry = res.total;
    //     this.$forceUpdate();
    //   });
    // },
    // singletable(val) {
    // 	this.multipleSelection = val;
    // },
    // getSearchData() {
    // 	if (this.$parent.$refs[this.code_data.bind] && this.$parent.$refs[this.code_data.bind][0]) {
    // 		this.searchObject = this.$parent.$refs[this.code_data.bind][0];
    // 		//console.log(this.searchObject.requestData)
    // 		this.searchObject.submit();
    // 	}
    // },
    // searchParams() {
    // 	return this.requestData;
    // },
    // handleSizeChange(val) {
    //   this.eachPage = val;
    //   this.getTableData();
    // },
    // handleCurrentChange(val) {
    // 	this.currentPage = val;
    // 	this.common.backTop();
    // 	this.getTableData();
    // },
    // 改分组回调
    // classCallback(callback) {
    // 	if (callback) {
    // 		this.getTableData()
    // 	}
    // },
    // 转移分类回调
    // transferCallback(callback) {
    // 	if (callback) {
    // 		this.getTableData()
    // 	}
    // },
    // 调价回调
    // priceCallback(val) {
    // 	this.$message.success("操作成功");
    // 	this.getTableData();
    // },
    // handleSelectAll() {
    // 	this.$refs.table.toggleAllSelection()
    // },
    // tabs 切换
    // handleClick(data) {
    // 	this.tabIndex = Number(data.index);
    // 	this.getTableData()
    // },
    // groundapi(resource) {
    // 	const ids = this.multipleSelection.map(item => item.id);

    // 	this.$confirm(`您确定${resource.label}该${resource.sub_label}?`, '提示', {
    // 		confirmButtonText: "确定",
    // 		cancelButtonText: "取消",
    // 		type: "warning",
    // 	}).then(() => {
    // 		this.code.request(this, resource.api, { id: ids }, (domThis, res) => {
    // 			//console.log(res);
    // 			if (res.code == 200) {
    // 				domThis.$message.success(res.message)
    // 				domThis.getTableData()
    // 			} else {
    // 				domThis.$message.warning(res.message)
    // 			}
    // 		}, (_, error) => {
    // 			this.$message.error(error.message)
    // 		})
    // 	})
    // },
    // 表头 按钮点击
    handleClickBtn(data) {
      //console.log(data);

      if (!this.multipleSelection.length) {
        this.$message({
          message: `请选择需要${data.label}的${data.sub_label}`,
          type: "warning",
        });
        return false;
      }

      if (data.action == "Api") {
        this.groundapi(data);
      }
      if (data.action == "Dialog") {
        if (data.name == "gaifenzu") {
          this.$refs.change_class[0].change_class = true;
        }
        if (data.name == "gaipinpai") {
          this.$refs.change_brand[0].brandVisible = true;
        }
        if (data.name == "tiaojia") {
          this.$refs.change_pice[0].modifytype = true;
        }
        if (data.name == "zuanyifenlei") {
          this.$refs.transfer_class[0].transfer_class = true;
        }
        if (data.name == "export") {
          let arr = [];
          this.multipleSelection.forEach((item) => {
            arr.push(item.id);
          });
          this.$refs.diyExportMore[0].export_user_id = arr;
          this.$refs.diyExportMore[0].exportMore = true;
        }
        if (data.name == "editIntegral") {
          let arr = [];
          this.multipleSelection.forEach((item) => {
            arr.push(item.id);
          });
          this.$refs.editIntegral[0].user_id = arr;
          this.$refs.editIntegral[0].integralVisble = true;
        }
        if (data.name == "repertory") {
          let arr = [];
          this.multipleSelection.forEach((item) => {
            arr.push(item.id);
          });
          this.$refs.repertory[0].goods_id = arr;
          this.$refs.repertory[0].repertoryVisible = true;
        }
        if (data.name == "change_department") {
          this.$refs.change_department[0].setDepartmentDialog = true;
        }
      }
    },
    // 表头 select点击
    handle_table_select(e) {
      this.handleClickBtn(this.code_data.action_buttons[e]);
    },
    sealgroup() {
      if (!this.checkList) {
        this.$message({
          showClose: true,
          message: "请选择分组",
          type: "warning",
          offset: 200,
          duration: 1000,
        });
        return false;
      }
      this.allgroupapi(this.arrId, this.checkList);
    },
    cleargroup() {
      this.keyword = "";
      this.checkList = "";
      this.change_class = false;
    },
    // 获取分组
    grouplistapi() {
      let data = {
        search: [
          {
            key: "name",
            value: this.keywordeds,
            op: "search",
          },
        ],
        page: 1,
        limit: 100,
      };
      this.$get(this.$apis.groupList, data)
        .then((res) => {
          // //console.log(res)
          if (res.code == 200) {
            this.grouplist = res.data.list;
          } else {
            this.common.message(this, res.message);
          }
          // this.tableloading = false;
        })
        .catch((err) => {
          // //console.log(err)
          this.common.message(this, err.message);
        });
    },
    groupsearch() {
      let searchtext = this.$refs.groupsearchtext.value;
      this.keywordeds = searchtext;
      this.grouplistapi();
    },
    allgroupapi(id, goodsid) {
      let data = {
        id: id,
        group_id: goodsid,
      };
      this.$confirm("您确定修改商品分组?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$put(this.$apis.allGroup, data)
          .then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                duration: 1000,
                message: "修改商品分组成功",
                onClose: () => {
                  this.goodslistapi();
                },
              });
            } else {
              this.common.message(this, res.message);
            }
          })
          .catch((err) => {
            // //console.log(err)
            this.common.message(this, err.message);
          });
      });
    },
    handleSwitch(state, dataSource, _code_data) {
      //console.log(state)
      // 获取当前数据id
      let id = [this.tableData[dataSource.$index].id];

      let data = {
        id: id,
        [_code_data.key]: state,
      };
      if (state == 1) {
        // on
        this.code.request(
          this,
          _code_data.onApi,
          data,
          (_, res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              this.getTableData();
            } else {
              this.$message.warning(res.message);
            }
          },
          (_, error) => {
            this.$message.error(error.message);
          }
        );
      } else {
        // down
        this.code.request(
          this,
          _code_data.downApi,
          data,
          (_, res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              this.getTableData();
            } else {
              this.$message.warning(res.message);
            }
          },
          (_, error) => {
            this.$message.error(error.message);
          }
        );
      }
    },
    importCallback() {
      this.getTableData();
    },
    intergrlChange() {
      this.getTableData();
    },
    repertoryChange() {
      this.getTableData();
    },
  },
};
</script>
<style  scoped lang="scss">
/deep/.el-table-column--selection>.cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .el-table__body-wrapper {
  &::-webkit-scrollba {
    background: #d7d7d7 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #535353 !important;
  }
}

.table-buttons .el-select,
.table-buttons .el-select /deep/ .el-input {
  width: 120px !important;
}

.codetable {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  box-sizing: border-box;
}

.table_foot {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-table th.el-table__cell {
  background-color: #f9f9fc;
  font-weight: 600;
  color: #333;
  padding: 8px 0;
}

.el-table td.el-table__cell {
  color: #333;
}

.table_head {
  margin-bottom: 10px;
  padding-left: 18px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.grounptc /deep/ .el-dialog {
  width: 660px;
  border-radius: 2px;
}

.grounptcbox {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.grounptcboxup {
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid #ededed;
  box-sizing: border-box;
}

.grounptcboxup /deep/ .el-input {
  float: left;
  width: 320px;
}

.grounptcboxup /deep/ .el-input .el-input__inner {
  border-radius: 4px 0 0 4px;
}

.grounptcboxup button {
  padding: 0 16px;
  box-sizing: border-box;
  height: 40px;
  border-radius: 0 4px 4px 0;
  font-size: 14px;
  cursor: pointer;
  background: var(--fontColor, #fb6638);
  border: 0;
  color: #fff;
}

.grounpbutlist {
  width: 100%;
  padding: 20px 0 0 0;
  box-sizing: border-box;
}

.grounpbutlist .el-checkbox {
  margin-bottom: 12px;
}

.goodtablist .el-table th.el-table__cell:first-child>.cell {
  padding-left: 14px;
}
</style>
<style scoped>
.foot_cascader {
  margin-left: 10px;
  min-width: 112px;
  width: 112px;
  height: 32px;
  margin-bottom: 0;
}

.foot_cascader .el-input {
  height: 32px;
}

.foot_cascader .el-input input {
  height: 32px;
}

.foot_cascader /deep/ .el-input--suffix {
  width: 100%;
  line-height: 32px;
  height: 32px;
  display: block;
}

.foot_cascader /deep/ .el-input--suffix .el-input__inner {
  height: 32px;
}

.foot_cascader /deep/ .el-input--suffix .el-input__suffix .el-input__suffix-inner .el-input__icon {
  line-height: 32px;
}
</style>