<template>
  <div>
    <el-dialog title="选择链接" :visible.sync="linkVisible" >
        <div class="revamp_wrap">
            <div class="revamp_top">
                <p v-for="(item,index) in linkList" :key="index" :class="firstIndex==index?'active':''" @click.stop="firstCilck(index)">{{ item.name }}</p>
            </div>
            <div class="revamo_content">
                <div class="">
                    <div v-for="(item,index) in linkList[firstIndex]?.children" :key="index" class="page-box">
                        <div class="page-title">
                            <i class="el-icon-tickets"></i>{{ item.name }}
                        </div>
                        <div class="page-list">
                            <div v-for="(i,b) in item.children" :key="b" :class="['fullyItem',fullyIndex==b?'active':'']" @click.stop="secondClick(index,b)">
                                <p>{{ i.name }}</p>
                                <div class="second_active" v-if="secondIndex==index&&fullyIndex==b">
                                    <i class="el-icon-success"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="value">
                <div v-if="value.params && value.params.length > 0" class="fullInput">
                    <div class="form-group" v-for="(param, i) in value.params">
                        <label
                            class="col-sm-2 text-right col-form-label"
                            :class="param.required"
                            >{{ param.key }}</label
                        >
                        <div class="col-sm-10">
                            <el-input v-model="param.value"></el-input>
                            <div class="text-muted" v-if="param.desc">
                            {{ param.desc }}
                            </div>
                        </div>
                    </div>
                    <div class="fs-sm text-muted" v-if="is_required">
                        <p style="color: red; text-align: center">请填写标记 * 参数</p>
                    </div>
                </div>
            </template>
        </div>
        
      <div slot="footer" class="dialog-footer">
        <el-button @click="linkVisible = false">取 消</el-button>
        <el-button @click="submit" type="primary">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "linkUrlChoice",
  props: {
    isMobile: {
      type: Boolean,
      default() {
        return false;
      },
    },
    mobileActive: {
      type: String,
      default() {
        return "0";
      },
    },
  },
  data(){
    return {
        linkVisible:false,
        linkList:[],
        firstIndex:0,
        secondIndex:-1,
        fullyIndex:-1,  //三级选择
        value:'',
        is_required:false,
        all_options:[],
        fillLink:false,
    }
  },
  
  watch: {
    linkVisible(value) {
      // console.log(value)
      if (value) {
        this.value = "";
        let arr=JSON.parse(JSON.stringify(this.all_options))
        this.$set(this,'linkList',arr)
        this.linkList= arr;
        this.is_required=false;
      }
    },
  },
  created(){
    this.getLink()
  },
  mounted(){
    
  },
  methods:{
    firstCilck(index){
        this.firstIndex=index;
        this.secondIndex=-1;
        this.fullyIndex=-1;
        this.value='';
    },
    // 三级选择
    secondClick(index,b){
        this.secondIndex=index;
        this.fullyIndex=b;
        let value=this.linkList[this.firstIndex].children[this.secondIndex].children[this.fullyIndex];
        this.value = value;
      if (value.params.length) {
        if (value.params[0].required == "required") {
          this.is_required = true;
        } else {
          this.is_required = false;
        }
      }
    },
     // 提交数据
     submit() {
      if (this.is_required) {
        return false;
      }
      if(!this.value){
        this.$message({
            type: "warning",
            message: '请选择链接',
            showClose: true,
            offset: 200,
            duration: 1000,
        });
        return false
      }
      var value = this.value,
        paramsurl = "",
        link = this.value.link;
      
      if (value.link != "custom-nav") {
        if (value.params && value.params.length > 0) {
            let is_judge=value.params.every(item=>item.value!='');
            console.log(is_judge)
            if(!is_judge){
                this.$message({
                type: "warning",
                message: '请填写信息',
                showClose: true,
                offset: 200,
                duration: 1000,
                });
                return false
            }
          for (var i in value.params) {
            paramsurl +=
              value.params[i].key +
              "=" +
              encodeURIComponent(value.params[i].value) +
              "&";
          }
        }
        link += "?" + paramsurl;
      } else {
        if (value.params && value.params.length > 0) {
        let is_judge=value.params.every(item=>item.value!='');
        console.log(is_judge)
        if(!is_judge){
            this.$message({
              type: "warning",
              message: '请填写信息',
              showClose: true,
              offset: 200,
              duration: 1000,
            });
            return false
        }
          for (var i in value.params) {
            paramsurl = value.params[i].value;
          }
        }
        link = paramsurl;
      }
      if(value){
        link = link._trim("&");
        link = link._trim("?");
        value.link = link;
      }
      
      this.linkVisible = false;
      this.$emit("change", value);
    },
    
    getLink() {
      let that = this;
      let api=this.$apis.pickLink
      if(this.isMobile){
        api=this.$apis.mobileLinkSelect
      }
      let params={
        activity_id:this.mobileActive
      };
      this.$get(api,params)
        .then((res) => {
          //   //console.log(res);
          if (res.code == 200) {
            if(res.data&&res.data.length){
                for(let i=0;i<res.data.length;i++){
                    res.data[i].sole_key=i;
                }
              that.all_options=JSON.parse(JSON.stringify(res.data));
            }
            that.linkList = res.data;
          } else {
            that.$message({
              type: "warning",
              message: res.message,
              showClose: true,
              offset: 200,
              duration: 1000,
            });
          }
        })
        .catch((err) => {
          // //console.log(err);
          that.common.message(that, err.message);
        });
    },
  }
};
</script>
<style lang="scss" scoped>
.revamp_wrap{
    height: 500px;
    position: relative;
    // display: flex;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    padding: 16px 20px;
    padding-top: 0;
    font-size: 12px;
    line-height: 1.5;
    color: #666;
    .revamp_top{
       display: flex;
       align-items: center;
       flex-wrap: wrap;
       p{
        margin-top: 10px;
        border-radius: 4px;
        margin-right: 10px;
        padding: 0 15px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border: 1px solid #f1f1f1;
        color: #666;
        cursor: pointer;

       }
       p.active{
        border: none;
        background-color: var(--primary,#fb6638);
        color: #fff;
       }
    }
    .revamo_content{
        border: 1px solid #f1f1f1;
        overflow: auto;
        overflow-x: hidden;
        border-radius: 4px;
        padding: 10px;
        margin-top: 10px;
        .page-box{
            .page-title{
                line-height: 43px;
                height: 43px;
                i{
                    font-size: 12px;
                    margin-right: 10px;
                    color: #ffb9a3;
                }
            }
            .page-list{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                .fullyItem{
                    height: 32px;
                    line-height: 32px;
                    padding: 0 16px;
                    border: 1px solid #ededed;
                    border-radius: 4px;
                    margin: 5px 9px 5px 0;
                    cursor: pointer;
                    position: relative;
                    .second_active{
                        position: absolute;
                        position: absolute;
                        right: -7px;
                        bottom: -15px;
                        color: var(--primary);
                        font-size: 11px;
                        z-index: 50;
                        // transform: scale(.7);
                    }
                }
                .fullyItem.active{
                    border-color: var(--primary,#fb6638);
                }
            }
        }
    }
}
.fullInput{
    margin-top: 10px;
}
.form-group {
    margin-bottom: 14px;
    display: flex;
    flex-wrap: wrap;
    .col-form-label {
      padding-top: 14.9px;
      padding-bottom: 3.9px;
      text-align: right;
      //   flex: 0 0 16.666667%;
      //   max-width: 16.666667%;
      min-width: 80px;
    }
    .col-sm-10 {
      padding-left: 25px;
      flex: 1;
      /deep/.el-input {
      }
      .text-muted {
        color: #636c72;
        font-size: 12px;
        text-align: left;
      }
    }
  }
</style>