<template>
    <div class="component-form-input">
        <el-radio-group v-model="$formData[code_data.key]" v-if="code_data?.data_source">
            <el-radio :label="item.key" v-for="(item, index) in code_data.data_source.source_data" :key="index">{{
                item.value
            }}</el-radio>
        </el-radio-group>
        <div v-if="code_data?.diy_input && $formData[code_data.key] === code_data.diy_input.is_bind">
            <template v-if="code_data.diy_input.children.length">
                <template v-if="code_data.diy_input.children[0].type == 'date_time_picker'">
                    <el-date-picker v-model="$formData[code_data.diy_input.children[0].key]" type="datetimerange"
                        start-placeholder="开始日期" format="yyyy-MM-dd" :default-time="['00:00:00', '23:59:59']"
                        value-format="yyyy-MM-dd hh:mm:ss" end-placeholder="结束日期">
                    </el-date-picker>
                </template>
                <template v-else>
                    <el-input v-for="(item, index) in code_data.diy_input.children" :type="item.type"
                        v-model="$formData[item.key]" :placeholder="item.placeholder"
                        :style="{ width: (item?.width || '100px') }" :key="index"></el-input>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'component.form.radio',
    props: {
        code_data: {
            type: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
.el-input+.el-input {
    margin-left: 15px;
}
</style>