<template>
	<div>
		<!-- 订单备注弹层 -->
		<el-dialog title="备注" class="remarkstc" :visible.sync="remarkstype">
			<div class="remarksbox">
				<el-input type="textarea" v-model.trim="attributeval.words" maxlength="255" show-word-limit resize="none"
					class="textarea-box" placeholder="备注内容(最多输入255字)"></el-input>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeattrubute()">取 消</el-button>
				<el-button class="addressqr" @click="certitude()">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'attribute',
		data() {
			return {
				remarkstype: false, //控制备注是否显示
				attributeval: {},
			};
		},
		beforeCreate(){
		},
		created() {
			
		},
		
		mounted() {
			
		},

		methods: {
			open(val){
				this.remarkstype = true;
				if(val){
					this.$set(this,'attributeval',val);
				}
			},
			certitude(){
				this.$emit("certitude",this.attributeval)
			},
			closeattrubute(){
				this.remarkstype = false;
			},
		},
	};
</script>

<style >
	.remarkstc .el-dialog {
	    width: 520px !important;
	    border-radius: 4px !important;
	}
	
	.remarksbox {
	    width: 100%;
	    padding: 20px;
	    box-sizing: border-box;
	}
	.remarksbox  .el-textarea{width: 100% !important;}
	.remarksbox textarea {
	    height: 117px;
	    padding-bottom: 18px;
	    box-sizing: border-box;
	}
</style>