<template>
	<div>
		<div class="boxright">
			<div class="boxrightconter">
				<div class="boxrightup grid">
					<div class="boxrightupleft grid">
						<div class="boxwelcome">
							<div class="boxwelcomeup" :style="{ backgroundImage: 'url(' + bjimg + ')' }">
								<div class="boxwelcometext">
									<h5>欢迎登录系统</h5>
									<p>别总是羡慕别人光芒万丈，别忘了自己也会发光</p>
								</div>
								<p class="boxwelcometime">
									上次登录：2023-02-27 11:18:08 IP: 183.141.223.144
								</p>
							</div>
							<div class="boxwelcomelast">
								<div class="boxwelcomelastup d-flex align-items-center">
									<div class="boxwelcomelastupimg">
										<img src="../../assets/images/shopdefault.png" alt="" />
										<span>SVIP888</span>
									</div>
									<div class="boxwelcomelastuptext flex_1">
										<p class="boxwelcomelastupname">韩韩小店</p>
										<p class="boxwelcomelastuptime">
											到期时间：2024-06-19 17:22:28
										</p>
										<a href="javascript:void(0);" class="quit" @click="quit()"><i
												class="el-icon-switch-button"></i>
											退出</a>
									</div>
								</div>
								<div class="boxnotice">
									<div class="d-flex align-items-center boxnoticecon">
										<i class="el-icon-s-opportunity"></i>
										<span>最新公告：</span>
										<a href="javascript:void(0);">
											<p class="tip">测试公告</p>
										</a>
										<div class="boxmore flex_1">
											<a href="javascript:void(0);">2022-04-13 <b class="el-icon-arrow-right"></b></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="boxoverview">
							<div class="boxtitle d-flex justify-content-between">
								<p class="tip-title">
									实时概况<span>更新于 2023-02-27 11:36:31</span>
								</p>
								<p class="boxhide">
									<span>复制数据</span>
									<span class="line"></span>
									<span>布局</span>
								</p>
							</div>
							<div class="boxoverviewlist grid">
								<div class="boxoverviewitem">
									<div class="d-flex align-items-center justify-content-between">
										<div class="boxtoday">
											<div class="today">
												今日支付订单数<span>(笔)</span>
												<el-tooltip class="boxicon" effect="light" content="提示文字" placement="right">
													<i class="el-icon-question"></i>
												</el-tooltip>
											</div>
										</div>
										<p class="boxday">昨日</p>
									</div>
									<div class="d-flex align-items-center justify-content-between middle">
										<p class="bignumber">0</p>
										<p class="smallnumber">0</p>
									</div>
									<div class="d-flex align-items-center justify-content-between compare">
										<p>
											<span>较昨日</span>
											<span class="flex_1">-</span>
										</p>
									</div>
								</div>
								<div class="boxoverviewitem">
									<div class="d-flex align-items-center justify-content-between">
										<div class="boxtoday">
											<div class="today">
												今日支付订单数<span>(笔)</span>
												<el-tooltip class="boxicon" effect="light" content="提示文字" placement="right">
													<i class="el-icon-question"></i>
												</el-tooltip>
											</div>
										</div>
										<p class="boxday">昨日</p>
									</div>
									<div class="d-flex align-items-center justify-content-between middle">
										<p class="bignumber">0</p>
										<p class="smallnumber">0</p>
									</div>
									<div class="d-flex align-items-center justify-content-between compare">
										<p>
											<span>较昨日</span>
											<span class="flex_1 down">100%<i class="el-icon-caret-top"></i></span>
										</p>
									</div>
								</div>
								<div class="boxoverviewitem">
									<div class="d-flex align-items-center justify-content-between">
										<div class="boxtoday">
											<div class="today">
												今日支付订单数<span>(笔)</span>
												<el-tooltip class="boxicon" effect="light" content="提示文字" placement="right">
													<i class="el-icon-question"></i>
												</el-tooltip>
											</div>
										</div>
										<p class="boxday">昨日</p>
									</div>
									<div class="d-flex align-items-center justify-content-between middle">
										<p class="bignumber">0</p>
										<p class="smallnumber">0</p>
									</div>
									<div class="d-flex align-items-center justify-content-between compare">
										<p>
											<span>较昨日</span>
											<span class="flex_1">0%<i class="el-icon-caret-top"></i></span>
										</p>
									</div>
								</div>
								<div class="boxoverviewitem">
									<div class="d-flex align-items-center justify-content-between">
										<div class="boxtoday">
											<div class="today">
												今日支付订单数<span>(笔)</span>
												<el-tooltip class="boxicon" effect="light" content="提示文字" placement="right">
													<i class="el-icon-question"></i>
												</el-tooltip>
											</div>
										</div>
										<p class="boxday">昨日</p>
									</div>
									<div class="d-flex align-items-center justify-content-between middle">
										<p class="bignumber">0</p>
										<p class="smallnumber">0</p>
									</div>
									<div class="d-flex align-items-center justify-content-between compare">
										<p>
											<span>较昨日</span>
											<span class="flex_1">-</span>
										</p>
									</div>
								</div>
								<div class="boxoverviewitem">
									<div class="d-flex align-items-center justify-content-between">
										<div class="boxtoday">
											<div class="today">
												今日支付订单数<span>(笔)</span>
												<el-tooltip class="boxicon" effect="light" content="提示文字" placement="right">
													<i class="el-icon-question"></i>
												</el-tooltip>
											</div>
										</div>
										<p class="boxday">昨日</p>
									</div>
									<div class="d-flex align-items-center justify-content-between middle">
										<p class="bignumber">0</p>
										<p class="smallnumber">0</p>
									</div>
									<div class="d-flex align-items-center justify-content-between compare">
										<p>
											<span>较昨日</span>
											<span class="flex_1 down">100%<i class="el-icon-caret-top"></i></span>
										</p>
									</div>
								</div>
								<div class="boxoverviewitem">
									<div class="d-flex align-items-center justify-content-between">
										<div class="boxtoday">
											<div class="today">
												今日支付订单数<span>(笔)</span>
												<el-tooltip class="boxicon" effect="light" content="提示文字" placement="right">
													<i class="el-icon-question"></i>
												</el-tooltip>
											</div>
										</div>
										<p class="boxday">昨日</p>
									</div>
									<div class="d-flex align-items-center justify-content-between middle">
										<p class="bignumber">0</p>
										<p class="smallnumber">0</p>
									</div>
									<div class="d-flex align-items-center justify-content-between compare">
										<p>
											<span>较昨日</span>
											<span class="flex_1">0%<i class="el-icon-caret-top"></i></span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="boxrightupright">
						<div class="boxtitle d-flex justify-content-between">
							<p class="tip-title">待办事项</p>
							<p class="boxhide">
								<span>布局</span>
							</p>
						</div>
						<div class="boxrightuprightlist grid">
							<div class="deal-item" v-for="(item, index) in 9" :key="index">
								<a href="javascript:void(0);">
									<div class="count d-flex justify-content-center">
										<div class="count-header d-flex align-items-center">
											<span class="count-item">0</span>
										</div>
									</div>
									<div class="deal-text">
										商品售罄
										<el-tooltip class="boxicon" effect="light" content="提示文字" placement="top">
											<i class="el-icon-question"></i>
										</el-tooltip>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div class="boxrightcon d-flex">
					<!-- 常用功能 -->
					<div class="ability">
						<div class="boxtitle d-flex justify-content-between">
							<p class="tip-title">常用功能</p>
							<p class="boxhide">
								<span>布局</span>
							</p>
						</div>
						<div class="abilitylist">
							<div class="ability-item" v-for="(item, index) in 9" :key="index">
								<a href="javascript:void(0);">
									<div class="ability-itemimg d-flex align-items-center justify-content-center">
										<img src="../../assets/images/decorate.png" alt="" />
									</div>
									<p class="abilitylistext">店铺装修</p>
								</a>
							</div>
						</div>
					</div>
					<!-- 统计 -->
					<div class="boxranking d-flex">
						<div class="boxranklist firstrank flex_1">
							<div class="boxtitle d-flex justify-content-between align-items-center">
								<p class="tip-title">交易概况</p>
								<div class="rankright">
									<el-dropdown trigger="click">
										<el-button class="rankbutton" type="primary">
											<strong>更多菜单</strong>
											<i class="el-icon-arrow-down el-icon--right rankjiao"></i>
										</el-button>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item>支付订单数</el-dropdown-item>
											<el-dropdown-item>实际支付订单数</el-dropdown-item>
											<el-dropdown-item>支付金额</el-dropdown-item>
											<el-dropdown-item>实际支付金额</el-dropdown-item>
											<el-dropdown-item>商品销量</el-dropdown-item>
											<el-dropdown-item>实际商品销量</el-dropdown-item>
											<el-dropdown-item>支付人数</el-dropdown-item>
											<el-dropdown-item>实际支付人数</el-dropdown-item>
											<el-dropdown-item>新增会员数</el-dropdown-item>
											<el-dropdown-item>新增分销商</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
									<div class="rankrighted clearfloat">
										<button class="active">7天</button>
										<button>15天</button>
										<button>30天</button>
									</div>
								</div>
							</div>
							<div class="rankpeople d-flex">
								<p>7天支付人数总计：<span>1</span> <em>人</em></p>
							</div>
							<div class="ranktj" id="firstechart"></div>
						</div>
						<div class="boxranklist secondrank">
							<div class="boxtitle d-flex justify-content-between align-items-center">
								<p class="tip-title">商品排行榜</p>
								<div class="rankright">
									<div class="rankrighted clearfloat">
										<button class="active">7天</button>
										<button>15天</button>
										<button>30天</button>
									</div>
								</div>
							</div>
							<div class="rankconter">
								<div class="rankcontitle d-flex align-items-center">
									<span>排行</span>
									<span>商品名称</span>
									<span>销量</span>
									<span>销售额 </span>
								</div>
								<div class="rankclist">
									<div class="rankclisted d-flex align-items-center">
										<span><img src="../../assets/images/top-0.png" alt="" /></span>
										<span>
											<el-tooltip class="boxicon" effect="light"
												content="办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱" placement="top">
												<a href="javascript:void(0);">办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱</a>
											</el-tooltip>
										</span>
										<span>2</span>
										<span>360.00</span>
									</div>
									<div class="rankclisted d-flex align-items-center">
										<span><img src="../../assets/images/top-1.png" alt="" /></span>
										<span>
											<el-tooltip class="boxicon" effect="light"
												content="办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱" placement="top">
												<a href="javascript:void(0);">办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱</a>
											</el-tooltip>
										</span>
										<span>2</span>
										<span>360.00</span>
									</div>
									<div class="rankclisted d-flex align-items-center">
										<span><img src="../../assets/images/top-2.png" alt="" /></span>
										<span>
											<el-tooltip class="boxicon" effect="light"
												content="办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱" placement="top">
												<a href="javascript:void(0);">办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱</a>
											</el-tooltip>
										</span>
										<span>2</span>
										<span>360.00</span>
									</div>
									<div class="rankclisted d-flex align-items-center">
										<span>4</span>
										<span>
											<el-tooltip class="boxicon" effect="light"
												content="办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱" placement="top">
												<a href="javascript:void(0);">办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱</a>
											</el-tooltip>
										</span>
										<span>2</span>
										<span>360.00</span>
									</div>
								</div>
								<!-- 暂无数据 -->
								<div class="pitera" style="display: none">
									<img src="../../assets/images/home-empty.png" alt="" />
									<p>暂无数据</p>
								</div>
							</div>
						</div>
						<div class="boxranklist threerank">
							<div class="boxtitle d-flex justify-content-between align-items-center">
								<p class="tip-title">会员排行</p>
								<div class="rankright">
									<div class="rankrighted clearfloat">
										<button class="active">7天</button>
										<button>15天</button>
										<button>30天</button>
									</div>
								</div>
							</div>
							<div class="rankconter">
								<div class="rankcontitle d-flex align-items-center">
									<span>排行</span>
									<span>商品名称</span>
									<span>销量</span>
									<span>销售额 </span>
								</div>
								<div class="rankclist" style="display: none">
									<div class="rankclisted d-flex align-items-center">
										<span><img src="../../assets/images/top-0.png" alt="" /></span>
										<span>
											<el-tooltip class="boxicon" effect="light"
												content="办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱" placement="top">
												<a href="javascript:void(0);">办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱</a>
											</el-tooltip>
										</span>
										<span>2</span>
										<span>360.00</span>
									</div>
									<div class="rankclisted d-flex align-items-center">
										<span><img src="../../assets/images/top-1.png" alt="" /></span>
										<span>
											<el-tooltip class="boxicon" effect="light"
												content="办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱" placement="top">
												<a href="javascript:void(0);">办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱</a>
											</el-tooltip>
										</span>
										<span>2</span>
										<span>360.00</span>
									</div>
									<div class="rankclisted d-flex align-items-center">
										<span><img src="../../assets/images/top-2.png" alt="" /></span>
										<span>
											<el-tooltip class="boxicon" effect="light"
												content="办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱" placement="top">
												<a href="javascript:void(0);">办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱</a>
											</el-tooltip>
										</span>
										<span>2</span>
										<span>360.00</span>
									</div>
									<div class="rankclisted d-flex align-items-center">
										<span>4</span>
										<span>
											<el-tooltip class="boxicon" effect="light"
												content="办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱" placement="top">
												<a href="javascript:void(0);">办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱</a>
											</el-tooltip>
										</span>
										<span>2</span>
										<span>360.00</span>
									</div>
								</div>
								<!-- 暂无数据 -->
								<div class="pitera">
									<img src="../../assets/images/home-empty.png" alt="" />
									<p>暂无数据</p>
								</div>
							</div>
						</div>
						<div class="boxranklist forerank">
							<div class="boxtitle d-flex justify-content-between align-items-center">
								<p class="tip-title">门店排行</p>
								<div class="rankright">
									<div class="rankrighted clearfloat">
										<button class="active">7天</button>
										<button>15天</button>
										<button>30天</button>
									</div>
								</div>
							</div>
							<div class="rankconter">
								<div class="rankcontitle d-flex align-items-center">
									<span>排行</span>
									<span>商品名称</span>
									<span>销量</span>
									<span>销售额 </span>
								</div>
								<div class="rankclist" style="display: none">
									<div class="rankclisted d-flex align-items-center">
										<span><img src="../../assets/images/top-0.png" alt="" /></span>
										<span>
											<el-tooltip class="boxicon" effect="light"
												content="办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱" placement="top">
												<a href="javascript:void(0);">办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱</a>
											</el-tooltip>
										</span>
										<span>2</span>
										<span>360.00</span>
									</div>
									<div class="rankclisted d-flex align-items-center">
										<span><img src="../../assets/images/top-1.png" alt="" /></span>
										<span>
											<el-tooltip class="boxicon" effect="light"
												content="办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱" placement="top">
												<a href="javascript:void(0);">办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱</a>
											</el-tooltip>
										</span>
										<span>2</span>
										<span>360.00</span>
									</div>
									<div class="rankclisted d-flex align-items-center">
										<span><img src="../../assets/images/top-2.png" alt="" /></span>
										<span>
											<el-tooltip class="boxicon" effect="light"
												content="办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱" placement="top">
												<a href="javascript:void(0);">办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱</a>
											</el-tooltip>
										</span>
										<span>2</span>
										<span>360.00</span>
									</div>
									<div class="rankclisted d-flex align-items-center">
										<span>4</span>
										<span>
											<el-tooltip class="boxicon" effect="light"
												content="办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱" placement="top">
												<a href="javascript:void(0);">办公桌面收纳盒塑料抽屉式收纳柜办公室置物架用品文件整理箱</a>
											</el-tooltip>
										</span>
										<span>2</span>
										<span>360.00</span>
									</div>
								</div>
								<!-- 暂无数据 -->
								<div class="pitera">
									<img src="../../assets/images/home-empty.png" alt="" />
									<p>暂无数据</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="boxbutton d-flex">
					<!-- 咨询售前 -->
					<div class="presale">
						<div class="presaleimg">
							<img src="../../assets/images/day_bg.png" />
						</div>
						<div class="presaletext">
							<h5>售前</h5>
							<p>咨询电话：15023222222</p>
						</div>
						<div class="presalebut">
							<a href="javascript:void(0);" class="presalefirst">QQ咨询</a>
							<el-tooltip class="boxicon" effect="light" placement="top">
								<div slot="content" class="presalewximg">
									<img src="../../assets/images/shopdefault.png" alt="" />
								</div>
								<a href="javascript:void(0);" class="wxpresale">微信咨询</a>
							</el-tooltip>
						</div>
						<div class="presaletime">
							<p>工作时间 : 12</p>
						</div>
					</div>
					<!-- 营销统计 -->
					<div class="marketing d-flex">
						<div class="marketlist flex_1 sales">
							<div class="boxtitle d-flex justify-content-between">
								<p class="tip-title">营销渠道收入比例</p>
							</div>
							<div class="marketriest" id="marketed"></div>
						</div>
						<div class="marketlist flex_1 sales">
							<div class="boxtitle d-flex justify-content-between">
								<p class="tip-title">会员注册渠道比例</p>
							</div>
							<div class="marketriest" id="marketzcqd"></div>
						</div>
						<div class="marketlist marketlast">
							<div class="marketlastcon swiper-container swiper">
								<ul class="clearfloat swiper-wrapper">
									<li class="swiper-slide">
										<a href="javascript:void(0);"><img src="../../assets/images/cs1.png" alt="" /></a>
									</li>
									<li class="swiper-slide">
										<a href="javascript:void(0);"><img src="../../assets/images/cs2.jpg" alt="" /></a>
									</li>
									<li class="swiper-slide">
										<a href="javascript:void(0);"><img src="../../assets/images/cs3.png" alt="" /></a>
									</li>
								</ul>
								<div class="swiper-pagination bannerpage"></div>
								<div class="swiper-button-next bannernext" slot="button-next">

								</div>
								<div class="swiper-button-prev bannerprev" slot="button-prev">

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 右下角 系统更新消息提示框 -->

		<div class="renew-box" v-if="Object.keys(sysUpdateInfo).length" :class="{ 'active': uploadIsShow }">
			<div class="renewtitle">
				<div class="renewleft">
					<img src="~@/assets/logo.png" alt="">
					<span>发现新版本 1.8.03</span>
				</div>
				<div class="renewright">
					<el-button type="text" size="medium" icon="el-icon-close"></el-button>
				</div>
			</div>
			<div class="renewbox">
				<div v-html="sysUpdateInfo.content"></div>
			</div>
			<div class="renewbut">
				<el-button type="primary" @click="showUpdateDetail">立即查看</el-button>
			</div>
		</div>
	</div>
</template>
<script>
// import Swiper from "swiper";
import {
	Swiper,
	Navigation,
	Pagination,
	Autoplay
} from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay])
export default {
	data() {
		return {
			bjimg: require("../../assets/images/day_bg.png"), //背景图
			sysUpdateInfo: {},
			uploadIsShow: false
		};
	},

	created() {

	},
	mounted() {
		// 轮播
		var mySwiper = new Swiper(".swiper-container", {
			autoplay: true,
			loop: true,
			speed: 600,
			direction: 'horizontal',
			paginationClickable: true,
			preventClicks: false,
			navigation: {
				nextEl: ".bannernext",
				prevEl: ".bannerprev",
			},
			pagination: {
				el: '.bannerpage',
			},
		});
		this.firstecharted();
		this.yxdraw();
		this.hydraw();

		// 获取系统是否有更新推送
		this.getSysUpdateMessage()
	},
	methods: {
		// 获取当前是否有系统升级信息
		getSysUpdateMessage() {
			this.$get(this.$apis.sysUpdateMessage).then(res => {
				//console.log(res, 'sysUpdateMessage');
				if (res.code == 200) {
					this.sysUpdateInfo = res.data
					setTimeout(() => {
						this.uploadIsShow = true
					}, 500);
				}
			})
		},
		// 系统升级详情
		showUpdateDetail() {
			this.$put(this.$apis.setUpdateMessageRead + `?id=${this.sysUpdateInfo.id}`).then(res => {
				if (res.code == 200) {
					this.$router.push('/setup/up_optimization/system_updates')
				} else {
					this.$message.error(res.message)
				}
			})
		},
		//退出
		quit() {
			this.common.signout(this);
		},
		//统计
		firstecharted() {
			let myChart = this.$echarts.init(document.getElementById("firstechart"));
			let option = {
				tooltip: {
					trigger: "axis",
				},
				grid: {
					top: "5%",
					left: "0%",
					right: "2%",
					bottom: "7%",
					containLabel: true,
				},
				xAxis: [{
					type: "category",
					boundaryGap: false,
					axisLabel: {
						show: true,
						color: "#999",
					},
					axisLine: {
						show: true, //是否显示轴线
						lineStyle: {
							color: "#f7f7f7", //刻度线的颜色
						},
					},
					data: [
						"2016/11/1",
						"2016/11/2",
						"2016/11/3",
						"2016/11/4",
						"2016/11/5",
						"2016/11/6",
					],
				},],
				yAxis: [{
					type: "value",
					axisLabel: {
						show: true,
						color: "#999",
					},
					axisLine: {
						show: true, //是否显示轴线
						lineStyle: {
							color: "#f7f7f7", //刻度线的颜色
						},
					},
				},],
				series: [{
					name: "新增数",
					type: "line",
					smooth: true,
					itemStyle: {
						color: "rgb(255, 70, 131)",
					},
					areaStyle: {
						color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
							offset: 0,
							color: "rgb(255, 70, 131)",
						},
						{
							offset: 1,
							color: "rgb(255, 158, 68)",
						},
						]),
					},
					data: [658, 98, 418, 108, 98, 78],
				},],
			};
			myChart.setOption(option);
		},
		yxdraw() {
			let myChart = this.$echarts.init(document.getElementById("marketed"));
			let option = {
				tooltip: {
					trigger: "item",
					formatter: function (data) {
						//console.log(data)
						return (
							data.name +
							"</br>" +
							"人数：" +
							data.value +
							"</br>占比：" +
							data.percent +
							"%" +
							"</br>"
						);
					},
				},
				legend: {
					orient: "vertical",
					icon: "circle",
					itemWidth: 6,
					itemHeight: 6,
					left: "80%",
					x: "right",
					y: "center",
					color: "#666666",
					fontSize: "12px",
					selectedMode: true,
					data: ["公众号", "微信小程序", "H5", "APP", "PC商城"],
				},
				series: [{
					name: "各端人数",
					center: ["30%", "50%"],
					type: "pie",
					radius: ["50%", "66%"],
					avoidLabelOverlap: false,
					label: {
						show: false,
						position: "center",
						emphasis: {
							show: true,
							fontSize: "14",
						},
					},
					labelLine: {
						show: false,
					},
					data: [{
						value: 335,
						name: "公众号"
					},
					{
						value: 310,
						name: "微信小程序"
					},
					{
						value: 234,
						name: "H5"
					},
					{
						value: 135,
						name: "APP"
					},
					{
						value: 1548,
						name: "PC商城"
					},
					],
				},],
			};
			myChart.setOption(option);
		},
		hydraw() {
			let myChart = this.$echarts.init(document.getElementById("marketzcqd"));
			var option = {
				tooltip: {
					trigger: "item",
					formatter: function (data) {
						//console.log(data)
						return (
							data.name +
							"</br>" +
							"人数：" +
							data.value +
							"</br>占比：" +
							data.percent +
							"%" +
							"</br>"
						);
					},
				},
				legend: {
					orient: "vertical",
					icon: "circle",
					itemWidth: 6,
					itemHeight: 6,
					left: "80%",
					x: "right",
					y: "center",
					color: "#666666",
					fontSize: "12px",
					selectedMode: true,
					data: ["公众号", "微信小程序", "H5", "APP", "PC商城"],
				},
				series: [{
					name: "各端人数",
					center: ["30%", "50%"],
					type: "pie",
					radius: ["50%", "66%"],
					avoidLabelOverlap: false,
					label: {
						show: false,
						position: "center",
						emphasis: {
							show: true,
							fontSize: "14",
						},
					},
					labelLine: {
						show: false,
					},
					data: [{
						value: 35,
						name: "公众号"
					},
					{
						value: 310,
						name: "微信小程序"
					},
					{
						value: 100,
						name: "H5"
					},
					{
						value: 15,
						name: "APP"
					},
					{
						value: 48,
						name: "PC商城"
					},
					],
				},],
			};
			myChart.setOption(option);
		},
	},
};
</script>
<style scoped lang="scss">
// 仅为了处理v-html情况下某些样式不生效的问题
@import url(./index.css);

.marketlist>>>.swiper {
	--swiper-navigation-size: 0;
}

.renew-box {
	width: 330px;
	// height: 346px;
	height: 0px;
	position: fixed;
	bottom: 0;
	right: 0;
	background: #fff;
	border-radius: 6px 6px 0 0;
	box-shadow: 0px 1px 12px 0px #E5E5E5;
	// display: none;
	z-index: 100000;
	transition: all 1s;

	&.active {
		height: 346px;
	}

	.renewtitle {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 50px;
		padding: 0 20px;
		box-sizing: border-box;
		background: #F7F8FA;

		.renewleft {
			display: flex;
			align-items: center;

			img {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				margin-right: 10px;
			}

			.span {
				font-size: 16px;
				color: #333;
				font-weight: 500;
			}
		}

		.renewright {

			/deep/ .el-button,
			/deep/ .el-button {
				color: #000 !important;
				opacity: .5;
				padding: 0 !important;

				.el-icon-close {
					font-size: 20px;
				}
			}

			/deep/ .el-button:focus,
			/deep/ .el-button:hover {
				opacity: 1;
			}
		}
	}

	.renewbox {
		width: 100%;
		padding: 24px 24px 10px 24px;
		box-sizing: border-box;
		height: 240px;
		overflow-y: scroll;

		p {
			font-size: 14px;
			color: #666;
			font-weight: 400;
			line-height: 20px;
			margin-bottom: 5px;
			padding-left: 8px;
			box-sizing: border-box;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

	}

	.renewbut {
		width: 100%;
		padding: 10px 24px;
		box-sizing: border-box;

		/deep/ .el-button {
			width: 100%;
		}
	}
}
</style>
