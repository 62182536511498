<template>
    <el-card class="container">
        <!-- 需要挂载的外部弹窗组件 -->
        <div v-for="(item, index) in code_data.other_dialog" :key="index">
            <!-- 调价 -->
            <changePrice ref="change_pice" :picelist="multipleSelection" @showCityName="priceCallback"
                v-if="item?.type == 'tiaojia'"></changePrice>
            <!-- 改分组 -->
            <component.table.change_class ref="change_class" :propsList="multipleSelection" @callback="classCallback"
                v-if="item?.type == 'gaifenzu'"></component.table.change_class>
            <!-- 转移分组 -->
            <component.table.transfer_class ref="transfer_class" :propsList="multipleSelection" @callback="transferCallback"
                v-if="item?.type == 'zuanyifenlei'" :allCat="item.allCat" :setCat="item.setCat">
            </component.table.transfer_class>
            <!-- 日志 -->
            <component.table.log ref="table_log" v-if="item?.type == 'log'"></component.table.log>
            <!-- 导入 -->
            <diy-import-more ref="diyImportMore" :dialogTitle="item.dialog_title" v-if="item.type == 'import'"
                :dialogImportUrl="item.dialog_import_url" :dialogTemplateUrl="item.dialog_template_url"
                @dialogImportCallback="importCallback"></diy-import-more>
            <!-- 导出 -->
            <diy-export-more ref="diyExportMore" :dialogTitle="item.dialog_title" v-if="item?.type == 'export'"
                :dialogExportKeys="item.dialog_export_keys" :dialogExportUrl="item.dialog_export_url"
                :dialogExportIsSearch="item.dialogExportIsSearch"></diy-export-more>
            <!-- 修改积分 -->
            <integralEdit v-if="item?.type == 'editIntegral'" ref="editIntegral" :requestMethods="item.dialog_requestMethos"
                :requestUrl="item.dialog_import_url" @change="intergrlChange"></integralEdit>
            <!-- table 分享 -->
            <component.table.share ref="table_share" v-if="item?.type == 'table_share'"></component.table.share>
        </div>
        <el-table :data="tableData" v-loading="isLoading" ref="multipleTable" style="width: 100%" row-key="id"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" type="index" element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 1)">

            <!-- 表列 -->
            <el-table-column width="100"></el-table-column>
            <el-table-column v-for="(item, index) in code_data.components" :prop="item.key" :label="item.label"
                :min-width="item.parameters.min_width" :align="item.parameters.align" :key="index"
                :sortable="item?.sortable">
                <template slot-scope="scope">
                    <component :is="item.type" :code_data="item"
                        :scope="(scope.row[item.key] && item?.is_filter) ? scope.row[item.key][item.filter_key] : scope.row[item.key]"
                        v-if="item.type === 'component.table.text'"></component>
                    <component :is="item.type" :code_data="item" :scope="scope" v-else @handleSwitch="handleSwitch">
                    </component>
                </template>
            </el-table-column>

            <!-- 操作按钮，冻结在右侧 -->
            <el-table-column v-if="code_data?.data_operate" :prop="code_data.data_operate.key"
                :label="code_data.data_operate.label" :width="code_data.data_operate.parameters.width"
                :type="code_data.data_operate.type" fixed="right" align="center">
                <template slot-scope="scope">
                    <!-- <component :is="code_data.data_operate.type" :code_data="code_data.data_operate" :scope="scope">
					</component> -->
                    <component v-for="( item, i ) in code_data.data_operate.buttons" :scope="scope" is="component.button"
                        :code_data="item" :code_this="code_this" :$formData="childAndFather">
                    </component>
                </template>
            </el-table-column>

        </el-table>
    </el-card>
</template>

<script>
export default {
    name: "component.table.manage",
    props: {
        code_data: {
            type: Object,
            required: true
        }
    },
    watch: {
        code_data: {
            handler(newVal, oldVal) {
                this.tableData = [];
                const result = Object.is(newVal, oldVal)
                //console.log(newVal, oldVal, 'table  监听到了吗');
                if (!result) {
                    // 初始化
                    this.init();
                    // todo 优化 获取其她数据
                    // this.grouplistapi();
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            // 当前组件的实例，用于子级传递
            code_this: this,
            childAndFather: {},
            tableData: {},
            isLoading: false,
            errorImg: 'this.src="' + require("@/assets/images/lodingsb.svg") + '"' //图片加载失败
        }
    },
    methods: {
        init() {
            // 需要处理成对象，因为子组件需要通过key直接操控父组件
            let childAndFather = {}
            for (let i in this.code_data.dialog_dom) {
                let dialog_key = this.code_data.dialog_dom[i].dialog_key;
                if (dialog_key) {
                    childAndFather[dialog_key] = false
                }
            }
            this.childAndFather = childAndFather
            this.isLoading = true;

            // 获取table数据
            this.getTableData()
        },
        getTableData() {
            this.code.request(this, this.code_data.data_source.api, {}, (domThis, res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$nextTick(() => {
                        this.isLoading = false;
                    })
                } else {
                    domThis.$message.error(res.message)
                    domThis.isLoading = false;
                }
            }, (domThis, error) => {
                domThis.$message.error(error.message)
                domThis.isLoading = false;
            })
        },
        handleSwitch(state, dataSource, _code_data) {
            //console.log(state)
            // 获取当前数据id
            let id = [this.tableData[dataSource.$index].id]

            let data = {
                id: id,
                [_code_data.key]: state
            };
            if (state == 1) {
                // on
                this.code.request(this, _code_data.onApi, data, (_, res) => {
                    if (res.code == 200) {
                        this.$message.success(res.message)
                        this.getTableData();
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (_, error) => {
                    this.$message.error(error.message)
                })
            } else {
                // down
                this.code.request(this, _code_data.downApi, data, (_, res) => {
                    if (res.code == 200) {
                        this.$message.success(res.message)
                        this.getTableData();
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (_, error) => {
                    this.$message.error(error.message)
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.container {
    & /deep/ .el-loading-mask {
        position: absolute;
        height: auto;
    }
}
</style>