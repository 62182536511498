<template>
    <div class="search-item">
        <label class="search-label">{{ code_data.label }}：</label>
        <el-select v-model="$formData[code_data.key]['value']" filterable clearable
            :placeholder="`${code_data.parameters.placeholder}${code_data.label}` || ''"
            :multiple="code_data.parameters?.is_multiple" :size="code_data.size">
            <template v-if="code_data.data_source.type == 'Api'">
                <el-option v-for="(item, index) in selectOptions" :key="item[code_data.parameters.key] || index"
                    :label="item[code_data.parameters.label]" :value="item[code_data.parameters.key]">
                </el-option>
            </template>
            <template v-else>
                <el-option v-for="(item, index) in code_data.data_source.source_data"
                    :key="item[code_data.parameters.key] || index" :label="item[code_data.parameters.label]"
                    :value="item[code_data.parameters.value]">
                </el-option>
            </template>
        </el-select>
    </div>
</template>
<script>
export default {
    name: "component.search.selectInput",
    props: {
        code_data: {
            typeof: Object,
            required: true
        },
        $formData: {}
    },
    data() {
        return {
            selectOptions: []
        }
    },
    created() { },
    mounted() {
        if (this.code_data.data_source?.type == 'Api') {
            this.code_data.data_source['successCallback'] = function(domThis, res) {
                if (!Array.isArray(res.data)) {
                    domThis.selectOptions = res.data.list;
                } else {
                    domThis.selectOptions = res.data;
                }
            }
            this.code_data.data_source['failCallback'] = function(domThis, err) {
                console.error('fail load data', err);
            }
            this.code.formatDataSource(this, this.code_data.data_source);
        }
    }
}
</script>