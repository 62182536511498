<template>
  <div id="app" :class="stylenum == 1 ? 'headeractive' : ''">
    <div id="appContainer"></div>
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
import Theme from "theme-vue"
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      //定义状态
      isRouterAlive: true,
      stylenum: 0
    };
  },
  created() {
    this.getjurisdiction()
  },
  mounted() {
    this.getNoLoginGlobal();

    // 动态处理 :root 文件
    const root = document.documentElement;
    root.style.setProperty('--fontColor', '#000c9a');
    // 处理elementUI的主题色
    const Th = new Theme()
    Th.changeTheme('#000c9a')
  },
  methods: {
    // 获取未登录全局资源
    async getNoLoginGlobal() {
      let res = await this.$get(this.$apis.noLoginGlobal);
      if (res.code == 200) {
        let title =
          res.data["system.base.setting"]["backend_info"]["platform.mall.name"];
        document.title = title;
        let icon =
          res.data["system.base.setting"]["backend_info"][
          "platform.backend.icon_url"
          ];
        this.stylenum =
          res.data["system.base.setting"]["backend_info"][
          "platform.mall.show_style_type"
          ];
        window.localStorage.setItem("style", this.stylenum);
        var link =
          document.querySelector("link[rel*='icon']") ||
          document.createElement("link");
        link.type = "image/x-icon";
        link.rel = "shortcut icon";
        link.href = icon ? icon : "assets/images/icon.png";
        document.getElementsByTagName("head")[0].appendChild(link);
        this.$store.commit("SET_NO_LOGIN_GLOBAL", res.data);
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    },
    // 获取登录者的权限
    getjurisdiction() {
      let that = this;
      var tokenStr = window.localStorage.getItem('userInfo');
      if (!tokenStr) {
        return false
      }
      let data = {
        keys: ["admin.permissions"],
      };
      this.common.getAuthority(this, data);
    },
  }
};
</script>
<style lang="scss">
#app {
  color: var(--primary-color) !important;
}
</style>
