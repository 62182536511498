<template>
	<div>
		<!-- 商品选择器弹层 -->
		<el-dialog title="商品选择器" class="shoptc" :visible.sync="shoptype" :close-on-click-modal="false"
			:destroy-on-close="true">
			<div class="shoptcbox">
				<div class="global-search-box addglobal">
					<div class="search-content">
						<div class="search-item">
							<el-input v-model="shopName" :placeholder="addshopnamesku == 1 ? '请输入商品名称' : '请输入商品SKU编号'">
								<template slot="prepend">
									<el-select v-model="addshopnamesku" placeholder="请选择">
										<el-option v-for="(item, index) in shopaddList" :key="item.value"
											:label="item.label" :value="item.value"></el-option>
									</el-select>
								</template>
							</el-input>
						</div>
						<div class="search-item">
							<label class="search-label">商品分类：</label>
							<el-cascader ref="shopClass" filterable @change="classChange" :options="options"
								v-model="cattext" clearable :disabled="fitmentClass.length > 0"
								:props="{ value: 'id', label: 'name', children: 'children', checkStrictly: true }"></el-cascader>
						</div>
						<div class="search-item search-itemadd">
							<label class="search-label">商品品牌：</label>
							<el-select v-model="shoplisttype" filterable placeholder="请选择" clearable remote
								:remote-method="remoteMethoded" @visible-change="templateTagChange">
								<el-option v-for="item in brandlist" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
								<template v-if="brandtotal_entry > 10">
									<div class="purpagebutkong"></div>
									<div class="purpagebut">
										<el-pagination :small="true" @current-change="handclassfly" hide-on-single-page
											:current-page="brandPage" :page-size="10" layout="prev, next"
											:total="brandtotal_entry">
										</el-pagination>
									</div>
								</template>
							</el-select>
						</div>
						<div class="search-item search-itemadd">
							<label class="search-label">商品分组：</label>
							<el-select v-model="shopsubset" filterable clearable placeholder="全部">
								<el-option v-for="item in grouplist" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</div>
						<div class="search-item search-itemadd">
							<label class="search-label">商品状态：</label>
							<el-select v-model="shopstatus" clearable placeholder="请选择">
								<el-option v-for="item in common_info['goods.status.list']" :key="item.key"
									:label="item.value" :value="item.key"></el-option>
							</el-select>
						</div>
						<!-- <div class="search-item search-itemadd">
						    <label class="search-label">是否热卖：</label>
						    <el-select v-model="shophot" placeholder="请选择">
						    	<el-option v-for="(item, index) in is_shophot" :key="item.value"
						    		:label="item.label" :value="item.value"></el-option>
						    </el-select>
						</div>
						<div class="search-item search-itemadd">
						    <label class="search-label">是否推荐：</label>
						    <el-select v-model="shopsell" placeholder="请选择">
						    	<el-option v-for="(item, index) in is_shopsell" :key="item.value"
						    		:label="item.label" :value="item.value"></el-option>
						    </el-select>
						</div> -->
						<div class="search-item" v-if="is_pull">
							<el-checkbox v-model="addshop">仅显示未添加商品</el-checkbox>
						</div>
					</div>
					<div class="serach-buttom-button">
						<el-button type="primary" @click="shopseacrh">搜索</el-button>
						<el-button @click="reshopsetting()">清空</el-button>
						<el-button @click="wares">已选商品</el-button>
					</div>
				</div>
				<div class="shopboxcon">
					<div class="batchtable">
						<div class="batchtablecon">
							<el-table :data="tableData" ref="multipleTable" @selection-change="singletable" row-key="id">
								<el-table-column type="selection" min-width="50"
									:reserve-selection="true"></el-table-column>
								<el-table-column min-width="160" prop="sku" label="SKU"></el-table-column>
								<el-table-column label="商品信息" min-width="320">
									<template slot-scope="scope">
										<div class="shoplister d-flex align-items-center">
											<div class="shopimg">
												<img :src="scope.row.cover_pic" alt="" :onerror="errorImg" />
											</div>
											<div class="shoptext">
												<h6>{{ scope.row.name }}</h6>
											</div>
										</div>
									</template>
								</el-table-column>
								<!-- <el-table-column label="规格" align="center" min-width="180">
									<template slot-scope="scope">
										<div class="guige">
											<span v-for="(item, index) in scope.row.attr" :key="index">{{ item.specs_key
											}}：{{ item.specs_value }}</span>
										</div>
									</template>
								</el-table-column> -->
								<el-table-column label="价格" align="center" min-width="150">
									<template slot-scope="scope">
										<div>{{ scope.row.price }}积分</div>
									</template>
								</el-table-column>
								<el-table-column label="商品状态" align="center" min-width="120">
									<template slot-scope="scope">
										<span class="shoplasted">{{
											common_info["goods.status.map"][scope.row.status] }}</span>
									</template>
								</el-table-column>

								<template slot="empty">
									<div class="empty_data">
										<p>暂无数据</p>
									</div>
								</template>
							</el-table>
						</div>
					</div>
				</div>
				<div class="orderlastbut clearfloat">
					<div class="orderlastbutleft clearfloat">
						<p>
							<span class="ordercheck">
								<el-checkbox v-model="all_checked" @change="allchange">当前页全选</el-checkbox>
							</span>
						</p>
					</div>
					<div class="orderlastbutright">
						<el-pagination @size-change="handleSizeChange" hide-on-single-page
							@current-change="handleCurrentChange" :current-page.sync="currentPage"
							:page-sizes="[10, 20, 50, 100, 200]" :page-size="eachPage"
							layout="sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
						</el-pagination>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="shoptype = false">取 消</el-button>
				<el-button @click="shopselected" class="addressqr">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 已选商品弹层 -->
		<el-dialog title="已选商品" class="warestc" :visible.sync="warestype">
			<div class="waresbox">
				<div class="waresboxlist" v-if="this.wareslist && this.wareslist.length">
					<div class="batchtable">
						<div class="batchtablecon">
							<table>
								<thead>
									<tr>
										<th class="tableleft">商品</th>
										<th>操作</th>
									</tr>
								</thead>
								<colgroup>
									<col style="width: 80%" />
									<col style="width: 20%" />
								</colgroup>
								<tbody>
									<tr v-for="(item, index) in wareslist" :key="index">
										<td class="tableleft">
											<div class="shoplister d-flex align-items-center">
												<div class="shopimg">
													<img :src="item.cover_pic" alt="" :onerror="errorImg" />
												</div>
												<div class="shoptext">
													<h6>{{ item.name }}</h6>
												</div>
											</div>
										</td>
										<td>
											<div class="setoperate">
												<a href="javascript:void(0);" @click="delectshop(item)">删除</a>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<template v-else>
					<div class="pitera">
						<img src="../../assets/images/home-empty.png" alt="" />
						<p>暂无已选商品</p>
					</div>
				</template>
			</div>
			<div slot="footer" class="dialog-footer clearfloat">
				<div class="dialog-footerleft">
					<el-button @click="clearlist()">全部清空</el-button>
				</div>
				<div class="dialog-footeright">
					<el-button @click="warestype = false">取 消</el-button>
					<el-button @click="warestype = false" type="primary" class="addressqr">确 定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
export default {
	name: "shopselector",
	props: {
		is_ChanhChae: {
			type: Boolean,
			default() {
				return false;
			},
		},
		procurement: {
			type: String,
			default() {
				return '';
			}
		},
		isMobile: {		//判断是否是移动端挑选商品
			type: Boolean,
			default() {
				return false
			}
		},
		isPc: {
			type: Boolean,
			default: false
		},
		fitmentClass: {		//分类选择商品传过来的分类id
			type: Array,
			default() {
				return []
			}
		},
		is_pull: {		//是否是拉取商品
			type: Boolean,
			default() {
				return false;
			},
		},
		pull_goods_url: {	//拉取的商品的接口
			type: String,
			default: '',
		},
		pull_class_url: {	//拉取商品的分类接口
			type: String,
			default: '',
		},
		pull_brand_url: {	//拉取的品牌接口
			type: String,
			default: '',
		},
		pull_group_url: {	//拉取分组的接口
			type: String,
			default: '',
		},
	},
	data() {
		return {
			errorImg: 'this.src="' + require("../../assets/images/lodingsb.svg") + '"', //图片加载失败
			currentPage: 1,
			eachPage: 10,
			total_entry: 0,
			tableData: [], //列表数据
			multipleSelection: [], //表格中选中的数据
			all_checked: false, //是否选中
			shoptype: false, //商品选择器是否显示
			addshopnamesku: "1", //商品选择器商品名称或SKU
			shopName: "", //搜索字段
			shopaddList: [{
				label: "商品名称",
				value: "1",
			},
			{
				label: "商品SKU",
				value: "2",
			},
			],
			is_shophot: [{
				label: "是",
				value: "1",
			},
			{
				label: "否",
				value: "2",
			},
			],
			is_shopsell: [{
				label: "是",
				value: "1",
			},
			{
				label: "否",
				value: "2",
			},
			],
			options: [],
			cattext: "", //商品分类字段
			shoplisttype: "", //商品类型
			brandlist: [], //商品品牌
			shopsubset: "", //商品分组
			grouplist: [], //商品分组数据
			shopstatus: "", //商品状态
			shophot: "", //是否热卖
			shopsell: "", //是否推荐
			addshop: true, //仅显示未添加商品
			labortype: false, //控制操作弹层是否显示
			warestype: false, //控制已选商品弹层是否显示
			wareslist: [], //已选商品
			mobileActive: this.$route.query.active_id || this.$route.query.activity_id || '0',
			brandPage: 1,
			brandtotal_entry: 0,
			brandText: '',
			common_info: {}
		};
	},
	watch: {
		fitmentClass: {
			handler(newVal) {
				this.fitmentData(newVal);
			},
			deep: true,
			immediate: true,
		},
		shoptype(value) {
			if (value) {
				this.currentPage = 1
				this.eachPage = 10;
				this.reshopsetting(true);
			}
		},
	},
	created() {
		let data = {
			keys: ["goods.status.map", "cat.list", "goods.status.list"],
		};
		this.common.getCommonInfo(this, data, res => {
			this.common_info = res;
		});
		this.classListApi()
	},
	mounted() {
		// this.shopListapi();
		this.brandlistapi();
		this.grouplistapi();
	},

	methods: {
		shopListapi() {
			let data = {
				search: [],
				order: [{
					key: "id",
					value: "desc",
				},],
				page: this.currentPage,
				limit: this.eachPage,
			};
			if (this.shopName) {
				let obj = {
					key: this.addshopnamesku == 1 ? "name" : "sku",
					value: this.shopName,
					op: "search",
				};
				data.search.push(obj);
			}
			if (this.cattext && this.cattext.length) {
				data.search.push({
					key: "cat_id",
					value: this.cattext[this.cattext.length - 1],
					op: "equal",
				});
			}
			if (this.shoplisttype) {
				data.search.push({
					key: "brand_id",
					value: this.shoplisttype,
					op: "equal",
				});
			}
			if (this.shopsubset) {
				data.search.push({
					key: "group",
					value: this.shopsubset,
					op: "equal"
				});
			}
			if (this.shopstatus != '' || this.shopstatus === 0) {
				data.search.push({
					key: "status",
					value: this.shopstatus,
					op: "equal",
				});
			}
			if (this.shophot) {
				data.search.push({
					key: "is_hot",
					value: this.shophot,
					op: "equal"
				});
			}
			if (this.shopsell) {
				data.search.push({
					key: "is_best",
					value: this.shopsell,
					op: "equal"
				});
			}
			let api = this.$apis.goodsList + '0'
			if (this.isMobile) {
				api = this.$apis.mobileShopSlect + this.mobileActive
			}
			if (this.isPc) {
				api = this.$apis.pcGoodsList + this.mobileActive
			}
			if (this.is_pull) {
				if (this.addshop) {
					data.search.push({
						key: "is_exist",
						value: this.addshop == true ? "1" : "0",
						op: "equal",
					});
				}
			}
			if (this.pull_goods_url) {
				api = this.code.formatTemplate(this, this.pull_goods_url);
			}
			// console.log(api);
			this.$get(api, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.total_entry = res.data.total;
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					////console.log(err)
					this.common.message(this, err.message);
				});
		},
		getTreeData(data) {
			for (var i = 0; i < data.length; i++) {
				if (!data[i].children || data[i].children.length < 1) {
					data[i].children = undefined;
				} else {
					this.getTreeData(data[i].children);
				}
			}
			return data;
		},
		// 获取品牌
		brandlistapi() {
			let data = {
				page: this.brandPage,
				limit: 10,
				search: [],
			};
			if (this.brandText) {
				data.search.push({
					key: "name",
					value: this.brandText,
					op: "search"
				});
			}
			let api = this.$apis.brandList;
			if (this.isMobile) {
				api = this.$apis.mobileBrandSlect
			}
			if (this.pull_brand_url) {
				api = this.pull_brand_url
			}
			this.$get(api, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.brandlist = res.data.list;
						this.brandtotal_entry = res.data.total;
					} else {
						this.common.message(this, res.message);
					}
				})
				.catch((err) => {
					////console.log(err)
					this.common.message(this, err.message);
				});
		},

		handclassfly(val) {
			this.brandPage = val;
			this.brandlistapi();
		},
		remoteMethoded(e) {
			this.brandText = e;
			this.brandlistapi();
		},
		templateTagChange(val) {
			if (val === false) {
				this.brandlist = []
			} else {
				this.brandText = '';
				this.brandlistapi();
			}
		},
		// 获取分组
		grouplistapi() {
			let data = {
				page: 1,
				limit: 100,
			};
			let api = this.$apis.groupList
			if (this.isMobile) {
				api = this.$apis.mobileGroupSlect
			}
			if (this.pull_group_url) {
				api = this.pull_group_url
			}
			this.$get(api, data)
				.then((res) => {
					// //console.log(res)
					if (res.code == 200) {
						this.grouplist = res.data.list;
					} else {
						this.common.message(this, res.message);
					}
					// this.tableloading = false;
				})
				.catch((err) => {
					//console.log(err)
					this.common.message(this, err.message);
				});
		},
		// 搜索
		shopseacrh() {
			this.currentPage = 1;
			this.shopListapi();
		},
		// 重置
		reshopsetting(addshop = false) {
			this.shopName = "";
			this.cattext = "";
			this.shoplisttype = "";
			this.shopsubset = "";
			this.shopstatus = "";
			this.shophot = "";
			this.shopsell = "";
			this.addshop = addshop;
			this.brandPage = 1;
			this.shopListapi();
		},
		// 选择所有商品
		allchange(value) {
			if (!value) {
				this.$refs.multipleTable.clearSelection();
			} else {
				this.$refs.multipleTable.toggleAllSelection();
			}
			this.all_checked = value;
		},
		// 单个表格被选中
		singletable(val) {
			this.multipleSelection = val;
			if (val.length == this.tableData.length) {
				this.all_checked = true;
			} else {
				this.all_checked = false;
			}
		},
		delectshop({
			id
		}) {
			//通过id找到当前对象的位置
			const index = this.wareslist.findIndex((item) => item.id == id);
			this.$refs.multipleTable.toggleRowSelection(this.wareslist[index], false);
			this.wareslist.splice(index, 1);
		},
		clearlist() {
			this.$refs.multipleTable.toggleAllSelection();
			this.wareslist = [];
		},
		// 已选商品
		wares() {
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: "请选择商品",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false;
			}
			this.wareslist = this.multipleSelection;
			this.warestype = true;
		},
		shopselected() {
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: "请选择商品",
					type: "warning",
					offset: 200,
					duration: 1000,
				});
				return false;
			}
			this.$emit("change", this.multipleSelection);
			this.shoptype = false;
			this.$refs.multipleTable.clearSelection();
			this.all_checked = false;
			this.multipleSelection = [];
			this.brandPage = 1;
			this.$refs.multipleTable.clearSelection();
		},

		handleSizeChange(val) {
			this.eachPage = val;
			this.shopListapi();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// this.common.backTop();
			this.$refs.multipleTable.$el.scrollTo({
				top: 0,
				behavior: 'smooth' // 可选，使滚动具有平滑的动画效果
			});
			this.shopListapi();
		},
		fitmentData(value) {
			let that = this;
			this.$nextTick(() => {
				that.$set(that, 'cattext', value)
				that.cattext = value;
				if (value.length) {
					that.$refs.shopClass.computePresentText();
				}

				//console.log(that.cattext);
				that.shopListapi();
			});
		},
		classChange(e) {
			//console.log(e)
		},
		classListApi() {
			let that = this;
			let api = this.$apis.infoList + 'act_' + this.mobileActive;
			if (this.pull_class_url) {
				api = this.pull_class_url
			}
			this.$get(api).then((res) => {
				if (res.code == 200) {
					// //console.log(res.data)
					this.options = this.getTreeData(res.data.list || []);
				} else {
					this.common.message(this, res.message);
				}
			}).catch((err) => {
				//console.log(err)
				this.common.message(this, err.message);
			})
		},
	},
};
</script>

<style scoped lang="scss">
.batchtablecon .el-table-column--selection .cell {
	padding-left: 0;
}

.addglobal.global-search-box .serach-buttom-button {
	justify-content: flex-start;
	margin-bottom: 16px;
}

.addglobal .search-item {
	margin-right: 10px;
	margin-bottom: 10px;
}

.addglobal .search-item .search-label {
	padding: 0;
	width: auto;
}

.addglobal .search-item /deep/ .el-select {
	width: 110px;
}

.addglobal .search-itemadd /deep/ .el-select {
	width: 144px;
}

.addglobal .search-item /deep/ .el-input {
	width: 100%;
}

.addglobal .search-itemadd /deep/ .el-input__inner {
	width: 100%;
}

.batchtablecon /deep/ .el-table th:first-child>.cell {
	padding-left: 14px;
}

/* 商品选择器 */
.shoptc /deep/ .el-dialog {
	width: 1100px;
	border-radius: 4px;
}

.shoptcbox {
	width: 100%;
	padding: 16px 20px;
	box-sizing: border-box;
	position: relative;
}

.shoptcup {
	width: 100% !important;
	border-bottom: 0 !important;
	padding-bottom: 6px !important;
	margin-bottom: 0 !important;
}


.shopboxcon {
	width: 100%;
	height: 450px;
	overflow-y: auto;
	position: relative;
}

.batchtable {
	width: 100%;
}

.batchtablecon {
	width: 100%;
}

.batchtablecon table {
	table-layout: fixed;
	width: 100%;
	max-width: 100%;
}

.batchtablecon table tr th {
	background: #f9f9fc;
	padding: 15px 10px;
	box-sizing: border-box;
	text-align: center;
	border-bottom: 1px solid #f2f2f2;
	box-sizing: border-box;
	font-size: 14px;
	color: #333;
	font-weight: bold;
}

.batchtablecon table tr td {
	background: #fff;
	padding: 15px 10px;
	box-sizing: border-box;
	text-align: center;
	font-size: 14px;
	color: #333;
	border-bottom: 1px solid #f2f2f2;
	box-sizing: border-box;
	transition: all 0.3s;
}

.batchtablecon table tr:hover td {
	background: var(--primary-tips, #fff3ef);
}

.batchtablecon table tr td .batchdown {
	color: var(--fontColor, #fb6638);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
}

.tablepage {
	width: 100%;
	padding: 20px 20px 10px 20px;
	box-sizing: border-box;
	background: #fff;
}

.tablepage .orderlastbutright {
	float: none;
}

.setoperate {
	width: 100%;
	text-align: center;
}

.setoperate a {
	display: inline-block;
	font-size: 14px;
	color: var(--fontColor, #fb6638);
	position: relative;
	margin-right: 20px;
}

.setoperate a:last-child {
	margin-right: 0;
}

.setoperate a::before {
	content: "";
	width: 1px;
	height: 12px;
	background: var(--fontColor, #fb6638);
	position: absolute;
	top: 6px;
	right: -10px;
}

.setoperate a:last-child::before {
	display: none;
}

.shopboxcon .batchtable {
	padding: 0 0 40px 0;
}

.shopboxcon .batchtablecon table tr td {
	padding: 8px 10px;
}

.shoplister {
	width: 100%;
}

.shopimg {
	float: left;
	width: 50px;
	height: 50px;
	border-radius: 4px;
	margin-right: 12px;
}

.shopimg img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 4px;
}

.shoptext {
	width: 100%;
	float: left;
	width: calc(100% - 62px);
}

.shoptext h6 {
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	color: #333;
	font-weight: 500;
	line-height: 22px;
	max-height: 44px;
}

.shoplasted {
	color: rgba(0, 0, 0, 0.6);
}

.shoptcbox .orderlastbut {
	padding: 10px 25px;
	box-sizing: border-box;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	background: #fff;
	z-index: 10;
}

.orderlastbutleft {
	float: left;
	line-height: 32px;
}

.orderlastbutleft p {
	float: left;
	margin-right: 4px;
	padding-left: 10px;
	box-sizing: border-box;
}

.orderlastbutleft a {
	float: left;
	font-size: 14px;
	color: #ccc;
	margin-right: 6px;
	cursor: no-drop;
}

.orderlastbutleft a.active {
	color: var(--fontColor, #fb6638);
	cursor: pointer;
}

.orderlastbutright {
	float: right;
}

.ordercheck {
	margin-right: 10px;
}

.ordercheck .el-checkbox__inner {
	width: 18px;
	height: 18px;
}

.ordercheck .el-checkbox__inner::after {
	left: 6px;
	top: 3px;
}

.ordercheck .el-checkbox__inner:hover {
	border-color: var(--fontColor, #fb6638);
}

.ordercheck .el-checkbox__input.is-checked .el-checkbox__inner,
.ordercheckbox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
	border-color: var(--fontColor, #fb6638);
	background: var(--fontColor, #fb6638);
}

.ordercheck .el-checkbox__input.is-checked+.el-checkbox__label {
	color: var(--fontColor, #fb6638);
}

.ordercheck .el-checkbox__input.is-focus .el-checkbox__inner {
	border-color: var(--fontColor, #fb6638);
}

.dialog-footer .addressqr {
	background: var(--fontColor, #fb6638);
	color: #fff !important;
	border-color: var(--fontColor, #fb6638) !important;
}

.dialog-footer .addressqr:hover {
	background: var(--fontColor, #fb6638) !important;
	color: #fff !important;
}

/* 商品选择器end */
.guige {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	display: -webkit-box;
	-webkit-box-orient: vertical;

	span {
		display: inline-block;
		margin-right: 4px;
		font-size: 14px;
		color: #333;
	}
}

.batchtablecon .el-table .el-table__header-wrapper .el-table__header thead tr th {
	background: #f9f9fc;
}

.batchtablecon .el-table .cell {
	padding: 0;
}

.batchtablecon .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
	top: 7px;
}

.batchtablecon .el-checkbox__inner {
	width: 18px;
	height: 18px;
}

.batchtablecon .el-checkbox__inner::after {
	left: 6px;
	top: 3px;
}

.pitera {
	padding-bottom: 66px;
}

.purpagebutkong {
	height: 34px;
}

.purpagebut {
	position: absolute;
	bottom: 0;
	padding: 2px 10px;
	box-sizing: border-box;
	left: 0;
	width: 100%;
	border-top: 1px solid #eee;
	background: #fff;
}

.purpagebut .btn-prev {
	float: left;
	min-width: 32px;
}

.purpagebut .btn-next {
	float: right;
	min-width: 32px;
}
</style>
