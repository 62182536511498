<template>
    <div>
        <el-card>
            <div class="container">
                <p class="tips1">WELCOME</p>
                <p class="tips2">欢迎进入{{noLoginGlobal['system.base.setting']?.backend_info['platform.mall.name']}}系统</p>
                <img src="~@/assets/images/default_index_bg.png" alt="" />
            </div>
        </el-card>

        <!-- 右下角 系统更新消息提示框 -->

        <div class="renew-box" v-if="Object.keys(sysUpdateInfo).length" :class="{ 'active': uploadIsShow }">
            <div class="renewtitle">
                <div class="renewleft">
                    <img src="~@/assets/logo.png" alt="">
                    <span>{{sysUpdateInfo.title}}</span>
                </div>
                <div class="renewright">
                    <el-button type="text" size="medium" icon="el-icon-close"></el-button>
                </div>
            </div>
            <div class="renewbox">
                <div v-html="sysUpdateInfo.content"></div>
            </div>
            <div class="renewbut">
                <el-button type="primary" @click="showUpdateDetail">立即查看</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            sysUpdateInfo: {},
            uploadIsShow: false
        };
    },
	computed: {
		...mapState(["noLoginGlobal"])
	},
	watch: {
		"noLoginGlobal": {
			handler(newVal, oldVal) {
				// //console.log(newVal)
			},
			deep: true,
			immediate: true,
		}
	},
    created() {
        // 获取系统是否有更新推送
        this.getSysUpdateMessage()
    },
    methods: {
        // 获取当前是否有系统升级信息
        getSysUpdateMessage() {
            this.$get(this.$apis.sysUpdateMessage).then(res => {
                //console.log(res, 'sysUpdateMessage');
                if (res.code == 200) {
                    this.sysUpdateInfo = res.data
                    setTimeout(() => {
                        this.uploadIsShow = true
                    }, 500);
                }
            })
        },
        // 系统升级详情
        showUpdateDetail() {
            this.$put(this.$apis.setUpdateMessageRead + `?id=${this.sysUpdateInfo.id}`).then(res => {
                if (res.code == 200) {
                    this.$router.push('/setup/up_optimization/system_updates')
                } else {
                    this.$message.error(res.message)
                }
            })
        },
    }
};
</script>

<style lang="scss" scoped>
::v-deep .el-card {
    height: calc(100vh - 92px);
    box-shadow: 0 0 20px 0 rgba(163, 177, 204, 0.1) !important;
}

/deep/.el-card .el-card__body {
    height: 100%;
    padding: 0;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;

    .tips1 {
        font-size: 92px;
        color: #333;
        opacity: 0.1;
        // margin-top: 10%;
    }

    .tips2 {
        color: #333;
        font-size: 24px;
        margin-top: -1%;
    }

    img {
        // width: 90%;
        // height: 40%;
        // margin-top: -8%;
        width: 80%;
        height: 70%;
        object-fit: contain;
        margin-top: -5%;
    }
}

.renew-box {
    width: 330px;
    // height: 346px;
    height: 0px;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #fff;
    border-radius: 6px 6px 0 0;
    box-shadow: 0px 1px 12px 0px #E5E5E5;
    // display: none;
    z-index: 100000;
    transition: all 1s;

    &.active {
        height: 346px;
    }

    .renewtitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        padding: 0 20px;
        box-sizing: border-box;
        background: #F7F8FA;

        .renewleft {
            display: flex;
            align-items: center;

            img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin-right: 10px;
            }

            .span {
                font-size: 16px;
                color: #333;
                font-weight: 500;
            }
        }

        .renewright {

            /deep/ .el-button,
            /deep/ .el-button {
                color: #000 !important;
                opacity: .5;
                padding: 0 !important;

                .el-icon-close {
                    font-size: 20px;
                }
            }

            /deep/ .el-button:focus,
            /deep/ .el-button:hover {
                opacity: 1;
            }
        }
    }

    .renewbox {
        width: 100%;
        padding: 24px 24px 10px 24px;
        box-sizing: border-box;
        height: 240px;
        overflow-y: scroll;

        p {
            font-size: 14px;
            color: #666;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 5px;
            padding-left: 8px;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

    }

    .renewbut {
        width: 100%;
        padding: 10px 24px;
        box-sizing: border-box;

        /deep/ .el-button {
            width: 100%;
        }
    }
}
</style>