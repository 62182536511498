<template>
	<span>{{ scope }}{{ code_data?.sub_unit }}</span>
</template>
<script>
export default {
	name: 'component.table.text',
	props: {//传递参数
		// v-model
		code_data: {
			type: Object,
			required: true
		},
		scope: [String, Number]
	},
	// watch: {
	// 	scope: {
	// 		handler(newVal, oldVal) {
	// 			if (newVal) {
	// 				// 初始化
	// 				// this.init();
	// 				// // todo 优化 获取其她数据
	// 				// this.grouplistapi();
	// 				this.value = this.code_data?.is_filter ? this.scope.row[this.code_data.key][this.code_data.filter_key] : this.scope.row[this.code_data.key]
	// 			}
	// 		},
	// 		deep: true,
	// 		immediate: true
	// 	}
	// },
	data() {
		// const value = this.code_data?.is_filter ? this.scope.row[this.code_data.key][this.code_data.filter_key] : this.scope.row[this.code_data.key]
		return {
			component_id: this.code.domId(this.code_data),
			key: this.code_data.key
		};
	},
	created() { },
	methods: {},
};
</script>
<style></style>